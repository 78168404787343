import {
  DataLoadType,
  FeedbackMessage,
  Inject,
  Injectable,
  LearnerFeedbackThread,
  PendingFeedbackSummary
} from '@trainhq/trainhq-client-core';
import { mergeMap, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators';

import { FeedbackRepository } from '@/services/repositoryData/feedbackRepository';

export interface FeedbackService {
  addFeedback(feedback: {
    blockUuid: string;
    feedbackMessage: FeedbackMessage;
    journeyUuid?: string;
  }): Observable<LearnerFeedbackThread>;
  addMultiChoiceAnswer(chosenAnswer: { blockUuid: string; answerUuid: string; journeyUuid?: string }): Observable<any>;
  getAllPendingThreads(): Observable<LearnerFeedbackThread[]>;
  getPendingThread(quizUuid: string, journeyUuid?: string): Observable<LearnerFeedbackThread>;
  observeFeedbackSummary(): Observable<PendingFeedbackSummary>;
  refreshFeedbackItemsCountSummary(dataLoadType?: DataLoadType): void;
}

@Injectable('feedbackService')
export class FeedbackServiceImpl implements FeedbackService {
  private feedbackSummaryCheckTrigger = new Subject<DataLoadType>();

  @Inject('feedbackRepository')
  private feedbackRepository: FeedbackRepository;

  addMultiChoiceAnswer(chosenAnswer: { blockUuid: string; answerUuid: string; journeyUuid?: string }): Observable<any> {
    //TODO add type to Observable<any>  once you know what the type is
    return this.feedbackRepository.addMultiChoiceAnswer(chosenAnswer);
  }

  addFeedback(feedback: {
    blockUuid: string;
    feedbackMessage: FeedbackMessage;
    journeyUuid?: string;
  }): Observable<LearnerFeedbackThread> {
    return this.feedbackRepository.addFeedback(feedback);
  }

  getAllPendingThreads(): Observable<LearnerFeedbackThread[]> {
    return this.feedbackRepository
      .pendingFeedbackThreads({
        page: 0,
        size: 500,
        criterias: []
      })
      .pipe(
        map((page) => {
          return page.content;
        })
      );
  }

  getPendingThread(quizUuid: string, journeyUuid?: string): Observable<LearnerFeedbackThread> {
    return this.feedbackRepository.getPendingThread(quizUuid, journeyUuid);
  }

  observeFeedbackSummary(): Observable<PendingFeedbackSummary> {
    return this.feedbackSummaryCheckTrigger.pipe(
      mergeMap(() =>
        this.feedbackRepository
          .pendingFeedbackThreads({
            page: 0,
            size: 500,
            criterias: []
          })
          .pipe(map((res) => ({ pendingItems: res.totalElements })))
      )
    );
  }

  refreshFeedbackItemsCountSummary(dataLoadType: DataLoadType = 'cacheAndCloud'): void {
    this.feedbackSummaryCheckTrigger.next(dataLoadType);
  }
}
