import React, { useEffect, useState } from 'react';

import { LoginSetUpAccountComponent, SetUpAccountForm } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { tap } from 'rxjs/operators';

import { HOME } from '@/constants/router';
import { useLoadingOverlayContext } from '@/hooks/loadingOverlay/loadingOverlayContext';
import { useGetPasswordPolicy, useUserManagementService } from '@/hooks/userManagement/useUserManagementService';
import { TokenExpiredHeadline, TokenInvalidOverlay } from '@/pages/auth/styles';
import i18n from '@/services/i18n-no-auth';

const RegisterPage: React.FC = () => {
  const { t } = useTranslation();
  const userManagementService = useUserManagementService();
  const loadingOverlayContext = useLoadingOverlayContext();
  const [tokenValid, setTokenValid] = useState<boolean>(null);
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');
  const firstName = searchParams.get('firstName');
  const navigate = useNavigate();
  const passPolicy = useGetPasswordPolicy(token);

  useEffect(() => {
    loadingOverlayContext.setLoading(true);
    loadingOverlayContext.setLoadingText(t('validating_token'));
    userManagementService.validateToken(token).subscribe({
      next: (res) => {
        loadingOverlayContext.setLoading(false);
        setTokenValid(res?.valid ?? false);
      },
      error: (err) => {
        console.log(err);
      }
    });
  }, []);

  useEffect(() => {
    const urlLanguage = searchParams.get('lang');
    const currentLanguageCode = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language;

    if (urlLanguage && urlLanguage !== currentLanguageCode) {
      i18n.changeLanguage(urlLanguage).catch((error) => {});
    }
  }, [searchParams]);

  const handleSubmit = ({ firstName, lastName, password }) => {
    return userManagementService
      .verify({
        password: password,
        token: token,
        user: {
          firstName: firstName,
          lastName: lastName
        }
      })
      .pipe(
        tap({
          next: () => {
            navigate(HOME);
          }
        })
      );
  };

  return (
    <>
      {tokenValid === false && (
        <TokenInvalidOverlay>
          <TokenExpiredHeadline>{t('invalid_link')}</TokenExpiredHeadline>
        </TokenInvalidOverlay>
      )}
      {tokenValid && (
        <LoginSetUpAccountComponent
          isRegister
          form={<SetUpAccountForm passwordPolicy={passPolicy} onSubmit={handleSubmit} />}
          title={`${t('hi')}, ${firstName}. ${t('lets_start')}`}
        />
      )}
    </>
  );
};

export default RegisterPage;
