import React from 'react';

import { ReactComponent as BurgerIcon } from '@/assets/icons/burger-icon.svg';
import { BurgerButtonStyled } from '@/components/journey/common/styles';

interface BurgerButtonProps {
  className?: string;
  onClick(): void;
}

const BurgerButton: React.FC<BurgerButtonProps> = ({ className, onClick }) => {
  return (
    <BurgerButtonStyled className={className} onClick={onClick}>
      <BurgerIcon />
    </BurgerButtonStyled>
  );
};

export default BurgerButton;
