import React from 'react';

import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { getFormattedDateString, Journey, ProgressStatus } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import {
  DescriptionStyled,
  THQContentBuilderCardStyled,
  THQSmallHeadlineStyled
} from '@/components/common/contentBuilderList/styles';
import { DueDateTypography } from '@/components/common/styles';
import { JOURNEY_STRING } from '@/constants/router';
import { getChipInfo } from '@/utils/courseUtils';

interface JourneyListItemProps {
  journey: Journey;
}

// TODO: use context to prevent prop drilling
const JourneyListItem: React.FC<JourneyListItemProps> = ({ journey }) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleOnJourneyClick = () => {
    navigate(`${JOURNEY_STRING}/${journey?.uuid}`);
  };

  return (
    <Grid item lg={4} md={6} sm={6} xs={12}>
      {
        <THQContentBuilderCardStyled
          asFraction
          learnerLayout
          total={journey?.numberOfSteps}
          passed={journey?.numberOfCompletedSteps}
          description={journey?.description ? <DescriptionStyled>{journey?.description}</DescriptionStyled> : undefined}
          descriptionPlaceholder={<DescriptionStyled asPlaceholder>{t('no_journey_description')}</DescriptionStyled>}
          subtitle={
            <THQSmallHeadlineStyled onClick={handleOnJourneyClick}>{t('journey_all_capital')}</THQSmallHeadlineStyled>
          }
          footnote={
            <>
              {journey?.progressStatus !== ProgressStatus.COMPLETED && (
                <DueDateTypography overdue={journey?.dueDate - new Date().valueOf() < 0}>
                  {journey?.dueDate && (
                    <>
                      {t('due')} {getFormattedDateString({ dueDate: journey?.dueDate, t: t, lang: i18n.language })}
                    </>
                  )}
                </DueDateTypography>
              )}
            </>
          }
          progressBar={(journey?.numberOfCompletedSteps / journey?.numberOfSteps) * 100}
          chipInfo={getChipInfo(journey.progressStatus, theme, t)}
          button={{
            variant:
              journey?.progressStatus === ProgressStatus.NOT_STARTED ||
              (journey?.progressStatus === 'DUE' && journey?.numberOfCompletedSteps === 0)
                ? 'main'
                : 'standard',
            text:
              journey?.progressStatus === ProgressStatus.NOT_STARTED ||
              (journey?.progressStatus === ProgressStatus.DUE && journey?.numberOfCompletedSteps === 0)
                ? t('start')
                : journey?.progressStatus === ProgressStatus.COMPLETED
                ? t('view')
                : t('continue'),
            action: handleOnJourneyClick
          }}
          entity={journey}
        />
      }
    </Grid>
  );
};

export default JourneyListItem;
