import { styled, Typography } from '@mui/material';
import { Player, PlayerControls, Recorder, THQInput } from '@trainhq/trainhq-client-core';

export const TypographyQuizQuestion = styled(Typography)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '150%',
  maxWidth: '100vw',
  overflow: 'hidden',
  paddingTop: '20px',
  textAlign: 'center',
  wordWrap: 'break-word'
}));

export const QuestionInputStyled = styled(THQInput)(({ theme, disabled }) => ({
  backgroundColor: 'white',
  border: '1px solid #ECF1F4',
  borderRadius: '12px',
  margin: 'auto',
  marginTop: '32px',
  padding: '20px',
  width: '100%',
  ...(disabled && {
    backgroundColor: theme.palette.common.pearl,
    '& > div > textarea': {
      WebkitTextFillColor: `${theme.palette.common.midnight} !important`
    }
  })
}));

interface StyledRecorderProps {
  sidebarExpanded?: boolean;
  withFeedback?: boolean;
}

// TODO: decide how should recorder and recorder navigation work in manager and learner. Once we have that defined, rework it in
// core so we can avoid styling it like this here
export const StyledRecorder = styled(Recorder, {
  shouldForwardProp: (prop) => prop !== 'sidebarExpanded' && prop !== 'withFeedback'
})<StyledRecorderProps>(({ theme, withFeedback }) => ({
  aspectRatio: '16/9',
  height: 'auto',
  marginLeft: '50%',
  marginTop: '32px',
  maxHeight: '60vh',
  maxWidth: withFeedback ? 'calc(( (60vh - 150px) * 16) / 9)' : 'calc((60vh * 16) / 9)',
  minWidth: '40%',
  position: 'static',
  width: '100%',
  zIndex: 'unset',
  [theme.breakpoints.down('lg')]: {
    height: 'auto',
    maxWidth: withFeedback ? 'calc(( (60vh - 150px) * 16) / 9)' : 'calc((60vh * 16) / 9)',
    width: '100%'
  },
  [theme.breakpoints.down('md')]: {
    border: '8px solid #ECF1F4'
  },
  [theme.breakpoints.down('sm')]: {
    border: 'none',
    borderRadius: 0,
    maxHeight: 'calc((60vh * 9) / 16)',
    maxWidth: '100vw',
    width: '100%'
  }
}));

export const StyledPlayerControls = styled(PlayerControls)<StyledRecorderProps>(() => ({
  alignItems: 'center',
  borderLeft: 'none',
  display: 'flex',
  maxWidth: '100%',
  minHeight: '100px',
  minWidth: 'unset',
  paddingLeft: '40px',
  paddingRight: '40px',
  right: 0,
  transition: 'all .2s',
  width: '100%',
  // TODO: define and use standard breakpoints
  ['@media (min-width:1200px)']: {
    maxWidth: '100%'
  }
}));

export const StyledPlayer = styled(Player)<StyledRecorderProps>(() => ({
  aspectRatio: '16 / 9',
  bottom: 'auto',
  border: '8px solid #ECF1F4',
  height: 'auto',
  marginTop: '32px',
  position: 'relative',
  width: '100%'
}));

interface FeedbackMessageProps {
  accepted?: boolean;
  review?: boolean;
}

export const FeedbackMessageContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'accepted' && prop !== 'review'
})<FeedbackMessageProps>(({ theme, accepted = false, review = false }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.primary.brand01,
  ...(review && { backgroundColor: theme.palette.warning.background }),
  ...(accepted && { backgroundColor: theme.palette.success.background }),
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  margin: '24px auto 8px auto',
  padding: '24px'
}));

export const FeedbackHeadline = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'accepted'
})<FeedbackMessageProps>(({ theme, accepted = false }) => ({
  color: accepted ? theme.palette.success.foreground : theme.palette.primary.main,
  fontWeight: 700,
  fontSize: '14px',
  lineHeight: '21px'
}));

export const FeedbackContent = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'accepted'
})<FeedbackMessageProps>(({ theme, accepted = false }) => ({
  color: accepted ? theme.palette.success.foreground : theme.palette.common.smoke,
  fontSize: '14px',
  lineHeight: '21px'
}));
