import { Grid, Link, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const MainContentContainerStyled = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'showBorder'
})(() => ({
  marginTop: '8%',
  display: 'flex'
}));

export const LogoContainerStyled = styled('div')(({ theme }) => ({
  margin: '58px auto auto 58px',
  height: 'auto',
  [theme.breakpoints.down('sm')]: {
    margin: '0',
    marginTop: '58px',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center'
  }
}));

export const BrowserCard = styled(Grid)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: theme.palette.background.paper,
  border: `1px solid ${theme.palette.common.pearl}`,
  padding: '24px',
  height: '200px',
  width: '200px',
  borderRadius: '32px',
  alignItems: 'center',
  justifyContent: 'center',
  marginTop: '16px',
  marginLeft: '16px',
  marginRight: '16px',
  cursor: 'pointer',
  transition: 'transform 100ms ease-out, box-shadow 250ms ease-out',
  color: theme.palette.common.smoke,
  '&:hover': {
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
    border: '1px solid #ECF1F4',
    boxShadow: '0px 4px 24px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05)'
  }
}));

export const DownloadLinkTypography = styled(Link)(() => ({
  transition: 'color .2s ease-in',
  cursor: 'pointer',
  marginBottom: 0,
  marginTop: 'auto',
  fontWeight: 700,
  fontSize: '14px',
  color: 'inherit'
}));

export const HeadlineTypography = styled(Typography)(({ theme }) => ({
  fontWeight: 700,
  textAlign: 'center',
  fontSize: '24px',
  color: theme.palette.common.midnight
}));

export const SubtextTypography = styled(Typography)(({ theme }) => ({
  fontSize: '21px',
  textAlign: 'center',
  marginTop: '16px',
  color: theme.palette.common.smoke
}));
