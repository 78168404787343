import React from 'react';

import { I18nextProvider } from 'react-i18next';
import { Outlet } from 'react-router-dom';

import i18n from '@/services/i18n-no-auth';

const PublicRoutes: React.FC = () => {
  return (
    <I18nextProvider i18n={i18n}>
      <Outlet />
    </I18nextProvider>
  );
};

export default PublicRoutes;
