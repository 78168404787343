import React from 'react';

import { Link } from '@mui/material';
import { ContentItem } from '@trainhq/trainhq-client-core';
import { useParams } from 'react-router';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { LinkStyled } from '@/components/library/link/styles';

interface BreadcrumbLinkProps {
  backButton?: boolean;
  children?: React.ReactNode;
  contentItem?: ContentItem;

  backButtonClick?(): void;

  clearSearchResults?(): void;
}

const BreadcrumbLink: React.FC<BreadcrumbLinkProps> = ({
  backButton,
  contentItem,
  children,
  backButtonClick,
  clearSearchResults
}) => {
  const routerParams = useParams();
  const location = useLocation();
  const routerParamsString = routerParams?.['*'];
  const position = !contentItem ? 0 : routerParamsString?.indexOf(contentItem.uuid) + contentItem.uuid.length;
  const goBack = location?.state?.backLink;
  const goTo = `/library/${routerParamsString?.substring(0, position)}`;

  const handleBackButtonClick = () => {
    clearSearchResults();
    backButtonClick();
  };

  if (backButton) {
    return (
      <Link component={RouterLink} to={goBack || goTo} onClick={handleBackButtonClick}>
        {children}
      </Link>
    );
  }

  return (
    <LinkStyled component={RouterLink} to={goTo} onClick={clearSearchResults}>
      {!contentItem ? 'Library' : contentItem.name}
    </LinkStyled>
  );
};

export default BreadcrumbLink;
