import React from 'react';

import { DescriptionStyled } from '@/components/common/contentBuilderList/styles';

export const parseDescriptionAddLinks = (desc) => {
  const linkRegex = /((?:https:|http:|www)\S*)/gm;
  if (linkRegex.test(desc)) {
    const tagged = desc.replaceAll(/((?:https:|http:|www)\S*)/gm, '<#>$1<#>');
    const parts = tagged.split('<#>');
    const aggregate = parts.reduce((acc, part: string) => {
      if (/((?:https:|http:|www)\S+)/gm.test(part)) {
        let linkToAttach = part;
        if (linkToAttach.charAt(linkToAttach.length - 1).match(/[,.!?]/)) {
          linkToAttach = linkToAttach.substring(0, linkToAttach.length - 1);
        }
        return [
          ...acc,
          <a
            style={{ wordBreak: 'break-all' }}
            href={linkToAttach.includes('//') ? linkToAttach : '//' + linkToAttach}
            key={part}
            target="_blank"
            rel="noreferrer"
          >
            {part}
          </a>
        ];
      } else {
        return [
          ...acc,
          <DescriptionStyled component={'span'} key={part} variant="body1">
            {part}
          </DescriptionStyled>
        ];
      }
    }, []);
    return aggregate;
  } else {
    return desc;
  }
};
