import React from 'react';

import { CircularProgress, Grid } from '@mui/material';
import { ErrorsByAttempt, ErrorsPerPair, SuccessRate } from '@trainhq/trainhq-client-core';
import { useParams } from 'react-router';

import { useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import {
  useGetErrorsByAttemptsStats,
  useGetErrorsPerPairStats,
  useGetRolePlayEventsCountStats
} from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const StrictOverviewTabPanel: React.FC = () => {
  const { rolePlayUuid } = useParams<{ rolePlayUuid: string }>();
  const { tabValue, selectedTypeOption } = useSelectedRPInsightsTabContext();
  const user = useAuthenticatedUserContext();

  const skip = tabValue !== 0;
  const successRateData = useGetRolePlayEventsCountStats(rolePlayUuid, selectedTypeOption, 'strict', skip);
  const errorsByAttemptsData = useGetErrorsByAttemptsStats(rolePlayUuid, selectedTypeOption, 'strict', skip);
  const errorsPerPairData = useGetErrorsPerPairStats(rolePlayUuid, selectedTypeOption, skip);

  const loading =
    successRateData?.loadingEventsCount || errorsByAttemptsData?.errorsByAttemptsLoading || errorsPerPairData?.loading;

  return skip ? null : (
    <>
      {loading ? (
        <Grid>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} lg={8}>
            <Grid alignItems="center" container columnSpacing={{ xs: 0, md: 2 }} rowSpacing={{ xs: 2, md: 0 }}>
              <Grid item xs={12} md={6}>
                <SuccessRate successRateData={successRateData} type="strict" />
              </Grid>
              <Grid item xs={12} md={6}>
                <ErrorsByAttempt
                  errorsByAttemptData={errorsByAttemptsData}
                  selectedUser={`${user.firstName} ${user.lastName}`}
                />
              </Grid>
            </Grid>
            <Grid alignItems="center" container sx={{ marginBottom: '16px', marginTop: '16px' }}>
              <Grid item sx={{ position: 'relative' }} xs={12}>
                {errorsPerPairData && !errorsPerPairData?.loading && (
                  <ErrorsPerPair errorsPerPair={errorsPerPairData} tabValue={tabValue} />
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default StrictOverviewTabPanel;
