import { BaseCloudService, CallIntelligenceBuilder } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { GET_AVAILABLE_LANGUAGES, GET_TRANSLATIONS } from '@/constants/api';

export class TranslationCloudService extends BaseCloudService<CallIntelligenceBuilder> {
  constructor() {
    super({
      get: GET_TRANSLATIONS
    });
  }

  getTranslations(locale?: string): Observable<any> {
    return this.networkService.get(`${GET_TRANSLATIONS}/${locale}`);
  }

  getAvailableLanguages(): Observable<any> {
    return this.networkService.get(`${GET_AVAILABLE_LANGUAGES}`);
  }
}
