import { BaseCloudService, ContentItem } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { CONTENT_ITEM_GET, CONTENT_ITEM_GET_ALL, CONTENT_ITEM_PAGE } from '@/constants/api';

export class ContentLibraryCloudService extends BaseCloudService<ContentItem> {
  constructor() {
    super({ get: CONTENT_ITEM_GET, page: CONTENT_ITEM_PAGE });
  }

  getAllByUuids(uuids: string[]): Observable<ContentItem[]> {
    return this.networkService.post(CONTENT_ITEM_GET_ALL, uuids);
  }
}
