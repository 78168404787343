import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQCard } from '@trainhq/trainhq-client-core';

interface THQCardStyledProps {
  fixed?: boolean;
}

export const THQCardStyled = styled(THQCard, {
  shouldForwardProp: (prop) => prop !== 'fixed'
})<THQCardStyledProps>(({ theme, fixed }) => ({
  '&&&': {
    maxWidth: '336px'
  },
  transition: 'all .3s ease-out',
  backgroundColor: `${theme.palette.common.zima}`,
  width: 'calc(100vw - 40px)',
  position: fixed ? 'fixed' : 'initial',
  top: '26px',
  left: '16px',
  [theme.breakpoints.down('sm')]: {
    maxWidth: '300px'
  }
}));

export const DescriptionTextStyled = styled(Typography)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: { maxWidth: '220px' },
  maxWidth: '238px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

export const IconImgStyled = styled('img')(() => ({
  objectFit: 'cover',
  height: '40px',
  width: '40px'
}));

export const TitleTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.silver
}));
