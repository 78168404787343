import { styled } from '@mui/material/styles';

export const LogoContainerStyled = styled('div')(({ theme }) => ({
  margin: 'auto',
  height: 'auto',
  justifyContent: 'center',
  display: 'flex',
  [theme.breakpoints.down('sm')]: {
    margin: '0',
    marginTop: '58px',
    width: '100vw',
    display: 'flex',
    justifyContent: 'center'
  }
}));
