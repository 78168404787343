import React, { useCallback, useLayoutEffect, useRef, useState } from 'react';

import { Divider, Grid, useTheme } from '@mui/material';
import {
  MediaFileUploadService,
  MultipleChoiceQuizAnswer,
  useMediaFileUploaderService
} from '@trainhq/trainhq-client-core';

import { ReactComponent as Check } from '@/assets/icons/check.svg';
import { ReactComponent as XIcon } from '@/assets/icons/x.svg';
import { renderMediaContent } from '@/components/course/block/common/quizmediaRender/QuizMediaRender';
import {
  AnswerContentGridStyled,
  AnswerRootGridStyled,
  ExplanationContentGridStyled
} from '@/components/course/block/multipleChoice/answerChoice/styles';

interface MultipleChoiceComponentProps {
  answerOption?: MultipleChoiceQuizAnswer;
  chosenAnswers?: string[];
  isSelected?: boolean;
  committingAnswer?: boolean;
  selectable?: boolean;
  onClick?: React.Dispatch<React.SetStateAction<string>>;
}

const AnswerTextComponent: React.FC<MultipleChoiceComponentProps> = ({
  committingAnswer,
  answerOption,
  isSelected,
  selectable,
  onClick
}) => {
  const mediaFileUploaderServiceRef = useRef<MediaFileUploadService>();
  mediaFileUploaderServiceRef.current = useMediaFileUploaderService();
  const [showExplanation, setShowExplanation] = useState(false);
  const theme = useTheme();

  const handleSetSelected = useCallback(() => {
    if (onClick && !answerOption.answered && selectable) {
      onClick(answerOption.uuid);
    }
  }, [answerOption.answered, answerOption.uuid, onClick, selectable]);

  useLayoutEffect(() => {
    if (answerOption.answered) {
      setShowExplanation(true);
    }
  }, [answerOption.answered]);

  return (
    <>
      <AnswerRootGridStyled
        selected={isSelected}
        committingAnswer={committingAnswer}
        onClick={handleSetSelected}
        error={answerOption.answered && !answerOption.correct}
        success={answerOption.answered && answerOption.correct}
        animate
      >
        {answerOption?.answerContent?.itemMediaContent && (
          <AnswerContentGridStyled item xs={12}>
            <Grid container justifyContent={'center'}>
              {renderMediaContent(answerOption?.answerContent?.itemMediaContent)}
            </Grid>
          </AnswerContentGridStyled>
        )}
        <AnswerContentGridStyled item>
          <Grid container>
            <Grid item xs={true}>
              {answerOption.answerContent.itemTextContent}
            </Grid>
            <Grid item sx={{ display: 'flex', alignItems: 'center' }} xs={'auto'}>
              {answerOption.correct && showExplanation && (
                <Check style={{ height: '16px', width: '16px', color: theme.palette.success.foreground }} />
              )}
              {!answerOption.correct && showExplanation && (
                <XIcon style={{ height: '16px', width: '16px', color: theme.palette.error.foreground }} />
              )}
            </Grid>
          </Grid>
        </AnswerContentGridStyled>
        {answerOption.explanation && (
          <>
            <Divider
              sx={(theme) => ({
                opacity: showExplanation ? 1 : 0,
                borderColor: theme.palette.common.pearl,
                transition: 'opacity .2s ease-in'
              })}
            />
            <ExplanationContentGridStyled show={showExplanation}>
              {answerOption.explanation}
            </ExplanationContentGridStyled>
          </>
        )}
      </AnswerRootGridStyled>
    </>
  );
};

export default AnswerTextComponent;
