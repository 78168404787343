import React, { useCallback, useEffect, useState } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import {
  CardRootGridStyled,
  getJourneyItemImage,
  JourneyItemType,
  RolePlayModePhase,
  THQBody,
  THQCard,
  THQPrimaryButton,
  THQTooltip,
  XIcon
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { ReactComponent as Dumbell } from '@/assets/icons/dumbell.svg';
import { ReactComponent as Completed } from '@/assets/icons/natural_roleplay_phase_done.svg';
import { ReactComponent as NotCompleted } from '@/assets/icons/natural_roleplay_phase_not_done.svg';
import { ReactComponent as Play } from '@/assets/icons/play_hollow.svg';
import PracticeRoleplayImage from '@/assets/images/natural_roleplay_practice.png';
import WatchRoleplayImage from '@/assets/images/role_play_watch.png';
import { ROLEPLAYS } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useAzureSDKContext } from '@/hooks/azureSDK/useAzureSDKContext';
import { HintDivStyled, WarningDivStyled } from '@/roleplay/naturalRolePlay/naturalRolePlayStart/styles';
import { comparePhases } from '@/roleplay/naturalRolePlay/utils';
import WatchModeRunner from '@/roleplay/naturalRolePlay/watchMode/WatchModeRunner';
import { RPContainer } from '@/roleplay/styles';

interface NaturalRolePlayStartProps {
  initializing: boolean;
  setInitializing: React.Dispatch<React.SetStateAction<boolean>>;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  moveToPracticeMode: () => void;
}

const NaturalRolePlayStart: React.FC<NaturalRolePlayStartProps> = ({
  initializing,
  setInitializing,
  setError,
  moveToPracticeMode
}) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const navigate = useNavigate();
  const { roleplayConfig, roleplaySummary } = useAzureSDKContext();
  const { activeCompany } = useAuthenticatedUserContext();

  useEffect(() => {
    setInitializing(false);
  });

  const [selectedMode, setSelectedMode] = useState('root');

  const handleBackToRoot = useCallback(() => {
    setSelectedMode('root');
  }, []);

  const handleMoveToWatch = () => {
    setSelectedMode('watch');
  };

  const handleGoBackToRoleplays = useCallback(() => {
    if (returnTo) {
      navigate(returnTo);
    } else {
      navigate(ROLEPLAYS);
    }
  }, [navigate, returnTo]);

  const renderRoot = () => {
    return (
      <>
        <THQPrimaryButton
          sx={{ zIndex: 100, position: 'fixed', top: '40px', right: '40px' }}
          onClick={handleGoBackToRoleplays}
        >
          <XIcon style={{ width: '16px', height: '16px' }} />
        </THQPrimaryButton>
        <THQCard
          cardSx={(theme) => ({
            '&&&': {
              zIndex: 5,
              maxWidth: '340px'
            },
            ['&:hover']: {
              backgroundColor: `${theme.palette.common.zima}`,
              transition: 'all .3s ease-in'
            },
            transition: 'all .3s ease-out',
            backgroundColor: `${theme.palette.common.zima}4D`,
            width: 'calc(100vw - 40px)',
            position: 'fixed',
            top: '26px',
            left: '20px',
            [theme.breakpoints.down('sm')]: {
              maxWidth: '300px',
              backgroundColor: `${theme.palette.common.zima}`
            }
          })}
          description={
            <THQTooltip arrow title={<>{roleplayConfig?.name}</>}>
              <Typography
                fontSize={12}
                fontWeight={600}
                sx={(theme) => ({
                  [theme.breakpoints.down('sm')]: { maxWidth: '160px' },
                  maxWidth: '240px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap'
                })}
              >
                {roleplayConfig?.name}
              </Typography>
            </THQTooltip>
          }
          icon={
            <img
              src={getJourneyItemImage(JourneyItemType.ROLE_PLAY)}
              style={{ objectFit: 'cover', height: '40px', width: '40px' }}
            />
          }
          orientation="horizontal"
          title={
            <Typography fontSize={12} fontWeight={400} sx={(theme) => ({ color: theme.palette.common.silver })}>
              {t('roleplay')}
            </Typography>
          }
        />
        {!initializing && (
          <RPContainer>
            <Grid
              container
              justifyContent={'center'}
              gap={'32px'}
              sx={{
                paddingTop: '60px',
                position: 'relative',
                '&::after': {
                  position: 'absolute',
                  bottom: 0,
                  left: '50%',
                  zIndex: 0,
                  content: '" "',
                  minHeight: '50px',
                  height: 'calc(100% - 60px)',
                  width: '1px',
                  backgroundColor: 'black'
                }
              }}
            >
              <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                  <Grid item>
                    <CardRootGridStyled sx={{ zIndex: 1, maxWidth: '480px', width: '100%' }}>
                      <Grid container columnGap={'16px'}>
                        {roleplaySummary.rolePlayModePhase === 'WATCH' && (
                          <Grid item xs={12} sx={{ paddingBottom: '24px', lineHeight: '150%' }}>
                            <HintDivStyled>{t('start_here')} 👇</HintDivStyled>
                          </Grid>
                        )}
                        <Grid item xs={'auto'}>
                          <img src={WatchRoleplayImage} style={{ objectFit: 'cover', maxWidth: '72px' }} />
                        </Grid>
                        <Grid item xs>
                          <Grid container>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs={true}>
                                  <THQBody
                                    sx={(theme) => ({
                                      fontSize: '14px',
                                      color: theme.palette.common.midnight,
                                      fontWeight: 700
                                    })}
                                  >
                                    {t('watch_rp')}
                                  </THQBody>
                                </Grid>
                                <Grid item xs="auto">
                                  {comparePhases(roleplaySummary.rolePlayModePhase, RolePlayModePhase.WATCH) < 0 ? (
                                    <Completed />
                                  ) : (
                                    <NotCompleted />
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                            <Grid item xs={12} rowGap={'12px'}>
                              <THQBody
                                sx={(theme) => ({
                                  fontSize: '14px',
                                  color: theme.palette.common.smoke
                                })}
                              >
                                {t('rp_start_body')}
                              </THQBody>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={12} sx={{ paddingTop: '16px' }}>
                        <THQPrimaryButton fullWidth onClick={handleMoveToWatch}>
                          <Play style={{ width: '16px', height: '16px', marginRight: '4px' }} />
                          {t('watch')}
                        </THQPrimaryButton>
                      </Grid>
                    </CardRootGridStyled>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <Grid container justifyContent={'center'}>
                  <Grid item>
                    <CardRootGridStyled sx={{ position: 'relative', zIndex: 1, maxWidth: '512px', width: '100%' }}>
                      {roleplaySummary.rolePlayModePhase === 'WATCH' && (
                        <Box
                          sx={(theme) => ({
                            borderRadius: '24px',
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            height: '100%',
                            width: '100%',
                            zIndex: '4',
                            backgroundColor: theme.palette.common.zima + '9D',
                            backdropFilter: 'grayscale(100%) blur(0.6px)'
                          })}
                        />
                      )}
                      <Grid container columnGap={'16px'}>
                        {roleplaySummary.rolePlayModePhase === 'PRACTICE' && (
                          <Grid item xs={12} sx={{ paddingBottom: '24px', lineHeight: '150%' }}>
                            {activeCompany?.companyConfiguration?.rolePlayAttemptsEnabled ? (
                              roleplaySummary.allAttemptsCount >=
                              activeCompany?.companyConfiguration?.availableRolePlayAttempts ? (
                                <WarningDivStyled>{t('rp_start_max_practice_attempts')}</WarningDivStyled>
                              ) : (
                                <HintDivStyled>
                                  {t('rp_start_total_1')}{' '}
                                  {activeCompany?.companyConfiguration?.availableRolePlayAttempts}{' '}
                                  {t('rp_start_total_2')}
                                </HintDivStyled>
                              )
                            ) : (
                              <HintDivStyled>{t('rp_start_achieve_completion')}</HintDivStyled>
                            )}
                          </Grid>
                        )}
                        <Grid item xs={'auto'}>
                          <img src={PracticeRoleplayImage} style={{ objectFit: 'cover' }} />
                        </Grid>
                        <Grid item xs>
                          <Grid container>
                            <Grid item xs={12}>
                              <Grid container>
                                <Grid item xs>
                                  <THQBody
                                    sx={(theme) => ({
                                      fontSize: '14px',
                                      color: theme.palette.common.midnight,
                                      fontWeight: 700
                                    })}
                                  >
                                    {t('practice_rp')}
                                  </THQBody>
                                </Grid>
                                <Grid item xs="auto">
                                  {roleplaySummary.completed ? <Completed /> : <NotCompleted />}
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={12} rowGap={'12px'}>
                              <THQBody
                                sx={(theme) => ({
                                  fontSize: '14px',
                                  color: theme.palette.common.smoke
                                })}
                              >
                                {t('practice_rp_shine')}
                              </THQBody>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      {(!activeCompany?.companyConfiguration?.rolePlayAttemptsEnabled ||
                        activeCompany?.companyConfiguration?.availableRolePlayAttempts >
                          roleplaySummary.allAttemptsCount) && (
                        <Grid item xs={12} sx={{ paddingTop: '16px' }}>
                          <THQPrimaryButton fullWidth onClick={moveToPracticeMode}>
                            <Dumbell
                              style={{
                                width: '16px',
                                height: '16px',
                                marginRight: '4px'
                              }}
                            />
                            {t('practise')}
                          </THQPrimaryButton>
                        </Grid>
                      )}
                    </CardRootGridStyled>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </RPContainer>
        )}
      </>
    );
  };
  const renderCurrentScreen = () => {
    switch (selectedMode) {
      case 'root':
        return renderRoot();
      case 'watch':
        return (
          <WatchModeRunner
            backToRoot={handleBackToRoot}
            initializing={initializing}
            setInitializing={setInitializing}
            setError={setError}
          />
        );
      case 'practice':
        moveToPracticeMode();
    }
  };
  return <>{renderCurrentScreen()}</>;
};
export default NaturalRolePlayStart;
