import React, { useCallback, useMemo } from 'react';

import { Box } from '@mui/material';
import { JourneyItemType, XIcon } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';

import { ActiveTab } from '@/components/common/contentBuilderList/ContentBuilderList';
import JourneyDetailsPanel from '@/components/journey/detailsPanel/JourneyDetailsPanel';
import JourneyItemDrawer from '@/components/journey/drawer/JourneyItemDrawer';
import CoursePanelItem from '@/components/journey/item/course/CoursePanelItem';
import JourneyItemList from '@/components/journey/item/list/JourneyItemList';
import MilestonePanelItem from '@/components/journey/item/milestone/MilestonePanelItem';
import RolePlayPanelItem from '@/components/journey/item/rolePlay/RolePlayPanelItem';
import { BurgerButtonStyled, XButtonStyled } from '@/components/journey/styles';
import { JOURNEYS } from '@/constants/router';
import { useJourneyContext, useJourneySetters } from '@/hooks/journey/useJourneyContext';

const JourneyDetails: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const returnTo = searchParams.get('returnTo');
  const tabToReturn = location.state === ActiveTab.COMPLETED ? '?activeTab=completed' : '?activeTab=your-journeys';

  const { openItemDrawer, selectedItem } = useJourneyContext();
  const { setOpenDetailsDrawer } = useJourneySetters();

  const handleOnClickBurger = useCallback(() => {
    setOpenDetailsDrawer((prevState) => !prevState);
  }, [setOpenDetailsDrawer]);

  const handleOnExitJourney = useCallback(() => {
    if (returnTo) {
      navigate(returnTo);
    } else {
      navigate(`${JOURNEYS}${tabToReturn}`);
    }
  }, [navigate, returnTo, tabToReturn]);

  const panelItemToRender = useMemo(() => {
    switch (selectedItem?.journeyItemType) {
      case JourneyItemType.COURSE:
        return <CoursePanelItem />;
      case JourneyItemType.MILESTONE:
        return <MilestonePanelItem />;
      case JourneyItemType.ROLE_PLAY:
        return <RolePlayPanelItem />;
      default:
        return null;
    }
  }, [selectedItem?.journeyItemType]);

  return (
    <Box sx={{ marginTop: '92px' }}>
      <BurgerButtonStyled onClick={handleOnClickBurger} />
      <XButtonStyled onClick={handleOnExitJourney}>
        <XIcon height={16} width={16} />
      </XButtonStyled>
      <JourneyDetailsPanel />
      <JourneyItemDrawer open={openItemDrawer}>{panelItemToRender}</JourneyItemDrawer>
      <JourneyItemList />
    </Box>
  );
};

export default JourneyDetails;
