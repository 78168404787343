import { BaseCloudService } from '@trainhq/trainhq-client-core';

import { GET_COURSE_BLOCK } from '@/constants/api';
import { LearnerBlock } from '@/models/learnerModels';

export class BlockCloudService extends BaseCloudService<LearnerBlock> {
  constructor() {
    super({ save: null, getAll: null, get: GET_COURSE_BLOCK });
  }

  getLpBlock(uuid: string, journeyUuid: string) {
    return this.networkService.get(
      `${GET_COURSE_BLOCK}?uuid=${encodeURIComponent(uuid)}&journeyUuid=${encodeURIComponent(journeyUuid)}`
    );
  }
}
