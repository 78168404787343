import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQAvatar } from '@trainhq/trainhq-client-core';

import { ReactComponent as PhoneIcon } from '@/assets/icons/phone.svg';

export const MainBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.midnight,
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  padding: '32px'
}));

export const AvatarStyled = styled(THQAvatar)(() => ({
  borderRadius: '50%',
  height: 200,
  width: 200
}));

export const StaticBackgroundDivStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.midnight,
  borderRadius: '50%',
  height: 200,
  position: 'absolute',
  width: 200
}));

interface PulsingBackgroundDivStyledProps {
  pulsing?: boolean;
}

const pulsingKeyframes = {
  '@keyframes pulsing': {
    '0%': {
      transform: 'scale(1)'
    },
    '40%': {
      transform: 'scale(1.1)'
    },
    '100%': {
      transform: 'scale(1.01)'
    }
  }
};

export const PulsingBackgroundDivStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'pulsing'
})<PulsingBackgroundDivStyledProps>(({ theme, pulsing }) => ({
  backgroundColor: theme.palette.common.smoke,
  border: `1px solid ${theme.palette.common.smoke}`,
  borderRadius: '50%',
  height: '208px',
  left: '-4px',
  position: 'absolute',
  top: '-4px',
  width: '208px',
  opacity: 0.7,
  ...(pulsing && {
    ...pulsingKeyframes,
    animation: 'pulsing infinite 2.5s'
  })
}));

export const PulsingBackgroundDivSmallStyled = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'pulsing'
})<PulsingBackgroundDivStyledProps>(({ theme, pulsing }) => ({
  backgroundColor: theme.palette.common.pearl,
  borderRadius: '50%',
  height: '208px',
  left: '-4px',
  position: 'absolute',
  top: '-4px',
  width: '208px',
  opacity: 0.3,
  ...(pulsing && {
    ...pulsingKeyframes,
    animation: 'pulsing infinite 2.5s',
    animationDelay: '1s'
  })
}));

interface CallIconWrapperProps {
  state: 'call' | 'inCall';
}

export const CallIconWrapper = styled(Box, {
  shouldForwardProp: (prop) => prop !== 'state'
})<CallIconWrapperProps>(({ theme, state }) => ({
  backgroundColor: theme.palette.success.main,
  borderRadius: '50%',
  cursor: 'pointer',
  display: 'block',
  height: 56,
  position: 'relative',
  transition: '.15s ease-in-out all',
  touchAction: 'manipulation',
  width: 56,
  ...(state === 'inCall' && {
    transform: 'rotate(135deg)',
    transition: '.15s ease-in-out all',
    background: theme.palette.error.main
  })
}));

export const PhoneIconStyled = styled(PhoneIcon)(({ theme }) => ({
  position: 'absolute',
  marginLeft: 'auto',
  marginRight: 'auto',
  marginTop: 'auto',
  marginBottom: 'auto',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  textAlign: 'center',
  '& > path': {
    stroke: theme.palette.common.zima,
    fill: theme.palette.common.zima
  }
}));
