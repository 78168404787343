import React from 'react';

import { getJourneyItemImage, JourneyItemType, THQTooltip } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

import {
  DescriptionTextStyled,
  IconImgStyled,
  THQCardStyled,
  TitleTextStyled
} from '@/roleplay/naturalRolePlay/common/titleCard/styles';

interface TitleCardProps {
  fixed?: boolean;
  name: string;
}

const TitleCard: React.FC<TitleCardProps> = ({ fixed, name }) => {
  const { t } = useTranslation();
  return (
    <THQCardStyled
      onClick={undefined}
      fixed={fixed}
      description={
        <THQTooltip arrow title={<>{name}</>}>
          <DescriptionTextStyled fontSize={12} fontWeight={600}>
            {name}
          </DescriptionTextStyled>
        </THQTooltip>
      }
      icon={<IconImgStyled alt={name} src={getJourneyItemImage(JourneyItemType.ROLE_PLAY)} />}
      orientation="horizontal"
      title={
        <TitleTextStyled fontSize={12} fontWeight={400}>
          {t('roleplay')}
        </TitleTextStyled>
      }
    />
  );
};

export default TitleCard;
