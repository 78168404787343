import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQPrimaryButton } from '@trainhq/trainhq-client-core';

import { ReactComponent as NavArrowLeft } from '@/assets/icons/nav-arrow-left.svg';
import { ReactComponent as NavArrowRight } from '@/assets/icons/nav-arrow-right.svg';

export const NavIconArrowLeft = styled(NavArrowLeft)(() => ({
  width: 16,
  '& path': {
    strokeWidth: 2
  }
}));

interface IconsProps {
  disabled?: boolean;
}

export const NavIconArrowRight = styled(NavArrowRight, {
  shouldForwardProp: (prop) => prop !== 'disabled'
})<IconsProps>(({ theme, disabled }) => ({
  width: 16,
  '& path': {
    strokeWidth: 2,
    stroke: disabled ? theme.palette.common.silver : theme.palette.common.zima
  }
}));

export const BlockNavGridStyled = styled(Grid)(() => ({
  bottom: 0,
  position: 'fixed',
  right: 0,
  transition: 'width 0.2s',
  width: '100%',
  zIndex: 8
}));

export const BlockNavSubGridStyled = styled(Grid)(({ theme }) => ({
  paddingBottom: 40,
  paddingLeft: 40,
  paddingRight: 40,
  [theme.breakpoints.down('sm')]: {
    paddingBottom: 16,
    paddingLeft: 16,
    paddingRight: 16
  }
}));

export const LearningTimeGridStyled = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.warning.background,
  padding: 5
}));

export const LearningTimeTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.warning.foreground,
  fontSize: 13,
  fontWeight: 700
}));

export const PreviousButtonStyled = styled(THQPrimaryButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const NextButtonStyled = styled(THQPrimaryButton)(({ theme }) => ({
  textTransform: 'none',
  border: 'none',
  minWidth: 100,
  padding: '24px 32px',
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));
