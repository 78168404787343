import { BaseCloudService, Milestone } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { MILESTONE_COMPLETE } from '@/constants/api';

export class MilestoneCloudService extends BaseCloudService<Milestone> {
  constructor() {
    super({ save: null, getAll: null, get: null });
  }

  completeMilestone(milestone: Milestone, journeyUuid: string): Observable<Milestone> {
    return this.networkService.post(MILESTONE_COMPLETE, { ...milestone, milestoneUuid: milestone.uuid, journeyUuid });
  }
}
