import React, { useCallback, useMemo } from 'react';

import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { minutesToHoursAndMinutes, Player, THQTooltip, useTHQSnackbar } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

import { ActiveTab } from '@/components/common/contentBuilderList/ContentBuilderList';
import {
  BlockNavGridStyled,
  BlockNavSubGridStyled,
  LearningTimeGridStyled,
  LearningTimeTextStyled,
  NavIconArrowLeft,
  NavIconArrowRight,
  NextButtonStyled,
  PreviousButtonStyled
} from '@/components/course/block/navigation/styles';
import { useCourseContext } from '@/components/course/courseDetails/context';
import { useConfettiOverlay } from '@/components/overlays/confettiOverlay/ConfettiOverlay';
import { COURSES } from '@/constants/router';

interface BlockControlsProps {
  disabled?: boolean;
  nextCallback?(): void;
  previousCallback?(): void;
}

const BlockNav: React.FC<BlockControlsProps> = ({ disabled, nextCallback, previousCallback }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const confettiOverlay = useConfettiOverlay();
  const snackbar = useTHQSnackbar();
  const navigate = useNavigate();
  const location = useLocation();
  const isTablet = useMediaQuery(theme.breakpoints.down('md')); // TODO: define media queries properly
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [searchParams] = useSearchParams();
  const preview = searchParams.get('preview') == '1';
  const fromLibrary = searchParams.get('library') == '1';
  const returnTo = searchParams.get('returnTo');
  const tabToReturn = location.state === ActiveTab.COMPLETED ? '?activeTab=completed' : '?activeTab=your-course';

  const { blockDetails, course, isFirst, isLast, nextBlock, previousBlock, timeSpent, goToBlock } = useCourseContext();

  const learningTimeRequiredCheck = !preview && isLast && course?.timeTrackingEnabled && course?.learningTimeRequired;
  const learnerTimeDisabled = timeSpent < (course?.minimumLearningTime || 0) * 1000 * 60 && learningTimeRequiredCheck;

  const openNextBlock = useCallback(
    (newBlockStatus?: string) => {
      if (isLast) {
        if (preview && !fromLibrary) {
          window.top.postMessage('finishPreview', '*');
        } else {
          if (!course?.userCompleted) {
            snackbar(t('completed'));
          }
          navigate(`${returnTo || COURSES}${fromLibrary ? '' : tabToReturn}`);
        }
        return;
      }
      goToBlock(nextBlock, undefined, newBlockStatus);
    },
    [
      course?.userCompleted,
      fromLibrary,
      goToBlock,
      isLast,
      navigate,
      nextBlock,
      preview,
      returnTo,
      snackbar,
      tabToReturn
    ]
  );

  const handleOnClickNext = useCallback(() => {
    if (!course?.userCompleted && isLast) {
      confettiOverlay(() => {
        openNextBlock();
      });
    } else {
      openNextBlock();
    }
    if (nextCallback) {
      nextCallback();
    }
  }, [confettiOverlay, course?.userCompleted, isLast, nextCallback, openNextBlock]);

  const openPreviousBlock = useCallback(() => {
    goToBlock(previousBlock, undefined);
    if (previousCallback) {
      previousCallback();
    }
  }, [goToBlock, previousBlock, previousCallback]);

  const nextButton = useMemo(
    () => (
      <NextButtonStyled
        disabled={disabled || (isLast && learnerTimeDisabled)}
        endIcon={<NavIconArrowRight disabled={disabled} />}
        variant="main"
        onClick={handleOnClickNext}
      >
        {isLast ? (preview ? t('finish_preview') : course?.userCompleted ? t('done') : t('finish_course')) : t('next')}
      </NextButtonStyled>
    ),
    [course?.userCompleted, disabled, handleOnClickNext, isLast, learnerTimeDisabled, preview]
  );

  return (
    <BlockNavGridStyled sx={{ pointerEvents: 'none' }}>
      <Grid container>
        {blockDetails?.content?.narrationContent && (
          <>
            <Player
              autoPlay
              key={blockDetails.uuid}
              file={blockDetails.content.narrationContent}
              playerSx={{
                pointerEvents: 'auto',
                zIndex: 15,
                right: 'unset',
                minWidth: 'unset',
                height: '185px',
                width: '326px',
                left: '40px',
                bottom: '40px',
                transform: 'unset',
                ...(isTablet && {
                  left: '50%',
                  bottom: '120px',
                  transform: 'translateX(-50%)'
                })
              }}
            />
          </>
        )}
        <BlockNavSubGridStyled item xs={12}>
          <Grid
            container
            alignItems="center"
            justifyContent={isFirst || !isTablet ? 'end' : 'space-between'}
            spacing={1}
          >
            {!isFirst && (
              <Grid sx={{ pointerEvents: 'auto' }} item xs={6} sm="auto">
                <PreviousButtonStyled startIcon={<NavIconArrowLeft />} onClick={openPreviousBlock}>
                  {t('previous')}
                </PreviousButtonStyled>
              </Grid>
            )}
            <Grid sx={{ pointerEvents: 'auto' }} item xs={6} sm="auto">
              {learnerTimeDisabled ? (
                <THQTooltip arrow placement="left" sx={{ width: '268px' }} title={t('didnt_reach_time_req')}>
                  {nextButton}
                </THQTooltip>
              ) : (
                nextButton
              )}
            </Grid>
          </Grid>
        </BlockNavSubGridStyled>
        {isMobile && learnerTimeDisabled && (
          <LearningTimeGridStyled item xs={12}>
            <LearningTimeTextStyled align="center">
              {t('learning_time_for_this_course')} {minutesToHoursAndMinutes(course?.minimumLearningTime)}.
            </LearningTimeTextStyled>
          </LearningTimeGridStyled>
        )}
      </Grid>
    </BlockNavGridStyled>
  );
};

export default BlockNav;
