import React from 'react';

import { SendInfoByEmail } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';

import { RESET_PASSWORD } from '@/constants/router';
import { useUserManagementService } from '@/hooks/userManagement/useUserManagementService';

const ResetViaEmail: React.FC = () => {
  const navigate = useNavigate();
  const userManagerService = useUserManagementService();

  const handleRequest = (email: string) => {
    return userManagerService.sendForgotLink(email);
  };

  const handleGoBack = () => {
    navigate(RESET_PASSWORD);
  };

  return <SendInfoByEmail onGoBack={handleGoBack} onSubmit={handleRequest} />;
};

export default ResetViaEmail;
