import { Inject, Injectable, Milestone } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { MilestoneRepository } from '@/services/repositoryData/milestoneRepository';

export abstract class MilestoneService {
  abstract completeMilestone(milestone: Milestone, journeyUuid: string): Observable<Milestone>;
}

@Injectable('milestoneService')
export class MilestoneServiceImpl implements MilestoneService {
  @Inject('milestoneRepository')
  private milestoneRepository: MilestoneRepository;

  completeMilestone(milestone: Milestone, journeyUuid: string): Observable<Milestone> {
    return this.milestoneRepository.completeMilestone(milestone, journeyUuid);
  }
}
