import { createContext, useContext } from 'react';

import { container, NetworkService } from '@trainhq/trainhq-client-core';

export const NetworkServiceContext = createContext<NetworkService>(container.resolve('networkService'));

export interface ThemeConfigProps {
  themeConfig: any;
}
export const ThemeConfigContext = createContext<ThemeConfigProps>(null);
export const useThemeConfigContext = () => useContext(ThemeConfigContext);
