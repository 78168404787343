import { GetRepository, Inject, Injectable, SaveRepository, User } from '@trainhq/trainhq-client-core';
import { Observable, of } from 'rxjs';
import { tap } from 'rxjs/operators';

import { UserCloudService } from '@/services/repositoryData/cloud/userCloudService';

export interface UserRepository extends GetRepository<User, string>, SaveRepository<User> {}

@Injectable('userRepository')
export class UserRepositoryImpl implements UserRepository {
  @Inject('userCloudService')
  userCloudService: UserCloudService;

  get(): Observable<User> {
    return this.userCloudService.get().pipe(
      tap({
        next: (user) => {
          localStorage.setItem('user', JSON.stringify(user));
        }
      })
    );
  }

  save(item: User): Observable<User> {
    return of(undefined);
  }
}
