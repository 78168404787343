import React, { createContext } from 'react';

import { container, Journey, JourneyItem } from '@trainhq/trainhq-client-core';

import { JourneyService } from '@/services/journey/journeyService';

export interface JourneySetters {
  setParentGroupUuid: React.Dispatch<React.SetStateAction<string>>;
  setOpenDetailsDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenItemDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setSelectedItem: React.Dispatch<React.SetStateAction<JourneyItem>>;
  setJourney: React.Dispatch<React.SetStateAction<Journey>>;
}

export interface JourneyDetails {
  journey: Journey;
  openDetailsDrawer?: boolean;
  openItemDrawer?: boolean;
  parentGroupUuid?: string;
  selectedItem?: JourneyItem;
}

export const JourneySettersContext = createContext<JourneySetters>(null);
export const JourneyContext = createContext<JourneyDetails>(null);
export const JourneyServiceContext = createContext<JourneyService>(container.resolve('journeyService'));
