import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQBody, THQHeadline } from '@trainhq/trainhq-client-core';

export interface RPRunSummaryRootGridProps {
  visible?: boolean;
}
export const RPRunSummaryRootGrid = styled(Grid, {
  shouldForwardProp: (prop) => prop != 'visible'
})<RPRunSummaryRootGridProps>(({ visible }) => ({
  marginTop: '48px',
  marginLeft: 'auto',
  marginRight: 'auto',
  maxWidth: '400px',
  opacity: 1,
  transition: 'max-height .3s ease-in, opacity .4s ease-out',
  ...(!visible && {
    marginTop: 0,
    maxHeight: 0,
    opacity: 0,
    overflow: 'hidden'
  })
}));

export const RPSummaryHeadlineTypography = styled(THQHeadline)(() => ({
  textAlign: 'center',
  fontSize: '18px'
}));

export const RPSummaryBodyTypography = styled(THQBody)(({ theme }) => ({
  marginBottom: '32px',
  textAlign: 'center',
  color: theme.palette.common.midnight
}));
