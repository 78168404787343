import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';

import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  EmptyRolePlay,
  PresentationAngleImage,
  RoleplayBuilder,
  THQAppBar,
  THQSelectSearch,
  useDebounce
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { Outlet, useSearchParams } from 'react-router-dom';

import { SelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import { MainGridStyled } from '@/components/insights/styles';
import { INSIGHTS, ROLE_PLAY_STRING } from '@/constants/router';
import { useGetRoleplaysPage } from '@/hooks/roleplay/useRolePlayService';

const Insights: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { rolePlayUuid } = useParams<{ rolePlayUuid: string }>();
  const [searchParams] = useSearchParams();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [rolePlayFilter, setRolePlayFilter] = useState<string>('-');
  const [selectedRolePlay, setSelectedRolePlay] = useState<RoleplayBuilder>();

  const {
    loadedItems: rpLoadedItems,
    loadMore: rpLoadMore,
    search: rpSearch
  } = useGetRoleplaysPage({
    page: 0,
    size: 5,
    criterias: [
      { key: 'userRolePlayId.rolePlay.rolePlayState', operation: 'equals', relationType: 'AND', value: 'PUBLISHED' }
    ]
  });

  const urlRolePlayNotInOptions = rolePlayUuid && !rpLoadedItems?.find((rp) => rp.uuid === rolePlayUuid);

  const getRPFromUrlCoordinator = useGetRoleplaysPage({
    page: 0,
    size: 1,
    criterias: [
      {
        key: 'userRolePlayId.rolePlay.uuid',
        operation: 'equals',
        relationType: 'AND',
        value: rolePlayUuid
      }
    ]
  });

  const rolePlayFromUrl = useMemo(
    () => getRPFromUrlCoordinator?.loadedItems[0],
    [getRPFromUrlCoordinator?.loadedItems]
  );

  const handleOnChangeRolePlay = useCallback(
    (event) => {
      navigate(event.target.value === '-' ? INSIGHTS : `${INSIGHTS}${ROLE_PLAY_STRING}/${event.target.value}`);
      setRolePlayFilter(event.target.value);
      setSelectedRolePlay(rpLoadedItems.find((rp) => rp.uuid === event.target.value));
    },
    [navigate, rpLoadedItems]
  );

  const handleRolePlaySearch = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      rpSearch([
        {
          key: 'userRolePlayId.rolePlay.name',
          operation: 'contains',
          relationType: 'AND',
          value: event.target.value
        },
        {
          key: 'userRolePlayId.rolePlay.rolePlayState',
          operation: 'equals',
          relationType: 'AND',
          value: 'PUBLISHED'
        }
      ]);
    },
    [rpSearch]
  );

  const handleOnOpenRPFilter = useCallback(() => {
    rpSearch([
      { key: 'userRolePlayId.rolePlay.name', operation: 'contains', relationType: 'AND', value: '' },
      { key: 'userRolePlayId.rolePlay.rolePlayState', operation: 'equals', relationType: 'AND', value: 'PUBLISHED' }
    ]);
  }, [rpSearch]);

  const debouncedRPSearch = useDebounce(500, handleRolePlaySearch);

  const rolePlayOptions = useMemo(
    () =>
      [
        { value: '-', label: t('common.rp_challenge_none') },
        ...(urlRolePlayNotInOptions && selectedRolePlay
          ? [
              {
                value: selectedRolePlay?.uuid,
                label: selectedRolePlay?.name
              }
            ]
          : []),
        ...(rpLoadedItems?.length === 0
          ? []
          : rpLoadedItems.map((item) => ({
              value: item.uuid,
              label: item.name
            })))
      ]?.filter((option) => !!option),
    [rpLoadedItems, selectedRolePlay, t, urlRolePlayNotInOptions]
  );

  const contextValue = useMemo(() => ({ selectedRolePlay }), [selectedRolePlay]);

  useEffect(() => {
    if (rpLoadedItems.length > 0 && rolePlayUuid && rolePlayFromUrl) {
      setRolePlayFilter(rolePlayUuid);
      if (!selectedRolePlay) {
        setSelectedRolePlay(
          rpLoadedItems?.find((rp) => rp.uuid === rolePlayUuid) || getRPFromUrlCoordinator?.loadedItems?.[0]
        );
      }
    }
  }, [
    getRPFromUrlCoordinator?.loadedItems,
    rolePlayFromUrl,
    rolePlayUuid,
    rpLoadedItems,
    searchParams,
    selectedRolePlay
  ]);

  const filters = useMemo(
    () => (
      <Grid item xs={12} sm="auto">
        <Grid alignItems="center" container columnSpacing={{ xs: 0, sm: 1 }} rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm="auto">
            <THQSelectSearch
              label={t('roleplay')}
              options={rolePlayOptions}
              searchPlaceholder={t('search_roleplays')}
              value={rolePlayFilter}
              onChange={handleOnChangeRolePlay}
              onLoadMore={rpLoadMore}
              onOpen={handleOnOpenRPFilter}
              onSearch={debouncedRPSearch}
            />
          </Grid>
        </Grid>
      </Grid>
    ),
    [debouncedRPSearch, handleOnChangeRolePlay, handleOnOpenRPFilter, rolePlayFilter, rolePlayOptions, rpLoadMore, t]
  );

  // Handles case when user clicks on sidebar menu item
  useEffect(() => {
    if (!rolePlayUuid) {
      setRolePlayFilter('-');
    }
  }, [rolePlayUuid]);

  return (
    <SelectedRPInsightsTabContext.Provider value={contextValue}>
      {isTablet ? (
        <THQAppBar text={t('insights')}>{filters}</THQAppBar>
      ) : (
        <MainGridStyled alignItems="center" container justifyContent="space-between" rowSpacing={{ xs: 1, sm: 0 }}>
          <Grid item xs={12} sm="auto">
            <Typography>{t('insights')}</Typography>
          </Grid>
          {filters}
        </MainGridStyled>
      )}
      {rolePlayFilter === '-' ? (
        <EmptyRolePlay image={PresentationAngleImage} mainText={t('insights_main')} subtext={t('insights_sub')} />
      ) : (
        <Outlet />
      )}
    </SelectedRPInsightsTabContext.Provider>
  );
};

export default Insights;
