import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';

import {
  container,
  ContentItem,
  PageRequest,
  PaginationCoordinator,
  useGetPagination
} from '@trainhq/trainhq-client-core';
import { Subscription } from 'rxjs';

import { ContentLibraryService } from '@/services/contentLibrary/contentLibraryService';

export const useGetLibraryContentItems = (pageRequest: PageRequest): PaginationCoordinator<ContentItem> => {
  const contentLibraryService = useContentLibraryService();
  return useGetPagination<ContentItem>(contentLibraryService.contentItemPage, pageRequest);
};

export const useGetContentItem = (
  uuid: string,
  fetch?: boolean
): { contentItem: ContentItem; setContentItem: React.Dispatch<React.SetStateAction<ContentItem>> } => {
  const contentLibraryService = useContentLibraryService();
  const [contentItem, setContentItem] = useState<ContentItem>();

  useEffect(() => {
    const subs = new Subscription();

    if (fetch) {
      subs.add(
        contentLibraryService.get(uuid).subscribe({
          next: (result) => {
            setContentItem(result);
          }
        })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [contentLibraryService, fetch, uuid]);

  return useMemo(() => ({ contentItem, setContentItem }), [contentItem]);
};

export const useGetAllByUuids = (
  uuids: string[],
  fetch?: boolean
): { contentItems: ContentItem[]; setContentItems: React.Dispatch<React.SetStateAction<ContentItem[]>> } => {
  const contentLibraryService = useContentLibraryService();
  const [contentItems, setContentItems] = useState<ContentItem[]>();

  useEffect(() => {
    const subs = new Subscription();

    if (fetch) {
      subs.add(
        contentLibraryService.getAllByUuids(uuids).subscribe({
          next: (result) => {
            setContentItems(result);
          }
        })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [contentLibraryService, fetch, uuids]);

  return useMemo(() => ({ contentItems, setContentItems }), [contentItems]);
};

export const ContentLibraryServiceContext = createContext<ContentLibraryService>(
  container.resolve('contentLibraryService')
);
export const useContentLibraryService = () => useContext(ContentLibraryServiceContext);
