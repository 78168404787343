import React, { useCallback, useMemo } from 'react';

import { Grid } from '@mui/material';
import { THQIcon } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

import { ReactComponent as Check } from '@/assets/icons/check.svg';
import { ReactComponent as Circle } from '@/assets/icons/circle.svg';
import { ReactComponent as LockedIcon } from '@/assets/icons/locked-icon.svg';
import { ReactComponent as XIcon } from '@/assets/icons/x.svg';
import {
  BlockNameTypographyStyled,
  CheckMarkIconContainer,
  ContainerGridStyled,
  LockedIconChildGridStyled,
  LockedIconGridStyled,
  NameTypographyStyled,
  OutlineItemGridStyled,
  SectionChildrenContainer,
  SidebarRootGridStyled
} from '@/components/course/sidebar/styles';
import { LearnerBlock, LearnerSection } from '@/models/learnerModels';

interface SidebarBlocksListProps {
  isItemLocked?: boolean;
  currentSectionIndex: number;
  currentBlockIndex: number;
  isSelected?: boolean;
  sidebarItem: LearnerSection | LearnerBlock;
  section: LearnerSection;
  onGoToBlock?: (block: LearnerBlock, forceIfCurrent?: any) => void;
  previewModeOn?: boolean;
  children?: React.ReactNode;
}

const SidebarBlocksList: React.FC<SidebarBlocksListProps> = ({
  isItemLocked,
  currentSectionIndex,
  currentBlockIndex,
  isSelected,
  sidebarItem,
  section,
  onGoToBlock,
  children,
  previewModeOn = false
}) => {
  const { t } = useTranslation();
  // UI performance optimization (re-renders only when blocks array changes, which is in useState in
  // CourseDetails, so it keeps the reference)
  const lockedIcon = useMemo(() => <LockedIcon />, []);
  const handleOnGoToBlock = useCallback(() => {
    if (!isItemLocked && onGoToBlock) {
      onGoToBlock(sidebarItem as LearnerBlock);
    }
  }, [isItemLocked, onGoToBlock, sidebarItem]);

  return section ? (
    <SidebarRootGridStyled onClick={handleOnGoToBlock} item>
      <ContainerGridStyled alignItems="center" container>
        <Grid item xs={true}>
          <Grid sx={{ flexWrap: 'nowrap' }} alignItems="center" container spacing={1}>
            <Grid item sx={{ height: '30px' }} alignSelf={'flex-start'}>
              {!previewModeOn && (
                <CheckMarkIconContainer
                  currentSectionIndex={currentSectionIndex}
                  currentBlockIndex={currentBlockIndex}
                  sectionOrder={section?.order}
                  sidebarItemOrder={sidebarItem.order}
                  isQuiz={
                    (sidebarItem as LearnerBlock)?.blockTemplate.blockType === 'QUIZ' ||
                    (sidebarItem as LearnerBlock)?.blockTemplate.blockType === 'MULTIPLE_CHOICE_QUIZ' ||
                    (sidebarItem as LearnerBlock)?.blockTemplate.blockType === 'SCORM'
                  }
                  sidebarItemState={(sidebarItem as LearnerBlock)?.quizFeedbackStatus}
                >
                  <>
                    {(sidebarItem as LearnerBlock)?.quizFeedbackStatus === 'PENDING_RESPONSE' ? (
                      <Circle height={16} width={16} />
                    ) : (sidebarItem as LearnerBlock)?.quizFeedbackStatus === 'APPROVER_REJECTED' ||
                      (sidebarItem as LearnerBlock)?.quizFeedbackStatus === 'FEEDBACK_REQUIRED' ? (
                      <XIcon height={16} width={16} />
                    ) : (
                      <Check height={16} width={16} />
                    )}
                  </>
                </CheckMarkIconContainer>
              )}
            </Grid>
            <Grid item>
              <BlockNameTypographyStyled isItemLocked={isItemLocked} isSelected={isSelected} variant="body2">
                <span style={{ paddingRight: '8px' }}>{t(sidebarItem.title) || t('untitled')}</span>
              </BlockNameTypographyStyled>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={'auto'} justifyItems={'flex-start'} alignSelf={'flex-start'}>
          {(sidebarItem as LearnerBlock).blockTemplate?.blockType?.includes('QUIZ') && (
            <LockedIconChildGridStyled>{t('quiz')}</LockedIconChildGridStyled>
          )}
        </Grid>
      </ContainerGridStyled>
    </SidebarRootGridStyled>
  ) : (
    <OutlineItemGridStyled noPointer onClick={handleOnGoToBlock} item>
      <Grid container alignItems="baseline" spacing={1} wrap="nowrap">
        <Grid item>
          <NameTypographyStyled isItemLocked={isItemLocked} isSelected={isSelected} variant="body2">
            {t(sidebarItem?.title) || t('untitled')}
          </NameTypographyStyled>
        </Grid>
        <LockedIconGridStyled item>{isItemLocked && <THQIcon icon={lockedIcon} />}</LockedIconGridStyled>
      </Grid>
      <SectionChildrenContainer>{children}</SectionChildrenContainer>
    </OutlineItemGridStyled>
  );
};

export default SidebarBlocksList;
