import { useCallback, useEffect, useRef, useState } from 'react';

import { minutesToHoursAndMinutes, THQWebSocket, useWebSocket } from '@trainhq/trainhq-client-core';
import { Subscription, timer } from 'rxjs';

import { COURSE_TOPIC } from '@/constants/api';
import { SOCKET_URL } from '@/hooks/config/useAppConfig';

export const useLearnerTime = (courseUuid: string, journeyUuid?: string, skip?: boolean): THQWebSocket<any> => {
  let journeyParam = '';
  if (journeyUuid) {
    journeyParam = `?journeyUuid=${journeyUuid}`;
  }
  return useWebSocket<unknown>(SOCKET_URL, `${COURSE_TOPIC}/${courseUuid}${journeyParam}`, { skip });
};

export const useSpentTime = (learningTimeDuration: number, startTimer?: boolean): number => {
  const [timeSpent, setTimeSpent] = useState<number>(); // time spent in milliseconds, later converted to h/m
  const initialLearningTime = useRef<number>(new Date().getTime());
  const initialTimeSpent = useRef<number>(learningTimeDuration);

  const handleOnTabFocus = useCallback(() => {
    // set initial time spent to be used for tab focus event
    if (!initialTimeSpent.current) {
      initialTimeSpent.current = learningTimeDuration;
    }

    if (!document.hidden) {
      const nextDate = new Date().getTime();
      const timeToAdd = initialTimeSpent.current + (nextDate - initialLearningTime.current);
      setTimeSpent(timeToAdd);
      initialTimeSpent.current = timeToAdd;
      initialLearningTime.current = nextDate;
    }
  }, [learningTimeDuration]);

  // starts timer for time spent
  useEffect(() => {
    if (startTimer && !timeSpent) {
      setTimeSpent(learningTimeDuration || 1);
    }
    const subs = new Subscription();
    if (timeSpent) {
      // fixes inactive tab interval issue
      document.addEventListener('visibilitychange', handleOnTabFocus);

      subs.add(
        timer(0, 1000).subscribe((value) => {
          // check if new minute started. If yes, update the value and trigger re-render
          // value increases by 1 each second, so we multiply it by 1000 to convert it to milliseconds for addition
          const timeSpentInSeconds = Math.floor(timeSpent / 1000);
          if (
            minutesToHoursAndMinutes(timeSpentInSeconds / 60) !==
            minutesToHoursAndMinutes(Math.floor((timeSpent + value) / 60))
          ) {
            setTimeSpent((prevState) => prevState + value * 1000);
          }
        })
      );
    }
    return () => {
      document.removeEventListener('visibilitychange', handleOnTabFocus);
      subs.unsubscribe();
    };
  }, [handleOnTabFocus, learningTimeDuration, startTimer, timeSpent]);

  return timeSpent;
};
