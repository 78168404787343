import React, { useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FeatureFlag, FeaturePermission, THQAppBar, THQMainPageContainer } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

import { CourseList } from '@/components/course/list/CourseList';
import useSetAppBar from '@/layout/useSetAppBar';
import NotFoundPage from '@/pages/notFound/NotFoundPage';

const CourseListPage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const appBar = useMemo(() => (isSmallScreen ? <THQAppBar text={t('courses')} /> : null), [isSmallScreen]);

  useSetAppBar(appBar);

  return (
    <FeatureFlag featurePermissions={[FeaturePermission.COURSE]} render={<NotFoundPage />}>
      <THQMainPageContainer>
        <CourseList />
      </THQMainPageContainer>
    </FeatureFlag>
  );
};

export default CourseListPage;
