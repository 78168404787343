import React from 'react';

import { DetailsDrawerStyled } from '@/components/journey/drawer/styles';
import { useJourneySetters } from '@/hooks/journey/useJourneyContext';

interface DrawerConfig {
  width: number;
}

export const detailsDrawerConfig: DrawerConfig = {
  width: 272
};

interface JourneyDetailsDrawerProps {
  children?: React.ReactNode;
  open?: boolean;
}

const JourneyDetailsDrawer: React.FC<JourneyDetailsDrawerProps> = ({ children, open }) => {
  const { setOpenItemDrawer } = useJourneySetters();

  const handleOnClose = () => {
    setOpenItemDrawer(false);
  };

  return (
    <DetailsDrawerStyled
      elevation={0}
      hideBackdrop
      ModalProps={{ disableScrollLock: true }}
      open={open}
      onClose={handleOnClose}
    >
      {children}
    </DetailsDrawerStyled>
  );
};

export default JourneyDetailsDrawer;
