import {
  FeedbackMessage,
  Injectable,
  LearnerFeedbackThread,
  Pageable,
  PageRequest
} from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { FeedbackCloudService } from '@/services/repositoryData/cloud/feedbackCloudService';

export interface FeedbackRepository {
  addFeedback(feedback: { blockUuid: string; feedbackMessage: FeedbackMessage }): Observable<LearnerFeedbackThread>;
  pendingFeedbackThreads(pageRequest: PageRequest): Observable<Pageable<LearnerFeedbackThread>>;
  getPendingThread(quizUuid: string, journeyUuid?: string): Observable<LearnerFeedbackThread>;
  addMultiChoiceAnswer(chosenAnswer: { blockUuid: string; answerUuid: string; journeyUuid?: string }): Observable<any>;
}

@Injectable('feedbackRepository')
export class FeedbackRepositoryImpl implements FeedbackRepository {
  constructor(private feedbackCloudService = new FeedbackCloudService()) {}

  addMultiChoiceAnswer(chosenAnswer: { blockUuid: string; answerUuid: string; journeyUuid?: string }): Observable<any> {
    return this.feedbackCloudService.addMultiChoiceAnswer(chosenAnswer);
  }

  addFeedback(feedback: { blockUuid: string; feedbackMessage: FeedbackMessage }): Observable<LearnerFeedbackThread> {
    return this.feedbackCloudService.addFeedback(feedback);
  }

  pendingFeedbackThreads(pageRequest: PageRequest): Observable<Pageable<LearnerFeedbackThread>> {
    return this.feedbackCloudService.getPendingThreads(pageRequest);
  }

  getPendingThread(quizUuid: string, journeyUuid?: string): Observable<LearnerFeedbackThread> {
    return this.feedbackCloudService.getPendingThread(quizUuid, journeyUuid);
  }
}
