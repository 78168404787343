import {
  ContentItem,
  DataLoadType,
  GetRepository,
  GetWithCacheRepositoryImpl,
  Injectable,
  Pageable,
  PageRequest,
  SaveRepository,
  SaveRepositoryImpl
} from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { ContentLibraryCacheService } from '@/services/repositoryData/cache/contentLibraryCacheService';
import { ContentLibraryCloudService } from '@/services/repositoryData/cloud/contentLibraryCloudService';

export interface ContentLibraryRepository extends GetRepository<ContentItem, string>, SaveRepository<ContentItem> {
  contentItemPage(pageRequest: PageRequest): Observable<Pageable<ContentItem>>;
  getAllByUuids(uuids: string[]): Observable<ContentItem[]>;
}

@Injectable('contentLibraryRepository')
export class ContentLibraryRepositoryImpl implements ContentLibraryRepository {
  constructor(
    private cacheService = new ContentLibraryCacheService(),
    private contentLibraryCloudService = new ContentLibraryCloudService(),
    private getRepository = new GetWithCacheRepositoryImpl(cacheService, contentLibraryCloudService.get),
    private saveRepository = new SaveRepositoryImpl<ContentItem>(cacheService, contentLibraryCloudService)
  ) {}
  contentItemPage(pageRequest: PageRequest): Observable<Pageable<ContentItem>> {
    return this.contentLibraryCloudService.page(pageRequest);
  }

  get(uuid: string, cacheType: DataLoadType = 'cacheAndCloud'): Observable<ContentItem> {
    return this.getRepository.get(uuid, cacheType);
  }

  getAllByUuids(uuids: string[]): Observable<ContentItem[]> {
    return this.contentLibraryCloudService.getAllByUuids(uuids);
  }

  save(item: ContentItem): Observable<ContentItem> {
    return this.saveRepository.save(item);
  }
}
