export const shuffle = (array) => {
  const result = [];
  let dismantle = [...array];
  while (dismantle.length > 0) {
    const randomElementIndex = Math.round(Math.random() * (dismantle.length - 1));
    const elementToPush = dismantle[randomElementIndex];
    dismantle = dismantle.filter((item, index) => index !== randomElementIndex);
    result.push(elementToPush);
  }
  return result;
};
