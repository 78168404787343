import { BaseCloudService, DataLoadType } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import {
  COURSE_SET_PROGRESS,
  ENROLL_TO_COURSE,
  GET_ALL_CONTENT_BUILDERS,
  GET_ALL_COURSES,
  GET_COURSE,
  GET_COURSE_PAGE,
  PREVIEW_COURSE
} from '@/constants/api';
import { LearnerContentBuilder, LearnerCourse } from '@/models/learnerModels';

export class CourseCloudService extends BaseCloudService<LearnerCourse> {
  constructor() {
    super({ save: null, getAll: GET_ALL_COURSES, get: GET_COURSE, delete: null, page: GET_COURSE_PAGE });
  }

  setCourseProgress(setCourseProgress: {
    journeyUuid: string;
    courseBuilderUuid: string;
    blockUuid: string;
  }): Observable<LearnerCourse> {
    return this.networkService.post(COURSE_SET_PROGRESS, setCourseProgress);
  }

  getAllContent(cacheType: DataLoadType = 'cacheAndCloud'): Observable<LearnerContentBuilder[]> {
    return this.networkService.get(GET_ALL_CONTENT_BUILDERS);
  }

  preview(courseUuid: string) {
    return this.networkService.get(`${PREVIEW_COURSE}?uuid=${encodeURIComponent(courseUuid)}`);
  }

  getLpCourse(uuid: string, journeyUuid: string): Observable<LearnerCourse> {
    return this.networkService.get(
      `${GET_COURSE}?uuid=${encodeURIComponent(uuid)}&journeyUuid=${encodeURIComponent(journeyUuid)}`
    );
  }

  enrollToCourse(uuid: string): Observable<any> {
    return this.networkService.post(ENROLL_TO_COURSE, { contentItemUuid: uuid });
  }
}
