import React, { useCallback, useMemo } from 'react';

import { Typography, useMediaQuery, useTheme } from '@mui/material';
import { ContentItem, ContentItemType, THQTooltip, useTHQSnackbar } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useParams } from 'react-router-dom';

import { ButtonStyled, DownloadIconStyled, ShareIconStyled } from '@/components/library/actions/styles';
import { LIBRARY_OVERVIEW_STRING } from '@/constants/router';

interface CommonButtonProps {
  contentItem?: ContentItem;
  icon?: React.ReactElement;
  text: string;

  onClick?(): void;

  onlyDesktopVariant?: boolean;
}

const CommonButton: React.FC<CommonButtonProps> = ({ icon, text, onClick, onlyDesktopVariant = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleOnClick = useCallback(
    (event) => {
      event.stopPropagation();
      onClick();
    },
    [onClick]
  );

  return (
    <ButtonStyled onClick={handleOnClick}>
      {!onlyDesktopVariant && isMobile ? (
        <>
          {icon}
          <Typography fontSize={14} fontWeight={700}>
            {text}
          </Typography>
        </>
      ) : (
        icon
      )}
    </ButtonStyled>
  );
};

const LinkButton: React.FC<CommonButtonProps> = ({ contentItem, icon, text, onlyDesktopVariant = false }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <a
      href={contentItem?.mediaFile?.streamingUrl}
      download={contentItem?.mediaFile.name}
      rel="noopener noreferrer"
      target="_blank"
    >
      <ButtonStyled>
        {!onlyDesktopVariant && isMobile ? (
          <>
            {icon}
            <Typography fontSize={14} fontWeight={700}>
              {text}
            </Typography>
          </>
        ) : (
          icon
        )}
      </ButtonStyled>
    </a>
  );
};

const ViewButton: React.FC<CommonButtonProps> = ({ text, onClick }) => {
  const handleOnClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <ButtonStyled onClick={handleOnClick}>
      <Typography fontSize={14} fontWeight={700}>
        {text}
      </Typography>
    </ButtonStyled>
  );
};

export const useActions = (
  contentItem: ContentItem,
  navigateFunc: (contentItem: ContentItem) => void,
  shareLink?: string,
  previewActions?: boolean,
  onlyDesktopVariant?: boolean
): React.ReactElement[] => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routerParams = useParams();
  const snackbar = useTHQSnackbar();
  const url =
    contentItem?.contentItemType !== ContentItemType.DIRECTORY
      ? location.pathname.replace('library', LIBRARY_OVERVIEW_STRING)
      : location.pathname;

  const viewText =
    contentItem?.contentItemType === ContentItemType.COURSE
      ? t('view_course')
      : contentItem?.contentItemType === ContentItemType.MEDIA_FILE
      ? t('view_file')
      : t('open_folder');

  const handleGoToPreview = useCallback(() => {
    navigate(`/${LIBRARY_OVERVIEW_STRING}/${routerParams?.['*']}${routerParams?.['*'] ? '/' : ''}${contentItem.uuid}`);
  }, [contentItem?.uuid, navigate, routerParams]);

  const handleShare = useCallback(() => {
    navigator.clipboard.writeText(shareLink || `${window.location.host}${url}/${contentItem.uuid}`).then(() => {
      snackbar(t('link_copied'));
    });
  }, [contentItem, snackbar, url]);
  const handleNavigate = useCallback(() => {
    navigateFunc(contentItem);
  }, [contentItem, navigateFunc]);

  const viewAction = useMemo(
    () => (contentItem?.contentItemType === ContentItemType.DIRECTORY ? handleNavigate : handleGoToPreview),
    [contentItem?.contentItemType, handleNavigate, handleGoToPreview]
  );

  const shareButton = useMemo(
    () => (
      <THQTooltip arrow title={t('share')}>
        <CommonButton
          onlyDesktopVariant={onlyDesktopVariant}
          icon={<ShareIconStyled onlyDesktopVariant={onlyDesktopVariant} />}
          text={t('share_url')}
          onClick={handleShare}
        />
      </THQTooltip>
    ),
    [handleShare, onlyDesktopVariant]
  );
  const downloadButton = useMemo(
    () => (
      <THQTooltip arrow title={t('download')}>
        <LinkButton
          onlyDesktopVariant={onlyDesktopVariant}
          contentItem={contentItem}
          icon={<DownloadIconStyled onlyDesktopVariant={onlyDesktopVariant} />}
          text={t('download')}
        />
      </THQTooltip>
    ),
    [contentItem, onlyDesktopVariant]
  );
  const viewButton = useMemo(() => <ViewButton text={viewText} onClick={viewAction} />, [viewAction, viewText]);

  return useMemo(
    () => [
      shareButton,
      ...(contentItem?.contentItemType === ContentItemType.MEDIA_FILE ? [downloadButton] : []),
      ...(previewActions ? [] : [viewButton])
    ],
    [shareButton, contentItem?.contentItemType, downloadButton, previewActions, viewButton]
  );
};
