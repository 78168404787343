import { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';

import { useTheme } from '@mui/material';
import {
  ChallengeEventsCountStats,
  ChallengeScoreRate,
  ChallengeType,
  container,
  ErrorsByAttemptsData,
  errorsPerPairColors,
  ErrorsPerPairData,
  ErrorsPerPairStatsData,
  InsightsUserStatus,
  NaturalDetailsData,
  NRPSection,
  SearchCriteria,
  SuccessRateStatsData
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { forkJoin, Observable, Subscription } from 'rxjs';

import { mapTypeOption } from '@/hooks/roleplay/insights/utils';
import { RolePlayAnalyticsService } from '@/services/roleplay/analytics/rolePlayAnalyticsService';

export const useGetRolePlayEventsCountStats = (
  rolePlayUuid: string,
  selectedTypeOption: string,
  type: 'strict' | 'natural',
  skip?: boolean
): SuccessRateStatsData => {
  const { t } = useTranslation();
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [eventsCountStats, setEventsCountStats] = useState<SuccessRateStatsData>();

  useEffect(() => {
    const subs = new Subscription();

    if (rolePlayUuid && !skip) {
      setEventsCountStats((prevState) => ({ ...prevState, loadingEventsCount: true }));

      subs.add(
        rpAnalyticsService
          .getEventsCountStats(
            {
              rolePlayUuid,
              executionTypes: mapTypeOption(selectedTypeOption)
            },
            type
          )
          .subscribe({
            next: (eventsCountStats) => {
              const successCount = eventsCountStats.find((value) => value._id === 'success')?.eventCount || 0;
              const proceedCount = eventsCountStats.find((value) => value._id === 'proceed')?.eventCount || 0;

              const errorCount = eventsCountStats.find((value) => value._id === 'failure')?.eventCount || 0;
              const total = successCount + proceedCount + errorCount;
              const successRate = total > 0 ? Math.round(((successCount + proceedCount) / total) * 100) : 0;
              setEventsCountStats({
                totalAttempts: total,
                data: [
                  { label: t('success'), value: total === 0 ? 0 : successRate },
                  { value: total === 0 ? 0 : 100 - successRate, label: t('error') }
                ],
                successPercentage: `${successRate}%`,
                loadingEventsCount: false
              });
            },
            error: () => {
              setEventsCountStats((prevState) => ({ ...prevState, loadingEventsCount: false }));
            }
          })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [rolePlayUuid, rpAnalyticsService, selectedTypeOption, skip, type]);

  return eventsCountStats;
};

export const useGetErrorsByAttemptsStats = (
  rolePlayUuid: string,
  selectedTypeOption: string,
  type: 'strict' | 'natural',
  skip?: boolean
): ErrorsByAttemptsData => {
  const { t } = useTranslation();
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [errorsByAttemptsStats, setErrorsByAttemptsStats] = useState<ErrorsByAttemptsData>();

  useEffect(() => {
    const subs = new Subscription();

    if (rolePlayUuid && !skip) {
      setErrorsByAttemptsStats((prevState) => ({ ...prevState, errorsByAttemptsLoading: true }));

      subs.add(
        rpAnalyticsService
          .getErrorsByAttemptsStats(
            {
              rolePlayUuid,
              executionTypes: mapTypeOption(selectedTypeOption)
            },
            type
          )
          .subscribe({
            next: (errorsByAttemptsData) => {
              const attemptsLabels = errorsByAttemptsData?.global?.map((error, index) => {
                return `${t('attempt')} ${index + 1}`;
              });

              setErrorsByAttemptsStats({
                selectedUserData: errorsByAttemptsData?.global?.map((globalData, i) =>
                  errorsByAttemptsData?.user?.[i] === 0 ? 0 : errorsByAttemptsData?.user?.[i] || -1
                ),
                allLearnersData: errorsByAttemptsData.global,
                attemptsLabels,
                errorsByAttemptsLoading: false
              });
            },
            error: () => {
              setErrorsByAttemptsStats((prevState) => ({ ...prevState, errorsByAttemptsLoading: false }));
            }
          })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [rolePlayUuid, rpAnalyticsService, selectedTypeOption, skip, type]);

  return errorsByAttemptsStats;
};

export const useGetErrorsPerPairStats = (
  rolePlayUuid: string,
  selectedTypeOption: string,
  skip?: boolean
): ErrorsPerPairStatsData => {
  const theme = useTheme();
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [errorPerPairStats, setErrorPerPairStats] = useState<ErrorsPerPairStatsData>();

  useEffect(() => {
    const subs = new Subscription();

    if (rolePlayUuid && !skip) {
      setErrorPerPairStats((prevState) => ({ ...prevState, loading: true }));

      subs.add(
        rpAnalyticsService
          .getErrorsPerPairStats({ rolePlayUuid, executionTypes: mapTypeOption(selectedTypeOption) })
          .subscribe({
            next: (data) => {
              setErrorPerPairStats({
                errorsPerPairData: data.map((dataItem) => ({
                  data: [dataItem],
                  color: errorsPerPairColors(dataItem, theme)
                })),
                loading: false
              });
            },
            error: () => {
              setErrorPerPairStats((prevState) => ({ ...prevState, loading: false }));
            }
          })
      );
    }
  }, [rolePlayUuid, rpAnalyticsService, selectedTypeOption, skip, theme]);

  return errorPerPairStats;
};

export const useGetErrorPerPairPlain = (
  rolePlayUuid: string,
  selectedTypeOption: string,
  skip?: boolean
): {
  data: ErrorsPerPairData;
  loading?: boolean;
} => {
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [errorsPerPairData, setErrorsPerPairData] = useState<ErrorsPerPairData>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const subs = new Subscription();

    if (rolePlayUuid && !skip) {
      setLoading(true);
      subs.add(
        rpAnalyticsService
          .getErrorsPerPairPlain({ rolePlayUuid, executionTypes: mapTypeOption(selectedTypeOption) })
          .subscribe({
            next: (result) => {
              setErrorsPerPairData(result);
              setLoading(false);
            },
            error: () => {
              setLoading(false);
            }
          })
      );
    }
  }, [rolePlayUuid, rpAnalyticsService, selectedTypeOption, skip]);

  return useMemo(() => ({ data: errorsPerPairData, loading }), [errorsPerPairData, loading]);
};

export const useGetAttemptDetailsScorecardData = () => {
  const rpAnalyticsService = useRolePlayAnalyticsService();
  const { reportUuid, callUuid } = useParams<{
    reportUuid: string;
    callUuid: string;
  }>();

  const [scorecardData, setScorecardData] = useState(null);

  // TODO: add types
  const packData = useCallback((data: any) => {
    const flatGoalMap = [];
    data.goalVerificationReportData?.sections?.forEach((section) => {
      section.goals.forEach((goal) => {
        flatGoalMap.push({ ...goal, section: section.section });
      });
    });
    const result = {
      ...data,
      goalVerificationReportData: data.goalVerificationReportData,
      objectionVerificationReportData: data.objectionVerificationReportData
    };
    result.goalVerificationReportData['flatGoalMap'] = flatGoalMap;

    return result;
  }, []);

  const uuidToUse = reportUuid || callUuid;

  useEffect(() => {
    const subs = new Subscription();

    if (uuidToUse) {
      subs.add(
        rpAnalyticsService.getNaturalReport(uuidToUse).subscribe((res) => {
          const parsedScorecard = packData(res);
          setScorecardData(parsedScorecard);
        })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [packData, rpAnalyticsService, uuidToUse]);

  return scorecardData;
};

export const useGetChallengeScores = (
  rolePlayUuid: string,
  selectedTypeOption: string,
  skip?: boolean
): { best: ChallengeScoreRate[]; worst: ChallengeScoreRate[]; loadingChallenges?: boolean } => {
  const rpAnalyticsService = useRolePlayAnalyticsService();
  const [challengeScores, setChallengeScores] = useState<{
    best: ChallengeScoreRate[];
    worst: ChallengeScoreRate[];
  }>({ best: [], worst: [] });
  const [loadingChallenges, setLoadingChallenges] = useState<boolean>(false);

  const bestScoresRequest = useCallback(
    (sort: 'ASC' | 'DESC') => ({
      rolePlayUuid,
      executionTypes: mapTypeOption(selectedTypeOption),
      page: 0,
      size: 3,
      sort
    }),
    [rolePlayUuid, selectedTypeOption]
  );

  useEffect(() => {
    const subs = new Subscription();

    if (rolePlayUuid && !skip) {
      setLoadingChallenges(true);
      const challenges$: Observable<ChallengeScoreRate[]>[] = [
        rpAnalyticsService.getChallengeScores(bestScoresRequest('DESC')),
        rpAnalyticsService.getChallengeScores(bestScoresRequest('ASC'))
      ];
      subs.add(
        forkJoin(challenges$).subscribe({
          next: (resultArray) => {
            setChallengeScores({ best: resultArray[0], worst: resultArray[1] });
          },
          complete: () => {
            setLoadingChallenges(false);
          }
        })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [bestScoresRequest, rolePlayUuid, rpAnalyticsService, skip]);

  return useMemo(() => ({ ...challengeScores, loadingChallenges }), [challengeScores, loadingChallenges]);
};

export const useGetChallengesEventsCountStats = (
  rolePlayUuid: string,
  selectedTypeOption: string,
  skip?: boolean
): { challengesEventsCount: ChallengeEventsCountStats[]; challengesEventsCountLoading?: boolean } => {
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [challengesEventsCount, setChallengesEventsCount] = useState<ChallengeEventsCountStats[]>([]);
  const [challengesEventsCountLoading, setChallengesEventsCountLoading] = useState<boolean>(false);

  useEffect(() => {
    const subs = new Subscription();

    if (rolePlayUuid && !skip) {
      setChallengesEventsCountLoading(true);
      subs.add(
        rpAnalyticsService
          .getChallengesEventsCountNatural({
            rolePlayUuid,
            executionTypes: mapTypeOption(selectedTypeOption)
          })
          .subscribe({
            next: (challengesEventsCount) => {
              setChallengesEventsCount(challengesEventsCount);
            },
            complete: () => {
              setChallengesEventsCountLoading(false);
            }
          })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [rolePlayUuid, rpAnalyticsService, selectedTypeOption, skip]);

  return useMemo(
    () => ({
      challengesEventsCount,
      challengesEventsCountLoading
    }),
    [challengesEventsCount, challengesEventsCountLoading]
  );
};

interface GetSubmittedNRPReports {
  rolePlayUuid: string;
  rolePlayTypes?: string[];
  createdAtBefore: number;
  createdAtAfter: number;
  page: number;
  rowsPerPage: number;
  successRateFrom?: number;
  successRateTo?: number;
}

export const useGetSubmittedNRPReports = (
  request: GetSubmittedNRPReports,
  tabValue: number
): {
  logResults: any;
  loadingLogResults: boolean;
  totalElements?: number;
} => {
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [logResults, setLogResults] = useState([]);
  const [loadingLogResults, setLoadingLogResults] = useState<boolean>(false);
  const [totalElements, setTotalElements] = useState<number>(0);

  useEffect(() => {
    const subs = new Subscription();
    if (request.rolePlayUuid && tabValue === 2) {
      setLoadingLogResults(true);
      subs.add(
        rpAnalyticsService
          .searchSubmittedNRPReports({
            page: request.page,
            size: request.rowsPerPage,
            sort: [{ column: 'name', sort: 'ASC' }],
            criterias: userValidationReportsCriterias(request)
          })
          .subscribe((res) => {
            setLogResults(res.content);
            setTotalElements(res.totalElements);
            setLoadingLogResults(false);
          })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [request, rpAnalyticsService, tabValue]);

  return useMemo(
    () => ({ totalElements, logResults, loadingLogResults }),
    [totalElements, loadingLogResults, logResults]
  );
};

export const userValidationReportsCriterias = (props: GetSubmittedNRPReports) => {
  const commonCrit: SearchCriteria[] = [];
  const { rolePlayUuid, successRateFrom, successRateTo, createdAtAfter, createdAtBefore, rolePlayTypes } = props;

  if (rolePlayTypes?.length > 0) {
    commonCrit.push({
      key: 'id.executionType',
      value: rolePlayTypes,
      operation: 'in',
      relationType: 'AND'
    });
  }

  if (successRateFrom) {
    commonCrit.push({
      key: 'successRate',
      value: successRateFrom,
      operation: 'gte',
      relationType: 'AND'
    });
  }
  if (successRateTo) {
    commonCrit.push({
      key: 'successRate',
      value: successRateTo,
      operation: 'lt',
      relationType: 'AND'
    });
  }

  if (createdAtBefore) {
    commonCrit.push({
      key: 'submitDate',
      value: createdAtBefore,
      operation: 'lte',
      relationType: 'AND'
    });
  }

  if (createdAtAfter) {
    commonCrit.push({
      key: 'submitDate',
      value: createdAtAfter,
      operation: 'gte',
      relationType: 'AND'
    });
  }

  const criterias: SearchCriteria[] = [
    {
      key: 'rolePlayUuid',
      value: rolePlayUuid,
      operation: 'equals',
      relationType: 'AND'
    },
    ...commonCrit
  ];

  return criterias;
};

export const useGetNaturalDetailsData = (
  rolePlayUuid: string,
  selectedTypeOption: string,
  skip?: boolean,
  challengeType?: ChallengeType,
  sections?: string,
  successRateFrom?: number,
  successRateTo?: number
): {
  loading: boolean;
  naturalDetailsData: NaturalDetailsData;
} => {
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [naturalDetailsData, setNaturalDetailsData] = useState<NaturalDetailsData>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const subs = new Subscription();

    if (rolePlayUuid && !skip) {
      setLoading(true);
      subs.add(
        rpAnalyticsService
          .getNaturalDetails({
            rolePlayUuid,
            page: 0,
            size: 999,
            executionTypes: mapTypeOption(selectedTypeOption),
            challengeType: challengeType ? [challengeType] : null,
            sections: sections ? [sections] : null,
            successRateFrom,
            successRateTo
          })
          .subscribe({
            next: (res) => {
              setNaturalDetailsData(res);
            },
            complete: () => {
              setLoading(false);
            }
          })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [
    challengeType,
    rolePlayUuid,
    rpAnalyticsService,
    sections,
    selectedTypeOption,
    skip,
    successRateFrom,
    successRateTo
  ]);

  return useMemo(() => ({ loading, naturalDetailsData }), [loading, naturalDetailsData]);
};

export const useGetNRPSections = (rpUuid: string): NRPSection[] => {
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [sections, setSections] = useState<NRPSection[]>([{ section: 'All', uuid: '-' }]);

  useEffect(() => {
    const subs = new Subscription();
    if (rpUuid) {
      subs.add(
        rpAnalyticsService.getNaturalSections(rpUuid).subscribe({
          next: (res) => {
            setSections((prevState) => [...prevState, ...res]);
          }
        })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [rpAnalyticsService, rpUuid]);

  return sections;
};

export const useGetRPUserStatus = (rolePlayUuid: string): InsightsUserStatus => {
  const rpAnalyticsService = useRolePlayAnalyticsService();

  const [status, setStatus] = useState<InsightsUserStatus>();

  useEffect(() => {
    const subs = new Subscription();
    if (rolePlayUuid) {
      subs.add(
        rpAnalyticsService.getUserStatus(rolePlayUuid).subscribe({
          next: (res) => {
            setStatus(res);
          }
        })
      );
    }

    return () => {
      subs.unsubscribe();
    };
  }, [rolePlayUuid, rpAnalyticsService]);

  return status;
};

export const RolePlayAnalyticsServiceContext = createContext<RolePlayAnalyticsService>(
  container.resolve('rolePlayAnalyticsService')
);

export const useRolePlayAnalyticsService = () => useContext(RolePlayAnalyticsServiceContext);
