import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQIconButton, TimeIcon } from '@trainhq/trainhq-client-core';

export const CourseDetailsRootGridStyled = styled(Grid)(() => ({
  height: '100%'
}));

interface SidebarRootGridStyledProps {
  playerPresent?: boolean;
}

export const SidebarRootGridStyled = styled(Grid, {
  shouldForwardProp: (props) => props !== 'playerPresent'
})<SidebarRootGridStyledProps>(({ playerPresent }) => ({
  paddingTop: 0,
  ...(playerPresent && {
    paddingBottom: '200px'
  })
}));

export const IconButtonStyled = styled(THQIconButton)(() => ({
  border: '1px solid #ECF1F4',
  minWidth: '48px',
  maxWidth: '48px',
  minHeight: '48px',
  maxHeight: '48px',
  borderRadius: '18px'
}));

export const CourseTitleTypographyStyled = styled(Typography)(({ theme }) => ({
  fontSize: 18,
  fontWeight: 700,
  lineHeight: '28px',
  color: theme.palette.grey[400]
}));

interface ExpandIconDivStyledProps {
  location: 'left' | 'right';
}

export const ExpandIconDivStyled = styled('div')<ExpandIconDivStyledProps>(({ theme, location }) => ({
  left: location === 'left' ? 40 : undefined,
  position: 'fixed',
  right: location === 'right' ? 40 : undefined,
  top: 40,
  zIndex: 8,
  [theme.breakpoints.down('sm')]: {
    left: location === 'left' ? 16 : undefined,
    right: location === 'right' ? 16 : undefined,
    top: 16
  }
}));

export const TimeSpentInSidebarTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.typography.body2.color,
  fontSize: 12
}));

export const TimeIconStyled = styled(TimeIcon)(({ theme }) => ({
  color: theme.typography.body2.color,
  height: 12,
  display: 'flex',
  width: 12
}));
