import {
  BackgroundTask,
  DataLoadType,
  GetPageRepository,
  GetRepository,
  GetWithCacheRepositoryImpl,
  Injectable,
  Pageable,
  PageRequest,
  RoleplayBuilder,
  RoleplayExecutionConfig,
  RoleplayLogAction,
  RoleplayMessage,
  RolePlayModePhase,
  RoleplayUserSummary
} from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { BackgroundVerificationTaskResult } from '@/models/learnerModels';
import { RoleplayCacheService } from '@/services/repositoryData/cache/roleplayCacheService';
import { RecodingUploadDto, RolePlaySessionDto } from '@/services/repositoryData/cloud/dto/roleplayCloudDtos';
import { RolePlayCloudService } from '@/services/repositoryData/cloud/rolePlayCloudService';

export interface RoleplayRepository extends GetRepository<RoleplayBuilder, string>, GetPageRepository<RoleplayBuilder> {
  addRecording(payload: RecodingUploadDto): Observable<void>;
  createRolePlaySession(roleplayUuid?: string, journeyUuid?: string): Observable<RolePlaySessionDto>;
  getBackgroundTask(uuid: string): Observable<BackgroundVerificationTaskResult>;
  getStt(sessionUuid: string): Observable<RoleplayExecutionConfig>;
  init(sessionUuid: string): Observable<RoleplayMessage>;
  logAction(payload: RoleplayLogAction): Observable<any>;
  refreshRoleplaySummary(
    roleplay?: string,
    journeyUuid?: string,
    executionType?: 'TESTING' | 'PRACTICE'
  ): Observable<RoleplayUserSummary>;
  requestAzureToken(): Observable<any>;
  respond(
    payload: {
      sessionUuid: string;
      covered_steps: number[];
      step: number;
      history: any[];
      company?: string;
      learner_input: string;
      fail_occurred: boolean;
    },
    apiV2?: boolean
  ): Observable<any>;
  submitNaturalValidation(payload: any): Observable<BackgroundTask>;
  setRolePlayPhase(payload: {
    roleplayUuid: string;
    journeyUuid: string;
    rolePlayModePhase: RolePlayModePhase;
  }): Observable<any>;
}

@Injectable('roleplayRepository')
export class RoleplayRepositoryImpl implements RoleplayRepository {
  constructor(
    private roleplayCloudService = new RolePlayCloudService(),
    cacheService = new RoleplayCacheService(),
    private getRepository = new GetWithCacheRepositoryImpl(cacheService, roleplayCloudService.get)
  ) {}

  addRecording(payload: RecodingUploadDto): Observable<void> {
    return this.roleplayCloudService.addRecording(payload);
  }

  createRolePlaySession(roleplayUuid?: string, journeyUuid?: string): Observable<RolePlaySessionDto> {
    return this.roleplayCloudService.createRolePlaySession(roleplayUuid, journeyUuid);
  }

  get(uuid: string, cacheType: DataLoadType = 'cache'): Observable<RoleplayBuilder> {
    return this.getRepository.get(uuid, cacheType);
  }

  getBackgroundTask(uuid: string): Observable<BackgroundVerificationTaskResult> {
    return this.roleplayCloudService.getBackgroundTask(uuid);
  }

  getStt(sessionUuid: string): Observable<RoleplayExecutionConfig> {
    return this.roleplayCloudService.getStt(sessionUuid);
  }

  init(sessionUuid: string): Observable<RoleplayMessage> {
    return this.roleplayCloudService.initRolePlay(sessionUuid);
  }

  logAction(payload: RoleplayLogAction): Observable<any> {
    return this.roleplayCloudService.logAction(payload);
  }

  page(pageRequest: PageRequest): Observable<Pageable<RoleplayBuilder>> {
    return this.roleplayCloudService.page(pageRequest);
  }

  refreshRoleplaySummary(
    roleplay?: string,
    journeyUuid?: string,
    executionType: 'TESTING' | 'PRACTICE' = 'PRACTICE'
  ): Observable<RoleplayUserSummary> {
    return this.roleplayCloudService.refreshRoleplaySummary(roleplay, journeyUuid, executionType);
  }

  requestAzureToken(): Observable<any> {
    return this.roleplayCloudService.requestAzureToken();
  }

  respond(
    payload: {
      sessionUuid: string;
      covered_steps: number[];
      step: number;
      history: any[];
      company?: string;
      learner_input: string;
      fail_occurred: boolean;
    },
    apiV2?: boolean
  ): Observable<any> {
    return this.roleplayCloudService.respond(payload, apiV2);
  }

  submitNaturalValidation(payload: any): Observable<BackgroundTask> {
    return this.roleplayCloudService.submitNaturalValidation(payload);
  }

  setRolePlayPhase = (payload: {
    roleplayUuid: string;
    journeyUuid: string;
    rolePlayModePhase: RolePlayModePhase;
  }): Observable<any> => {
    return this.roleplayCloudService.setRolePlayPhase(payload);
  };
}
