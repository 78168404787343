import { styled } from '@mui/material/styles';
import { THQPrimaryButton } from '@trainhq/trainhq-client-core';

import BurgerButton from '@/components/journey/common/BurgerButton';

export const BurgerButtonStyled = styled(BurgerButton)(() => ({
  left: 32,
  position: 'fixed',
  top: 24
}));

export const XButtonStyled = styled(THQPrimaryButton)(() => ({
  borderRadius: 12,
  maxWidth: 48,
  position: 'fixed',
  right: 32,
  top: 24
}));
