import React from 'react';

import { SendInfoBySms } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';

import { RESET_PASSWORD, RESET_PASSWORD_SMS_VERIFY } from '@/constants/router';
import { useUserManagementService } from '@/hooks/userManagement/useUserManagementService';

const ResetViaSMS: React.FC = () => {
  const navigate = useNavigate();
  const userManagerService = useUserManagementService();

  const handleRequest = (email: string) => {
    return userManagerService.sendForgotLink(email);
  };

  const handleGoToCodeVerification = () => {
    navigate(RESET_PASSWORD_SMS_VERIFY);
  };

  const handleGoBack = () => {
    navigate(RESET_PASSWORD);
  };

  return <SendInfoBySms onDone={handleGoToCodeVerification} onGoBack={handleGoBack} onSubmit={handleRequest} />;
};

export default ResetViaSMS;
