import React, { useMemo, useState } from 'react';

import { JourneyItem, MainLayout } from '@trainhq/trainhq-client-core';
import { useParams } from 'react-router-dom';

import JourneyDetails from '@/components/journey/JourneyDetails';
import { JourneyContext, JourneySettersContext } from '@/context/journey';
import { useGetJourney } from '@/hooks/journey/useJourneyService';
import NotFoundPage from '@/pages/notFound/NotFoundPage';

const JourneyLayout: React.FC = () => {
  const { journeyUuid } = useParams<{ journeyUuid: string }>();

  const [openItemDrawer, setOpenItemDrawer] = useState<boolean>(false);
  const [openDetailsDrawer, setOpenDetailsDrawer] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<JourneyItem>();
  const [parentGroupUuid, setParentGroupUuid] = useState<string>();

  const { journey, setJourney, notFound } = useGetJourney(journeyUuid);

  const detailsContext = useMemo(
    () => ({
      journey,
      openDetailsDrawer,
      openItemDrawer,
      parentGroupUuid,
      selectedItem
    }),
    [journey, openDetailsDrawer, openItemDrawer, parentGroupUuid, selectedItem]
  );
  const settersContext = useMemo(
    () => ({
      setOpenDetailsDrawer,
      setOpenItemDrawer,
      setJourney,
      setParentGroupUuid,
      setSelectedItem
    }),
    [setJourney]
  );

  return (
    <JourneyContext.Provider value={detailsContext}>
      <JourneySettersContext.Provider value={settersContext}>
        {notFound ? (
          <NotFoundPage />
        ) : (
          <MainLayout>
            <JourneyDetails />
          </MainLayout>
        )}
      </JourneySettersContext.Provider>
    </JourneyContext.Provider>
  );
};

export default JourneyLayout;
