import { LinearProgress, linearProgressClasses, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BorderLinearProgressStyled = styled(LinearProgress)(({ theme }) => ({
  height: 4,
  borderRadius: '4px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: '#ECF1F4' //theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.primary.main : '#308fe8'
  }
}));

export const ProgressTypographyStyled = styled(Typography)(({ theme }) => ({
  fontSize: 12,
  fontWeight: 400,
  color: theme.typography.body2.color
}));
