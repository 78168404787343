import React from 'react';

import { EmptyStateTemplate, MainLayout, THQMainPageContainer } from '@trainhq/trainhq-client-core';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ReactComponent as Logo } from '@/assets/icons/logo.svg';
import notFoundHeroImg from '@/assets/images/not_found_hero.png';
import ThemeLogo from '@/components/common/themeLogo/ThemeLogo';
import { HOME } from '@/constants/router';
import { LogoContainerStyled } from '@/pages/notFound/styles';
import i18n from '@/services/i18n-no-auth';

const NotFoundPage: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRedirectToDashboard = () => {
    navigate(HOME);
  };
  return (
    <I18nextProvider i18n={i18n}>
      <MainLayout>
        <THQMainPageContainer>
          <LogoContainerStyled>
            <ThemeLogo width={'96px'} />
          </LogoContainerStyled>
          <EmptyStateTemplate
            title={t('cant_find')}
            description={t('cant_find_desc')}
            buttonAction={handleRedirectToDashboard}
            buttonText={t('cant_find_button')}
            heroImage={notFoundHeroImg}
            heroImageMaxWidth={240}
          />
        </THQMainPageContainer>
      </MainLayout>
    </I18nextProvider>
  );
};

export default NotFoundPage;
