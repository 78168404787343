import React, { useCallback } from 'react';

import { Container, Grid } from '@mui/material';
import { BlockContent } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

import {
  CenteredTextDivStyled,
  ImageStyled,
  MediaComponentPlayerStyled,
  MediaTitleAndSubtitleContainer,
  RootGridStyled,
  SubTitleTypographyStyled,
  TitleTypographyStyled
} from '@/components/course/block/media/styles';
import BlockNav from '@/components/course/block/navigation/BlockNav';

export interface MediaBlockProps {
  content: BlockContent;
  sidebarExpanded?: boolean;
}

export const MediaBlock: React.FC<MediaBlockProps> = ({ content }) => {
  const { t } = useTranslation();
  const renderMediaContent = useCallback(() => {
    switch (content?.mediaContent?.fileMetadata?.mediaFileType) {
      case 'IMAGE':
        return (
          <CenteredTextDivStyled>
            <ImageStyled alt="media-img" src={content?.mediaContent?.streamingUrl || content?.mediaContent.fileUrl} />
          </CenteredTextDivStyled>
        );
      case 'AUDIO':
      case 'VIDEO':
      default:
        return (
          <Container maxWidth="md" sx={{ height: '100%' }}>
            <Grid alignItems="center" container justifyContent="center" sx={{ height: '100%', padding: 2 }}>
              <Grid item xs={12}>
                <MediaComponentPlayerStyled file={content?.mediaContent} />
                {(content?.title || content?.subtitle) && (
                  <MediaTitleAndSubtitleContainer>
                    <TitleTypographyStyled>{t(content.title)}</TitleTypographyStyled>
                    <SubTitleTypographyStyled>{t(content.subtitle)}</SubTitleTypographyStyled>
                  </MediaTitleAndSubtitleContainer>
                )}
              </Grid>
            </Grid>
          </Container>
        );
    }
  }, [content?.subtitle, content?.title, content?.mediaContent]);

  return (
    <>
      <RootGridStyled alignItems="center" container justifyContent="center">
        <Grid item sx={{ height: '100%' }} xs={content?.title && content?.title !== '' ? 10 : 12}>
          {renderMediaContent()}
        </Grid>
      </RootGridStyled>
      <BlockNav />
    </>
  );
};
