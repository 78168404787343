import React, { useMemo } from 'react';

import { ProgressStatus } from '@trainhq/trainhq-client-core';

import ContentBuilderListTabPanel from '@/components/common/contentBuilderList/tabs/ContentBuilderListTabPanel';
import { LearnerContentBuilder } from '@/models/learnerModels';
import { resolveCourseState } from '@/utils/courseUtils';

export enum ActiveTab {
  COMPLETED = 'COMPLETED',
  YOUR_CONTENT = 'YOUR_CONTENT'
}

export interface CourseListProps {
  contentBuilders?: LearnerContentBuilder[];
  loading?: boolean;
  returnLink?: string;
}

export const ContentBuilderList: React.FC<CourseListProps> = ({ contentBuilders, loading, returnLink }) => {
  const unfinishedContentBuilders = useMemo(
    () =>
      contentBuilders?.filter((contentBuilder) => {
        if (contentBuilder.contentBuilderType === 'ROLE_PLAY') {
          return (
            contentBuilder.rolePlay.progressStatus !== ProgressStatus.COMPLETED &&
            contentBuilder.rolePlay.finishedInARow <= contentBuilder.rolePlay.passRequirement
          );
        } else if (contentBuilder.contentBuilderType === 'COURSE') {
          return resolveCourseState(contentBuilder.course) !== ProgressStatus.COMPLETED;
        } else if (contentBuilder.contentBuilderType === 'JOURNEY') {
          return contentBuilder.journey.progressStatus !== ProgressStatus.COMPLETED;
        }
        return contentBuilder.journey.numberOfSteps !== contentBuilder.journey.numberOfCompletedSteps;
      }),
    [contentBuilders]
  );

  return (
    <ContentBuilderListTabPanel
      contentBuilders={unfinishedContentBuilders}
      index={0}
      loading={loading}
      returnLink={returnLink}
      tabValue={0}
    />
  );
};
