import { styled } from '@mui/material/styles';
import { THQHeadline } from '@trainhq/trainhq-client-core';

interface LoadingOverlayProps {
  loading?: boolean;
}

export const TokenExpiredHeadline = styled(THQHeadline)(() => ({
  padding: '16px',
  borderRadius: '12px',
  boxShadow: '0px 0px 4px 4px rgba(255,255,255,0.77)',
  backgroundColor: '#FFFFFF'
}));

export const TokenInvalidOverlay = styled('div', {
  shouldForwardProp: (prop) => prop !== 'loading'
})<LoadingOverlayProps>(({ loading }) => ({
  zIndex: '10',
  position: 'absolute',
  minHeight: '100vh',
  minWidth: '100vw',
  background: '#FFFFFFAB',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  ...(!loading && {
    background: '#FFFFFFDE'
  }),
  ...(loading && {
    '@keyframes colorchange': {
      '0%': {
        backgroundColor: '#F4F7F9AB'
      },
      '50%': {
        backgroundColor: '#BED3E7AB'
      },
      '100%': {
        backgroundColor: '#F4F7F9AB'
      }
    },
    animation: 'colorchange 2s infinite'
  })
}));
