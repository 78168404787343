import { styled } from '@mui/material/styles';
import { THQTabPanel } from '@trainhq/trainhq-client-core';

interface NRPGoalsContainerProps {
  fadeChat?: boolean;
}

export const NRPGoalsWrapper = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fadeChat'
})<NRPGoalsContainerProps>(({ theme }) => ({
  maxWidth: '336px',
  height: '98vh',
  paddingTop: '16px',
  paddingLeft: '16px',
  [theme.breakpoints.up('lg')]: {
    position: 'fixed',
    top: 0
  }
}));

export const NRPGoalsContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fadeChat'
})<NRPGoalsContainerProps>(({ theme }) => ({
  paddingTop: '12px',
  border: `1px solid ${theme.palette.common.pearl}`,
  borderRadius: '16px',
  height: 'calc(100% - 100px)',
  width: '336px',
  backgroundColor: theme.palette.common.zima
}));

export const TabPanelStyled = styled(THQTabPanel)(() => ({
  paddingLeft: '12px',
  paddingRight: '12px',
  height: 'calc(100% - 120px)',
  overflowY: 'auto'
}));
