import {
  DataLoadType,
  GetPageRepository,
  GetRepository,
  GetWithCacheRepositoryImpl,
  Injectable,
  Journey,
  Pageable,
  PageRequest
} from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { JourneyCacheService } from '@/services/repositoryData/cache/journeyCacheService';
import { JourneyCloudService } from '@/services/repositoryData/cloud/journeyCloudService';

export interface JourneyRepository extends GetRepository<Journey, string>, GetPageRepository<Journey> {}

@Injectable('journeyRepository')
export class JourneyRepositoryImpl implements JourneyRepository {
  constructor(
    private journeyCloudService = new JourneyCloudService(),
    private cacheService = new JourneyCacheService(),
    private getRepository = new GetWithCacheRepositoryImpl(cacheService, journeyCloudService.get)
  ) {}

  get(uuid: string, cacheType: DataLoadType = 'cache'): Observable<Journey> {
    return this.getRepository.get(uuid, cacheType);
  }

  page = (pageRequest: PageRequest): Observable<Pageable<Journey>> => {
    return this.journeyCloudService.page(pageRequest);
  };
}
