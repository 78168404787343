import React from 'react';

import { THQBody } from '@trainhq/trainhq-client-core';

import { EmptyCardContainer } from '@/components/common/emptyCard/styles';

export interface EmptyCardProps {
  description?: string;
  image?: string;
}

const EmptyCard: React.FC<EmptyCardProps> = ({ description, image }) => {
  return (
    <EmptyCardContainer container direction="column">
      <img src={image} style={{ marginBottom: '14px', width: 'auto', height: '84px' }} />
      <THQBody sx={{ width: '70%', textAlign: 'center' }}>{description}</THQBody>
    </EmptyCardContainer>
  );
};

export default EmptyCard;
