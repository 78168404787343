import React from 'react';

import { LinearProgressProps } from '@mui/material';

import { BorderLinearProgressStyled } from '@/components/common/progress/styles';

const BorderLinearProgress: React.FC<LinearProgressProps> = ({ ...props }) => {
  return <BorderLinearProgressStyled {...props} />;
};

export default BorderLinearProgress;
