import { styled } from '@mui/material/styles';
import { THQSmallHeadline } from '@trainhq/trainhq-client-core';

interface DueDateTypographyProps {
  overdue?: boolean;
}
export const DueDateTypography = styled(THQSmallHeadline, {
  shouldForwardProp: (prop) => prop !== 'overdue'
})<DueDateTypographyProps>(({ theme, overdue }) => ({
  color: overdue ? theme.palette.error.foreground : theme.palette.common.smoke
}));
