import React, { useEffect, useMemo, useRef, useState } from 'react';

import { Grid } from '@mui/material';
import { useParams, useSearchParams } from 'react-router-dom';
import { Subscription } from 'rxjs';

import { BlockContent } from '@/components/course/block/blockContent/BlockContent';
import { BlockContentWrapperStyled } from '@/components/course/block/styles';
import { CourseContext, useCourseContext } from '@/components/course/courseDetails/context';
import { shuffle } from '@/components/course/courseDetails/utils';
import { useCourseService } from '@/hooks/course/useCourseService';
import { LearnerBlock } from '@/models/learnerModels';

const BlockDetails: React.FC = () => {
  const { blockUuid } = useParams<{ blockUuid: string }>();
  const [searchParams] = useSearchParams();
  const previewModeOn = searchParams.get('preview') == '1';
  const journeyUuid = searchParams.get('journeyUuid');
  const courseService = useCourseService(previewModeOn);
  const courseContext = useCourseContext();
  const ref = useRef<HTMLDivElement>();

  const [blockDetails, setBlockDetails] = useState<LearnerBlock>(); // TODO: use model from core

  const currentSection = courseContext?.course?.sections.find((section) =>
    section.blocks.find((block) => block.uuid === blockDetails?.uuid)
  );

  // TODO: extract to hook
  useEffect(() => {
    const subs = new Subscription();
    subs.add(
      courseService.getCourseBlock(blockUuid, journeyUuid).subscribe({
        next: (block) => {
          if (block.learnerMultipleChoiceQuiz) {
            block.learnerMultipleChoiceQuiz.answers = shuffle(block.learnerMultipleChoiceQuiz.answers);
          }
          setBlockDetails(block);
        }
      })
    );
    if (courseContext?.nextBlock?.uuid) {
      subs.add(courseService.getCourseBlock(courseContext.nextBlock.uuid, journeyUuid, 'cache').subscribe());
    }
    if (
      courseContext?.previousBlock?.uuid &&
      ['QUIZ', 'MULTIPLE_CHOICE_QUIZ'].includes(courseContext?.previousBlock?.blockTemplate?.blockType)
    ) {
      subs.add(
        courseService.getCourseBlock(courseContext.previousBlock.uuid, journeyUuid, 'cacheAndCloud').subscribe()
      );
    }
    return () => {
      subs.unsubscribe();
    };
  }, [
    setBlockDetails,
    courseService,
    journeyUuid,
    courseContext?.nextBlock?.uuid,
    courseContext?.previousBlock?.uuid,
    courseContext?.previousBlock?.blockTemplate?.blockType,
    blockUuid
  ]);

  // Scroll to top when going to next/previous block
  useEffect(() => {
    if (ref?.current?.scrollTop !== 0) {
      ref.current.scrollTop = 0;
    }
  });

  const updatedContextValue = useMemo(
    () => ({ ...courseContext, blockDetails, setBlockDetails }),
    [blockDetails, courseContext]
  );

  return (
    <BlockContentWrapperStyled sidebarExpanded={courseContext?.sidebarExpanded} item ref={ref}>
      <Grid container sx={{ height: '100%' }}>
        <Grid item sx={{ overflow: 'auto', width: '100%', position: 'relative' }}>
          <CourseContext.Provider value={updatedContextValue}>
            <BlockContent blockDetails={blockDetails} section={currentSection} />
          </CourseContext.Provider>
        </Grid>
      </Grid>
    </BlockContentWrapperStyled>
  );
};

export default BlockDetails;
