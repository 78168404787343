import { useContext, useEffect, useMemo, useState } from 'react';

import {
  FeaturePermission,
  PageRequest,
  PaginationCoordinator,
  RoleplayBuilder,
  RoleplayExecutionConfig,
  useFeaturesEnabled,
  useGetPagination
} from '@trainhq/trainhq-client-core';
import { finalize, Observable, Subscription } from 'rxjs';

import { RolePlayServiceContext } from '@/context/roleplay';

export const useRolePlayService = () => useContext(RolePlayServiceContext);

export const useGetAllRoleplays = (): { rolePlays: RoleplayBuilder[]; loading: boolean } => {
  const [rolePlays, setRoleplays] = useState<any[]>();
  const [loading, setLoading] = useState<boolean>();
  const rpService = useRolePlayService();

  useEffect(() => {
    setLoading(true);
    const subs = rpService
      .getRoleplaysPage({ page: 0, size: 9999 })
      .pipe(
        finalize(() => {
          setLoading(false);
        })
      )
      .subscribe({
        next: (roleplays) => {
          setRoleplays(roleplays?.content || []);
        }
      });
    return () => subs.unsubscribe();
  }, [rpService]);

  return useMemo(
    () => ({
      rolePlays,
      loading
    }),
    [rolePlays, loading]
  );
};

export const useGetRoleplaysPage = (pageRequest: PageRequest): PaginationCoordinator<RoleplayBuilder> => {
  const rpService = useRolePlayService();
  return useGetPagination<RoleplayBuilder>(rpService.getRoleplaysPage, pageRequest);
};

export const useCreateRolePlayAndGetConfig = (
  createSessionAndFetchConfig: () => Observable<RoleplayExecutionConfig>
): void => {
  const rolePlayFeatureEnabled = useFeaturesEnabled([FeaturePermission.ROLE_PLAY]);

  useEffect(() => {
    const subs = new Subscription();

    if (rolePlayFeatureEnabled) {
      subs.add(createSessionAndFetchConfig().subscribe());
    }

    return () => {
      subs.unsubscribe();
    };
  }, [createSessionAndFetchConfig, rolePlayFeatureEnabled]);
};
