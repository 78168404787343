import { Inject, Injectable, NetworkService, User, PasswordPolicy } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import {
  GET_CURRENT_USER,
  GET_PASSWORD_POLICY,
  GET_PASSWORD_POLICY_FOR_VERIFICATION,
  USER_LEARNER_CHANGE_PASSWORD,
  USER_LEARNER_FORGOT_LINK,
  USER_LEARNER_RESEND_VERIFICATION_LINK
} from '@/constants/api';

@Injectable('userCloudService')
export class UserCloudService {
  @Inject('networkService')
  networkService!: NetworkService;

  get(): Observable<User> {
    return this.networkService.get<User>(GET_CURRENT_USER);
  }

  sendForgotLink(email: string): Observable<any> {
    return this.networkService.get(
      `${USER_LEARNER_FORGOT_LINK}?username=${encodeURIComponent(email)}`,
      undefined,
      true
    );
  }

  resendVerificationLink(email: string): Observable<any> {
    return this.networkService.get(
      `${USER_LEARNER_RESEND_VERIFICATION_LINK}?email=${encodeURIComponent(email)}`,
      undefined,
      true
    );
  }

  changePassword(changePasswordData: { oldPassword: string; newPassword: string }) {
    return this.networkService.post(`${USER_LEARNER_CHANGE_PASSWORD}`, changePasswordData);
  }

  getPasswordPolicy(): Observable<PasswordPolicy> {
    return this.networkService.get<PasswordPolicy>(GET_PASSWORD_POLICY);
  }

  getPasswordPolicyForVerification(verificationToken: string) {
    return this.networkService.get<PasswordPolicy>(
      `${GET_PASSWORD_POLICY_FOR_VERIFICATION}?verificationToken=${verificationToken}`,
      undefined,
      true
    );
  }
}
