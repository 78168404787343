import React, { createContext, useCallback, useContext, useEffect, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import {
  THQDialog,
  THQDialogActionButton,
  THQDialogActions,
  THQDialogContent,
  THQDialogTitle,
  useTHQSnackbar
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { Subscription, timer } from 'rxjs';

type RolePlayInactivityDialogContext = (inactivityPeriodInMinutes: number, moveOutOfPracticeMode: () => void) => void;

const context = createContext<RolePlayInactivityDialogContext>(null);
export const useRolePlayInactivityDialogContext = () => useContext(context);

interface RolePlayInactivityDialogProps {
  children?: React.ReactNode;
}

const RolePlayInactivityDialog: React.FC<RolePlayInactivityDialogProps> = ({ children }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const snackbar = useTHQSnackbar();

  const [open, setOpen] = useState<boolean>(false);
  const [inactivityPeriodInMinutes, setInactivityPeriodInMinutes] = useState<number>();
  const [moveOutOfPracticeMode, setMoveOutOfPracticeMode] = useState<() => void>();
  const [timeTillExpire, setTimeTillExpire] = useState<number>(60);

  const handleOnClose = useCallback(() => {
    setOpen(false);
    setTimeTillExpire(60);
  }, []);

  useEffect(() => {
    const subs = new Subscription();
    if (open) {
      subs.add(
        timer(0, 1000).subscribe(() => {
          setTimeTillExpire((prevState) => prevState - 1);
        })
      );
    }
    return () => {
      subs.unsubscribe();
    };
  }, [open]);

  useEffect(() => {
    if (timeTillExpire === 0) {
      handleOnClose();
      moveOutOfPracticeMode();
      snackbar(t('you_were_timed_out'));
    }
  }, [handleOnClose, moveOutOfPracticeMode, navigate, snackbar, timeTillExpire]);

  const contextValue = useCallback((inactivityPeriodInMinutes: number, moveOutOfPracticeMode: () => void) => {
    setOpen(true);
    setInactivityPeriodInMinutes(inactivityPeriodInMinutes);
    setMoveOutOfPracticeMode(() => moveOutOfPracticeMode);
  }, []);

  return (
    <context.Provider value={contextValue}>
      {children}
      <THQDialog fullWidth open={open} onClose={handleOnClose}>
        <THQDialogTitle>{t('course_inactivity_title')}</THQDialogTitle>
        <THQDialogContent>
          <Typography>
            {t('rp_inactivity_message_1')} {inactivityPeriodInMinutes}{' '}
            {inactivityPeriodInMinutes === 1 ? t('datetime.minute') : t('datetime.minutes')}.{' '}
            {t('rp_inactivity_message_2')}
          </Typography>
          <Typography>
            {t('course_inactivity_expire')}{' '}
            <Typography component="span" fontWeight={700} sx={{ marginTop: '16px' }}>
              {timeTillExpire} {timeTillExpire === 1 ? t('datetime.second') : t('datetime.seconds')}
            </Typography>
            .
          </Typography>
        </THQDialogContent>
        <THQDialogActions>
          <Grid container justifyContent="end" spacing={1}>
            <Grid item sm="auto" xs={12}>
              <THQDialogActionButton variant="main" onClick={handleOnClose}>
                {t('yes_i_am_here')}
              </THQDialogActionButton>
            </Grid>
          </Grid>
        </THQDialogActions>
      </THQDialog>
    </context.Provider>
  );
};

export default RolePlayInactivityDialog;
