import React from 'react';

import { XButtonStyled, XIconStyled } from '@/roleplay/naturalRolePlay/common/xButton/styles';

interface XButtonProps {
  onClick(): void;
}

const XButton: React.FC<XButtonProps> = ({ onClick }) => {
  return (
    <XButtonStyled onClick={onClick}>
      <XIconStyled />
    </XButtonStyled>
  );
};

export default XButton;
