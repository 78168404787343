import DMSansFont from '@/assets/fonts/DMSans-VariableFont.ttf';
import MulishFont from '@/assets/fonts/Mulish.ttf';

export const font = (realmFont?: string, fontFamily?: string) => {
  return `@font-face {
  font-family: '${fontFamily || 'DM Sans'}';
  font-style: normal;
  font-display: swap;
  font-weight: 100 900;
  src: url(${realmFont || DMSansFont}) format('truetype');
}`;
};
