import { Inject, Injectable, Journey, Pageable, PageRequest } from '@trainhq/trainhq-client-core';
import { map, Observable } from 'rxjs';

import { JourneyRepository } from '@/services/repositoryData/journeyRepository';

export interface JourneyService {
  getJourney(uuid: string): Observable<Journey>;
  page(pageRequest: PageRequest): Observable<Pageable<Journey>>;
}

@Injectable('journeyService')
export class JourneyServiceImpl implements JourneyService {
  @Inject('journeyRepository')
  private journeyRepository: JourneyRepository;

  getJourney(uuid: string): Observable<Journey> {
    return this.journeyRepository.get(uuid, 'cloud').pipe(
      map((res) => ({
        ...res,
        journeyItems: res.journeyItems.sort((a, b) => a.itemOrder - b.itemOrder)
      }))
    );
  }

  page = (pageRequest: PageRequest): Observable<Pageable<Journey>> => {
    return this.journeyRepository.page(pageRequest);
  };
}
