import { Box, Typography, TypographyProps } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQCourseCard, THQSmallHeadline } from '@trainhq/trainhq-client-core';

export const TitleTypographyStyled = styled(Typography)(() => ({
  fontWeight: 700,
  fontSize: 18
}));

export const THQContentBuilderCardStyled = styled(THQCourseCard)(() => ({
  '&&': {
    minHeight: '272px'
  }
}));

export const StandaloneContainerStyled = styled(Box)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down('md')]: {
    paddingTop: '0',
    paddingLeft: 0,
    paddingRight: '0'
  }
}));

export const THQSmallHeadlineStyled = styled(THQSmallHeadline)(() => ({
  cursor: 'pointer',
  display: 'inline',
  '&:hover': {
    color: 'black',
    transition: 'color .2 ease-in'
  }
}));

export interface DescriptionStyledProps {
  asPlaceholder?: boolean;
}

export const DescriptionStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'asPlaceholder'
})<TypographyProps & DescriptionStyledProps>(({ theme, asPlaceholder }) => ({
  fontSize: '14px',
  whiteSpace: 'break-spaces',
  ...(asPlaceholder && { color: theme.palette.common.steel })
}));
