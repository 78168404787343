import React from 'react';

import { ChangeLanguageDialog, ChangePasswordDialog } from '@trainhq/trainhq-client-core';

interface LibraryActionsDialogsWrapperProps {
  children?: React.ReactNode;
}

const LibraryActionsDialogsWrapper: React.FC<LibraryActionsDialogsWrapperProps> = ({ children }) => {
  return (
    <ChangeLanguageDialog>
      <ChangePasswordDialog>{children}</ChangePasswordDialog>
    </ChangeLanguageDialog>
  );
};

export default LibraryActionsDialogsWrapper;
