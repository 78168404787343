import React, { ReactNode, useEffect, useState } from 'react';

import { I18nextProvider } from 'react-i18next';

import { useTranslationService } from '@/hooks/translations/useTranslationContext';
import initializeI18n from '@/services/i18n';

interface I18nInitializerProps {
  children: ReactNode;
}

export const I18nInitializer: React.FC<I18nInitializerProps> = ({ children }) => {
  const translationService = useTranslationService();
  const [i18nInstance, setI18nInstance] = useState<any>(null);

  useEffect(() => {
    if (!i18nInstance) {
      const i18n = initializeI18n(translationService);
      setI18nInstance(i18n);
    }
  }, [i18nInstance, translationService]);

  if (!i18nInstance) {
    return null;
  }

  return <I18nextProvider i18n={i18nInstance}>{children}</I18nextProvider>;
};

export default I18nInitializer;
