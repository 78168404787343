import {
  DataLoadType,
  GetAllRepository,
  GetAllWithCacheRepositoryImpl,
  GetRepository,
  GetWithCacheRepositoryImpl,
  Inject,
  Injectable,
  Pageable,
  PageRequest
} from '@trainhq/trainhq-client-core';
import { defer, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LearnerContentBuilder, LearnerCourse } from '@/models/learnerModels';
import { CourseCacheService } from '@/services/repositoryData/cache/courseCacheService';
import { CourseCloudService } from '@/services/repositoryData/cloud/courseCloudService';

export interface CourseRepository extends GetRepository<LearnerCourse, string>, GetAllRepository<LearnerCourse> {
  enrollToCourse(uuid): Observable<any>;
  getAllContent(cacheType: DataLoadType): Observable<LearnerContentBuilder[]>;
  getLpCourse(courseUuid: string, journeyUuid: string): Observable<LearnerCourse>;
  page(pageRequest: PageRequest): Observable<Pageable<LearnerCourse>>;
  setCurrentProgress(courseUuid: string, blockUuid: string, journeyUuid?: string): Observable<LearnerCourse>;
}

@Injectable('courseRepository')
export class CourseRepositoryImpl implements CourseRepository {
  constructor(
    private courseCloudService = new CourseCloudService(),
    private cacheService = new CourseCacheService(),
    private getRepository = new GetWithCacheRepositoryImpl(cacheService, courseCloudService.get),
    private getAllRepository = new GetAllWithCacheRepositoryImpl(cacheService, courseCloudService.all)
  ) {}

  enrollToCourse(uuid: string): Observable<any> {
    return this.courseCloudService.enrollToCourse(uuid);
  }

  get(uuid: string, cacheType: DataLoadType = 'cacheAndCloud'): Observable<LearnerCourse> {
    return this.getRepository.get(uuid, cacheType);
  }

  getAll(cacheType: DataLoadType = 'cacheAndCloud'): Observable<LearnerCourse[]> {
    return this.getAllRepository.getAll(cacheType);
  }

  getAllContent(cacheType: DataLoadType = 'cacheAndCloud'): Observable<LearnerContentBuilder[]> {
    return this.courseCloudService.getAllContent(cacheType);
  }

  getLpCourse(courseUuid: string, journeyUuid: string): Observable<LearnerCourse> {
    return this.courseCloudService.getLpCourse(courseUuid, journeyUuid);
  }

  page = (pageRequest: PageRequest): Observable<Pageable<LearnerCourse>> => {
    return this.courseCloudService.page(pageRequest);
  };

  setCurrentProgress(courseUuid: string, blockUuid: string, journeyUuid?: string): Observable<LearnerCourse> {
    return this.courseCloudService.setCourseProgress({ journeyUuid, courseBuilderUuid: courseUuid, blockUuid });
  }
}

@Injectable('coursePreviewRepository')
export class CoursePreviewRepositoryImpl implements CourseRepository {
  @Inject('courseRepository')
  private courseRepository: GetRepository<LearnerCourse, string>;
  private currentCourse: LearnerCourse;

  constructor(private courseCloudService = new CourseCloudService()) {}

  enrollToCourse(uuid: any): Observable<any> {
    throw new Error('Method not implemented.');
  }

  setCurrentProgress(courseUuid: string, blockUuid: string, journeyUuid?: string): Observable<LearnerCourse> {
    return defer(() => {
      const sectionToSetAsCurrent = this.currentCourse.sections.find((section) =>
        section.blocks.find((block) => block.uuid === blockUuid)
      );
      this.currentCourse = {
        ...this.currentCourse,
        currentSection: sectionToSetAsCurrent,
        currentBlock: sectionToSetAsCurrent?.blocks.find((block) => block.uuid === blockUuid)
      };
      return of(this.currentCourse);
    });
  }

  getLpCourse(courseUuid: string, journeyUuid: string): Observable<LearnerCourse> {
    throw new Error('Method not supported!');
  }

  get(uuid: string): Observable<LearnerCourse> {
    return this.courseCloudService.preview(uuid).pipe(
      map((course: LearnerCourse) => {
        return {
          ...course,
          currentBlock: undefined,
          currentSection: undefined
          // }))
        };
      }),
      tap({
        next: (course: LearnerCourse) => {
          this.currentCourse = course;
        }
      })
    );
  }

  getAllContent(cacheType: DataLoadType = 'cacheAndCloud'): Observable<LearnerContentBuilder[]> {
    return this.courseCloudService.getAllContent(cacheType);
  }

  getAll(cacheType: DataLoadType = 'cacheAndCloud'): Observable<LearnerCourse[]> {
    return null;
  }

  page = (pageRequest: PageRequest): Observable<Pageable<LearnerCourse>> => {
    return null;
  };
}
