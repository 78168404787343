import React from 'react';

import { FeatureFlag, FeaturePermission } from '@trainhq/trainhq-client-core';

import JourneyListLayout from '@/components/journey/list/JourneyListLayout';
import NotFoundPage from '@/pages/notFound/NotFoundPage';

const JourneyListPage: React.FC = () => {
  return (
    <FeatureFlag featurePermissions={[FeaturePermission.JOURNEY]} render={<NotFoundPage />}>
      <JourneyListLayout />
    </FeatureFlag>
  );
};

export default JourneyListPage;
