import React, { useMemo } from 'react';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { THQAppBar, THQMainPageContainer } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

import Home from '@/components/home/Home';
import useSetAppBar from '@/layout/useSetAppBar';

const HomePage: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const appBar = useMemo(() => (isSmallScreen ? <THQAppBar text={t('home')} /> : null), [isSmallScreen]);

  useSetAppBar(appBar);

  return (
    <THQMainPageContainer>
      <Home />
    </THQMainPageContainer>
  );
};

export default HomePage;
