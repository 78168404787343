import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid, List } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  ArrowDown,
  Bell,
  Bookmark,
  FeatureFlag,
  FeaturePermission,
  Home,
  InsightsIcon,
  LibMenuItemIcon,
  RoleplayIcon,
  SidebarListItem,
  SideBarMain,
  THQAvatar,
  THQBody,
  THQDropdown,
  useChangeLanguageDialog,
  useChangePasswordDialog,
  useFeaturesEnabled,
  useTHQSnackbar
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { finalize, Subscription } from 'rxjs';

import { ReactComponent as CallsIcon } from '@/assets/icons/headset.svg';
import { ReactComponent as Journey } from '@/assets/icons/journeys.svg';
import { ReactComponent as Logout } from '@/assets/icons/logout.svg';
import {
  CurrentUserContainer,
  LanguageIconStyled,
  ListGridStyled,
  LockIconStyled,
  LogoGridStyled,
  LogoutGridContainerStyled,
  SidebarContentRootGridStyled
} from '@/components/common/sidebar/styles';
import ThemeLogo from '@/components/common/themeLogo/ThemeLogo';
import { CALLS, COURSES, FEEDBACK, HOME, INSIGHTS, JOURNEYS, LIBRARY, ROLEPLAYS } from '@/constants/router';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useFeedbackService } from '@/hooks/feedback/useCourseService';
import { useTranslationService } from '@/hooks/translations/useTranslationContext';
import { useGetPasswordPolicy, useUserManagementService } from '@/hooks/userManagement/useUserManagementService';
import { getRealmConfig } from '@/utils/urlUtils';

const pollRefreshInterval = 30000;

// TODO: extract journey icon to core
// TODO: refactor styling of the sidebar list items (move to styles.ts and use styled common)
const DashboardSidebar: React.FC = () => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const theme = useTheme();
  const user = useAuthenticatedUserContext();
  const changePasswordDialog = useChangePasswordDialog();
  const changeLanguageDialog = useChangeLanguageDialog();

  const snackbar = useTHQSnackbar();

  const feedbackService = useFeedbackService();
  const userService = useUserManagementService();
  const translationService = useTranslationService();
  const courseFeatureEnabled = useFeaturesEnabled([FeaturePermission.COURSE]);
  const passPolicy = useGetPasswordPolicy();

  const [pendingCount, setPendingCount] = useState<number>();

  const [availableLanguages, setAvailableLanguages] = useState<{ label: string; value: string }[]>();

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const intervalID = setInterval(() => {
      feedbackService.refreshFeedbackItemsCountSummary('cloud');
    }, pollRefreshInterval);
    return () => {
      clearInterval(intervalID);
    };
  }, [feedbackService]);

  useEffect(() => {
    const subs = new Subscription();

    if (courseFeatureEnabled) {
      subs.add(
        feedbackService.observeFeedbackSummary().subscribe({
          next: (summary) => {
            setPendingCount(summary.pendingItems > 0 ? summary.pendingItems : undefined);
          }
        })
      );
      feedbackService.refreshFeedbackItemsCountSummary();
    }

    return () => {
      subs.unsubscribe();
    };
  }, [courseFeatureEnabled, feedbackService]);

  const handleGoHome = useCallback(() => {
    navigate(HOME);
  }, [navigate]);

  const handleGoToFeedback = useCallback(() => {
    navigate(FEEDBACK);
  }, [navigate]);

  const handleGoToCourses = useCallback(() => {
    navigate(COURSES);
  }, [navigate]);

  const handleGoToCalls = useCallback(() => {
    navigate(CALLS);
  }, [navigate]);

  const handleGoToJourneys = useCallback(() => {
    navigate(JOURNEYS);
  }, [navigate]);

  const handleGoToRoleplays = useCallback(() => {
    navigate(ROLEPLAYS);
  }, [navigate]);

  const handleGoToInsights = useCallback(() => {
    navigate(INSIGHTS);
  }, [navigate]);

  const handleGoToLibrary = useCallback(() => {
    navigate(LIBRARY);
  }, [navigate]);

  const handleLogout = useCallback(() => {
    userService.logout().subscribe();
  }, [userService]);

  const realmConfig = useMemo(() => {
    return getRealmConfig();
  }, []);

  const handleOnChangePassword = useCallback(
    (oldPassword: string, newPassword: string, callback: () => void) => {
      userService
        .changePassword(oldPassword, newPassword)
        .pipe(
          finalize(() => {
            callback();
          })
        )
        .subscribe({
          next: () => {
            handleLogout();
          }
        });
    },
    [userService, handleLogout]
  );

  useEffect(() => {
    const subs = new Subscription();

    subs.add(
      translationService.getAvailableLanguages().subscribe({
        next: (availableLanguages) => {
          setAvailableLanguages(availableLanguages);
        }
      })
    );

    return () => {
      subs.unsubscribe();
    };
  }, []);

  const handleOnChangeLanguage = useCallback(
    (selectedLanguage: string, successCallback: () => void, errorCallback: () => void) => {
      const currentLanguageCode = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language;
      selectedLanguage =
        selectedLanguage && selectedLanguage.includes('-') ? selectedLanguage.split('-')[0] : selectedLanguage;

      if (selectedLanguage && selectedLanguage !== '' && i18n && selectedLanguage !== currentLanguageCode) {
        i18n
          .changeLanguage(selectedLanguage)
          .then(() => successCallback())
          .catch((error) => {
            snackbar(error.displayErrorMessage);
            errorCallback();
          });
      } else {
        successCallback();
      }
    },
    [snackbar, i18n]
  );

  const handleOpenChangePasswordDialog = useCallback(() => {
    changePasswordDialog(passPolicy, true, handleOnChangePassword);
  }, [changePasswordDialog, handleOnChangePassword, passPolicy]);

  const handleOpenChangeLanguageDialog = useCallback(() => {
    const currentLanguageCode = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language;
    changeLanguageDialog(currentLanguageCode, availableLanguages, true, handleOnChangeLanguage);
  }, [availableLanguages, changeLanguageDialog, handleOnChangeLanguage, i18n.language]);

  const dropDownItems = useMemo(() => {
    const items = [
      { text: t('change_password'), action: handleOpenChangePasswordDialog, icon: <LockIconStyled /> },
      { text: t('change_language'), action: handleOpenChangeLanguageDialog, icon: <LanguageIconStyled /> }
    ];
    if (!realmConfig.idpHint) {
      items.push({ text: t('logout'), action: handleLogout, icon: <Logout /> });
    }
    return items;
  }, [handleLogout, handleOpenChangeLanguageDialog, handleOpenChangePasswordDialog, realmConfig.idpHint, t]);

  useEffect(() => {
    const urlLanguage = searchParams.get('lang');
    const currentLanguageCode = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language;

    if (urlLanguage && urlLanguage !== currentLanguageCode) {
      i18n.changeLanguage(urlLanguage).catch((error) => {
        snackbar(error.displayErrorMessage);
      });
    }
  }, [searchParams, i18n, snackbar]);

  return (
    <SideBarMain
      contentFixed={true}
      expanded={true}
      content={
        <SidebarContentRootGridStyled container direction="column">
          <LogoGridStyled item>
            <Grid sx={{ marginLeft: '8px' }} alignItems="center" container spacing={1}>
              <Grid item>
                <ThemeLogo />
              </Grid>
            </Grid>
          </LogoGridStyled>
          <ListGridStyled item>
            <List component="nav">
              <SidebarListItem
                icon={
                  <Home
                    style={{
                      color: pathname === HOME ? theme.palette.primary.main : theme.palette.common.steel
                    }}
                  />
                }
                isSelected={pathname === HOME}
                title={t('home')}
                onClick={handleGoHome}
              />
              <FeatureFlag featurePermissions={[FeaturePermission.COURSE]}>
                <SidebarListItem
                  icon={
                    <Bell
                      style={{
                        color:
                          pathname === FEEDBACK || pathname.includes('feedback')
                            ? theme.palette.primary.main
                            : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname === FEEDBACK || pathname.includes('feedback')}
                  title={t('feedback')}
                  count={pendingCount}
                  onClick={handleGoToFeedback}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.COURSE]}>
                <SidebarListItem
                  icon={
                    <Bookmark
                      style={{
                        color: pathname.includes(COURSES) ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname.includes(COURSES)}
                  title={t('courses')}
                  onClick={handleGoToCourses}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.CALL_INTELLIGENCE]}>
                <SidebarListItem
                  icon={
                    <CallsIcon
                      style={{
                        width: '16px',
                        height: '16px',
                        color: pathname === CALLS ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname === CALLS}
                  title={t('calls')}
                  onClick={handleGoToCalls}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.JOURNEY]}>
                <SidebarListItem
                  icon={
                    <Journey
                      style={{
                        color: pathname === JOURNEYS ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname === JOURNEYS}
                  title={t('journeys')}
                  onClick={handleGoToJourneys}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.ROLE_PLAY]}>
                <SidebarListItem
                  icon={
                    <RoleplayIcon
                      style={{
                        color: pathname.includes(ROLEPLAYS) ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname.includes(ROLEPLAYS)}
                  title={t('roleplays')}
                  onClick={handleGoToRoleplays}
                />
              </FeatureFlag>
              <FeatureFlag featurePermissions={[FeaturePermission.ROLE_PLAY]}>
                <SidebarListItem
                  icon={
                    <InsightsIcon
                      style={{
                        color: pathname.includes(INSIGHTS) ? theme.palette.primary.main : theme.palette.common.steel
                      }}
                    />
                  }
                  isSelected={pathname.includes(INSIGHTS)}
                  title={t('insights')}
                  onClick={handleGoToInsights}
                />
              </FeatureFlag>
              <SidebarListItem
                icon={
                  <LibMenuItemIcon
                    style={{
                      color: pathname === LIBRARY ? theme.palette.primary.main : theme.palette.common.steel
                    }}
                  />
                }
                isSelected={pathname === LIBRARY}
                title={t('library')}
                onClick={handleGoToLibrary}
              />
            </List>
          </ListGridStyled>
          <Grid item>
            <LogoutGridContainerStyled container alignItems="center" direction="row" spacing={1}>
              <CurrentUserContainer>
                <THQAvatar src={user?.profilePicUrl} colorRandomizeString={`${user?.firstName + user?.lastName}`} />
                <div>
                  <THQBody
                    sx={{
                      color: theme.palette.common.midnight,
                      maxWidth: '135px',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {user?.firstName} {user?.lastName}
                  </THQBody>
                  <THQBody
                    sx={{
                      color: theme.palette.common.steel,
                      fontSize: '12px',
                      overflow: 'hidden',
                      maxWidth: '135px',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap'
                    }}
                  >
                    {user?.email}
                  </THQBody>
                </div>
                <div>
                  <THQDropdown
                    button={{
                      sx: { border: 'none', minWidth: '24px', minHeight: '24px', padding: 0 },
                      icon: <ArrowDown style={{ color: theme.palette.common.steel }} />
                    }}
                    items={dropDownItems}
                  />
                </div>
              </CurrentUserContainer>
            </LogoutGridContainerStyled>
          </Grid>
        </SidebarContentRootGridStyled>
      }
    />
  );
};

export default DashboardSidebar;
