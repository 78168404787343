import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import { ROOT_URL } from '@/hooks/config/useAppConfig';

i18n
  .use(HttpBackend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    load: 'languageOnly',
    debug: false,
    backend: {
      loadPath: `${ROOT_URL}/api/translations/public/{{lng}}`
    },
    interpolation: {
      escapeValue: false
    }
  });

export default i18n;
