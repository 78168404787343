import React, { useEffect, useRef, useState } from 'react';

import { Box, CircularProgress, Dialog } from '@mui/material';
import { THQBody } from '@trainhq/trainhq-client-core';

interface BlockTypePickStepProps {
  loading?: boolean;
  loadingText?: string;
  setLoadingText: (x: string) => void;
}

const SavingOverlay: React.FC<BlockTypePickStepProps> = React.memo(({ loading, loadingText, setLoadingText }) => {
  const creationTime = useRef<Date>(null);
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    const timeElapsed = +new Date() - +creationTime.current;
    if (!loading && creationTime.current) {
      if (timeElapsed < 1000) {
        setTimeout(() => {
          setLoadingText('');
          setVisible(false);
        }, 1000 - timeElapsed);
      } else {
        setLoadingText('');
        setVisible(false);
      }
    } else if (loading) {
      creationTime.current = new Date();
      setVisible(true);
    }
  }, [loading, setLoadingText]);

  return (
    <Dialog sx={{ justifyContent: 'center', alignItems: 'center' }} open={visible} fullScreen>
      <Box
        sx={{
          margin: 'auto',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          gap: '10px'
        }}
      >
        <CircularProgress color="success" />
        <THQBody>{loadingText}</THQBody>
      </Box>
    </Dialog>
  );
});

export default SavingOverlay;
