import {
  DataLoadType,
  GetRepository,
  GetWithCacheRepositoryImpl,
  Inject,
  Injectable,
  QuizFeedbackStatus
} from '@trainhq/trainhq-client-core';
import { Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';

import { LearnerBlock } from '@/models/learnerModels';
import { BlockCacheService } from '@/services/repositoryData/cache/blockCacheService';
import { BlockCloudService } from '@/services/repositoryData/cloud/blockCloudService';

export interface BlockRepository extends GetRepository<LearnerBlock, string> {
  getLpBlock(uuid: string, journeyUuid: string, cacheType?: DataLoadType): Observable<LearnerBlock>;
}

@Injectable('blockRepository')
export class BlockRepositoryImpl implements BlockRepository {
  constructor(
    private blockCloudService = new BlockCloudService(),
    private cacheService = new BlockCacheService(),
    private getRepository = new GetWithCacheRepositoryImpl(cacheService, blockCloudService.get)
  ) {}

  get(uuid: string, cacheType: DataLoadType = 'cacheAndCloud'): Observable<LearnerBlock> {
    return this.getRepository.get(uuid, cacheType);
  }

  getLpBlock(uuid: string, journeyUuid: string, cacheType: DataLoadType = 'cloud'): Observable<LearnerBlock> {
    return this.blockCloudService.getLpBlock(uuid, journeyUuid);
  }
}

@Injectable('previewBlockRepository')
export class PreviewBlockRepositoryImpl implements BlockRepository {
  private BlocksMap = new Map<string, LearnerBlock>();
  @Inject('blockRepository')
  private blockRepository: BlockRepository;

  get(uuid: string, cacheType: DataLoadType = 'cacheAndCloud'): Observable<LearnerBlock> {
    if (this.BlocksMap.has(uuid)) return of(this.BlocksMap.get(uuid));
    return this.blockRepository.get(uuid, cacheType).pipe(
      map((block: LearnerBlock) => {
        return { ...block, quizFeedbackStatus: 'FEEDBACK_REQUIRED' as QuizFeedbackStatus };
      }),
      tap((block: LearnerBlock) => {
        this.BlocksMap.set(block.uuid, block);
      })
    );
  }

  getLpBlock(uuid: string, journeyUuid: string, cacheType?: DataLoadType): Observable<LearnerBlock> {
    throw new Error('Method not supported!');
  }
}
