import React, { useCallback, useState } from 'react';

import { Grid } from '@mui/material';
import { RoleplayConfiguration, RoleplayUserSummary, THQPrimaryButton } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { Observable } from 'rxjs';

import { RPRunSummaryRootGrid, RPSummaryBodyTypography, RPSummaryHeadlineTypography } from './styles';
import { ReactComponent as Help } from '@/assets/icons/help.svg';
import { ReactComponent as Restart } from '@/assets/icons/undo.svg';
import Checkmark from '@/assets/images/checkbox.png';
import Confetti from '@/assets/images/confetti.png';
import Pencil from '@/assets/images/rp_fail_complete_pencil.png';
import WatchModeDone from '@/assets/images/watchModeDone.png';

interface RPRunSummaryProps {
  chatStatus: string;
  visible?: boolean;
  onMainClick?: (x?: boolean) => void;
  onRetryLast?: (errorPresent?: boolean) => void;
  onSecondaryClick?: () => void;
  onRecover?: () => void;
  actualMessage?: string;
  preferredMessage?: string;
  showHint?: boolean;
  logHintEvent?: () => Observable<any>;
  failPresent?: boolean;
  errorInCommunication?: boolean;
  natural?: boolean;
  roleplayConfig: RoleplayConfiguration;
  roleplaySummary: RoleplayUserSummary;
}

export const RPRunSummary: React.FC<RPRunSummaryProps> = ({
  chatStatus,
  visible = false,
  onMainClick,
  onSecondaryClick,
  actualMessage,
  preferredMessage,
  showHint,
  failPresent,
  onRetryLast,
  onRecover,
  natural,
  logHintEvent,
  errorInCommunication,
  roleplayConfig,
  roleplaySummary
}) => {
  const { t } = useTranslation();
  const [hintEnabled, setHintEnabled] = useState(false);
  const toggleHintEnabled = () => {
    logHintEvent().subscribe(() => setHintEnabled(!hintEnabled));
  };

  const handleOnMainClick = useCallback(() => {
    setHintEnabled(false);
    onMainClick(true);
  }, [onMainClick]);

  const handleOnRetryLast = useCallback(() => {
    setHintEnabled(false);
    onRetryLast(errorInCommunication);
  }, [errorInCommunication, onRetryLast]);

  const handleOnSecondaryClick = useCallback(() => {
    setHintEnabled(false);
    onSecondaryClick();
  }, [onSecondaryClick]);

  const renderHeadline = () => {
    if (failPresent) {
      return 'Good try!';
    }
    switch (true) {
      case roleplayConfig.passRequirement > 0 && roleplayConfig.passRequirement === roleplaySummary.finishedInARow:
        return 'Nicely done!';
      case !roleplayConfig.passRequirement ||
        roleplayConfig.passRequirement === 1 ||
        roleplayConfig.passRequirement === 0:
        return 'Nicely done!';
      case roleplayConfig.passRequirement && roleplayConfig.passRequirement > roleplaySummary.finishedInARow:
        return 'Nice!';
      default:
        return 'Nicely done!';
    }
  };

  const renderSuccessText = () => {
    if (failPresent) {
      // you made a mistake somewhere down the script
      return (
        <>
          {t('common.rp_summary_need_complete')}{' '}
          <b>
            {roleplayConfig.passRequirement}{' '}
            {roleplayConfig.passRequirement === 1 ? t('datetime.time') : t('datetime.times')}
          </b>{' '}
          {t('common.rp_summary_without_error')}
          <br />
          {t('common.rp_summary_try_again')}
        </>
      );
    }
    switch (true) {
      // fully completed
      case roleplaySummary.completed ||
        (roleplayConfig.passRequirement > 0 && roleplayConfig.passRequirement === roleplaySummary.finishedInARow):
        return (
          <>
            {t('common.rp_summary_congrats')} <br />
            {t('common.rp_summary_congrats_practice')}
          </>
        );
      // has no pass requirement -> just need to do it once
      case !roleplayConfig.passRequirement ||
        roleplayConfig.passRequirement === 1 ||
        roleplayConfig.passRequirement === 0:
        return (
          <>
            {t('common.rp_summary_congrats')} <br />
            {t('common.rp_summary_congrats_practice')}
          </>
        );
      // not yet fulfilled the pass requirement
      case roleplayConfig.passRequirement && roleplayConfig.passRequirement > roleplaySummary.finishedInARow:
        return (
          <>
            {t('common.rp_summary_completed')}{' '}
            <b>
              {roleplaySummary.finishedInARow}{' '}
              {roleplaySummary.finishedInARow === 1 ? t('datetime.time') : t('datetime.times')}
            </b>{' '}
            {t('common.rp_summary_without_error')} <br /> {t('common.rp_summary_need')}{' '}
            <b>
              {roleplayConfig.passRequirement - roleplaySummary.finishedInARow} {t('common.rp_summary_more')}
            </b>{' '}
            {t('common.rp_summary_more_in_a_row')}
          </>
        );
      default:
        // practice mode
        return (
          <>
            {t('common.rp_summary_congrats')} <br /> {t('common.rp_summary_congrats_practice')}
          </>
        );
    }
  };

  const getHeroImage = () => {
    if (chatStatus === 'watchModeDone') {
      return WatchModeDone;
    }
    if (failPresent) {
      return Pencil;
    }
    if (roleplaySummary.completed) {
      return Confetti;
    }
    return roleplayConfig.passRequirement > 0 && roleplayConfig.passRequirement <= roleplaySummary.finishedInARow
      ? Confetti
      : Checkmark;
  };

  const renderError = () => {
    return (
      <RPRunSummaryRootGrid visible={visible} container>
        <Grid sx={{ marginBottom: '10px' }} item xs={12}>
          <RPSummaryHeadlineTypography>{t('rp_summary_error_in_communication')}</RPSummaryHeadlineTypography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            overflow: 'hidden',
            maxHeight: '600px',
            opacity: 1,
            transition: 'all .6s ease-in-out'
          }}
        >
          {!natural && <RPSummaryBodyTypography>{t('please_try_again')}</RPSummaryBodyTypography>}
          {natural && (
            <RPSummaryBodyTypography>
              {t('rp_summary_resending_1')}
              <br /> {t('rp_summary_resending_2')}
            </RPSummaryBodyTypography>
          )}
          {/* <RPSummaryBodyTypography sx={{ fontStyle: 'italic' }}>“{preferredMessage?.trim()}”</RPSummaryBodyTypography> */}
        </Grid>
        {chatStatus && (
          <>
            {!natural && (
              <>
                <Grid item xs={12} sx={{ marginTop: '8px' }}>
                  <Grid container justifyContent={'center'} gap={'12px'}>
                    <Grid item xs={'auto'}>
                      <THQPrimaryButton onClick={handleOnRetryLast} variant="main">
                        {t('try_again')}
                      </THQPrimaryButton>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
            {natural && (
              <>
                <Grid container justifyContent={'center'} gap={'12px'}>
                  <Grid item sx={{ marginTop: '8px' }}>
                    <Grid container justifyContent={'center'} gap={'12px'}>
                      <Grid item xs={'auto'}>
                        <THQPrimaryButton onClick={onRecover} variant="main">
                          {t('resend')}
                        </THQPrimaryButton>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item sx={{ marginTop: '8px' }}>
                    <Grid container justifyContent={'center'} gap={'12px'}>
                      <Grid item xs={'auto'}>
                        <THQPrimaryButton onClick={handleOnRetryLast}>{t('try_again')}</THQPrimaryButton>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </>
        )}
      </RPRunSummaryRootGrid>
    );
  };

  const renderSuccess = () => {
    return (
      <RPRunSummaryRootGrid visible={visible} container>
        <Grid display={'flex'} justifyContent="center" sx={{ marginBottom: '24px' }} item xs={12}>
          <img alt="confetti" src={getHeroImage()} width={120} height={120} />
        </Grid>
        <Grid sx={{ marginBottom: '10px' }} item xs={12}>
          {chatStatus === 'success' && <RPSummaryHeadlineTypography>{renderHeadline()}</RPSummaryHeadlineTypography>}
        </Grid>
        <Grid item xs={12}>
          {chatStatus === 'success' && <RPSummaryBodyTypography>{renderSuccessText()}</RPSummaryBodyTypography>}
        </Grid>
        {chatStatus && (
          <Grid item xs={12} sx={{ marginTop: '8px' }}>
            <Grid container justifyContent={'center'} gap={'12px'}>
              <Grid item xs={'auto'}>
                <THQPrimaryButton onClick={handleOnMainClick} variant="main">
                  {t('start_again')}
                </THQPrimaryButton>
              </Grid>
              {chatStatus === 'success' && (
                <Grid item xs={'auto'}>
                  <THQPrimaryButton onClick={handleOnSecondaryClick}>{t('exit_rp')}</THQPrimaryButton>
                </Grid>
              )}
            </Grid>
          </Grid>
        )}
      </RPRunSummaryRootGrid>
    );
  };

  const renderFailureDefault = () => {
    return (
      <RPRunSummaryRootGrid visible={visible} container>
        <Grid
          sx={{
            overflow: 'hidden',
            maxHeight: !hintEnabled ? '600px' : 0,
            opacity: !hintEnabled ? 1 : 0,
            transition: 'all .6s ease-in-out',
            marginBottom: '10px'
          }}
          item
          xs={12}
        >
          <RPSummaryBodyTypography>{t('rp_summary_almost')}</RPSummaryBodyTypography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{
            overflow: 'hidden',
            maxHeight: hintEnabled ? '600px' : 0,
            opacity: hintEnabled ? 1 : 0,
            transition: 'all .6s ease-in-out'
          }}
        >
          <RPSummaryBodyTypography>{t('rp_summary_say_instead')}</RPSummaryBodyTypography>
          <RPSummaryBodyTypography
            sx={(theme) => ({
              fontSize: '16px',
              padding: '12px 16px',
              backgroundColor: theme.palette.common.pearl,
              border: `1px solid ${theme.palette.common.paleSilver}`,
              borderRadius: '12px'
            })}
          >
            {preferredMessage?.trim()}
          </RPSummaryBodyTypography>
        </Grid>
        {chatStatus && (
          <Grid item xs={12} sx={{ marginTop: '8px' }}>
            <Grid container justifyContent={'center'} gap={'12px'}>
              <Grid item xs={'auto'}>
                <THQPrimaryButton onClick={handleOnRetryLast} variant="main">
                  {t('try_again')}
                </THQPrimaryButton>
              </Grid>
              <Grid
                sx={{
                  overflow: 'hidden',
                  '&&': { opacity: hintEnabled ? 0 : 1, maxWidth: hintEnabled ? '0px' : '150px' },
                  transition: 'max-width .3s ease-in, opacity .2s ease-in'
                }}
                item
                xs={'auto'}
              >
                <THQPrimaryButton
                  disabled={hintEnabled}
                  sx={{ opacity: hintEnabled ? 0 : '1', transition: 'opacity .2s ease-in' }}
                  onClick={toggleHintEnabled}
                >
                  <Help style={{ height: '16px', width: '16px', marginRight: '8px' }} /> {t('help_me_out')}
                </THQPrimaryButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </RPRunSummaryRootGrid>
    );
  };

  const renderFailureFirst = () => {
    return (
      <RPRunSummaryRootGrid visible={visible} container>
        <Grid sx={{ marginBottom: '24px' }} item xs={12}>
          {chatStatus === 'failure' && (
            <RPSummaryHeadlineTypography>{t('rp_summary_bob_ross')}</RPSummaryHeadlineTypography>
          )}
        </Grid>
        <Grid item xs={12}>
          <RPSummaryBodyTypography>{t('rp_summary_could_better')}</RPSummaryBodyTypography>
        </Grid>

        {chatStatus && (
          <Grid item xs={12} sx={{ marginTop: '8px' }}>
            <Grid container justifyContent={'center'} gap={'12px'}>
              <Grid item xs={'auto'}>
                <THQPrimaryButton onClick={handleOnRetryLast} variant="main">
                  {t('try_again')}
                </THQPrimaryButton>
              </Grid>
              {/* <Grid item xs={'auto'}>
                <THQPrimaryButton
                  disabled={hintEnabled}
                  sx={{ opacity: hintEnabled ? 0 : '1', transition: 'opacity .2s ease-in' }}
                  onClick={handleOnMainClick}
                >
                  Start from the top
                </THQPrimaryButton>
              </Grid> */}
            </Grid>
          </Grid>
        )}
      </RPRunSummaryRootGrid>
    );
  };

  const renderWatchModeDone = () => {
    return (
      <RPRunSummaryRootGrid visible={visible} container>
        <Grid display={'flex'} justifyContent="center" sx={{ marginBottom: '24px' }} item xs={12}>
          <img alt="confetti" src={getHeroImage()} width={168} height={195} />
        </Grid>
        <Grid sx={{ marginBottom: '10px' }} item xs={12}>
          <RPSummaryHeadlineTypography>{t('rp_summary_made_it')}</RPSummaryHeadlineTypography>
        </Grid>
        <Grid item xs={12}>
          <RPSummaryBodyTypography>{t('rp_summary_ready_next')}</RPSummaryBodyTypography>
        </Grid>
        {chatStatus && (
          <Grid item xs={12} sx={{ marginTop: '8px' }}>
            <Grid container justifyContent={'center'} gap={'12px'}>
              <Grid item xs={'auto'}>
                <THQPrimaryButton onClick={handleOnSecondaryClick}>
                  <Restart style={{ width: '14px', height: '14px', marginRight: '8px' }} />
                  {t('rp_summary_start_over')}
                </THQPrimaryButton>
              </Grid>
              <Grid item xs={'auto'}>
                <THQPrimaryButton onClick={handleOnMainClick} variant="main">
                  {t('rp_summary_i_ready')}
                </THQPrimaryButton>
              </Grid>
            </Grid>
          </Grid>
        )}
      </RPRunSummaryRootGrid>
    );
  };

  return (
    <>
      {errorInCommunication && renderError()}
      {!errorInCommunication && chatStatus === 'watchModeDone' && renderWatchModeDone()}
      {!errorInCommunication && chatStatus === 'success' && renderSuccess()}
      {!errorInCommunication && chatStatus === 'failure' && (
        <>{showHint ? renderFailureDefault() : renderFailureFirst()}</>
      )}
    </>
  );
};
