import { Box, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQChip } from '@trainhq/trainhq-client-core';

export const ItemTypeTextStyled = styled(Typography)(({ theme }) => ({
  color: theme.palette.common.silver,
  fontSize: 14
}));

interface ChipStyledProps {
  background: string;
  foreground: string;
}

export const ChipStyled = styled(THQChip, {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'background' && prop !== 'foreground'
})<ChipStyledProps>(({ background, foreground }) => ({
  backgroundColor: background,
  borderRadius: 6,
  display: 'flex',
  height: 22,
  marginTop: '2px',
  ['.MuiChip-label']: {
    color: foreground,
    fontWeight: 700
  }
}));

export const WarningBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.error.background,
  borderRadius: 12,
  padding: 16,
  '& > p': {
    color: theme.palette.error.foreground
  }
}));

export const InfoBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.info.background,
  borderRadius: 12,
  padding: 16,
  '& > p': {
    color: theme.palette.info.foreground
  }
}));

export const SuccessBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.success.background,
  borderRadius: 12,
  padding: 16,
  '& > p': {
    color: theme.palette.success.foreground
  }
}));
