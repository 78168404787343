import { styled } from '@mui/material/styles';

import { ReactComponent as InfoIcon } from '@/assets/icons/info-empty.svg';

export const InfoIconStyled = styled(InfoIcon)(({ theme }) => ({
  boxSizing: 'content-box',
  height: 18,
  width: 18,
  border: `8px solid ${theme.palette.common.pearl}`,
  marginRight: 5,
  strokeWidth: 2,
  backgroundColor: theme.palette.common.pearl,
  borderRadius: '50%',
  '&&&& path': {
    stroke: theme.palette.common.smoke
  }
}));
