import { Box, Divider, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQTabPanel } from '@trainhq/trainhq-client-core';

export const RoundedBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.zima,
  borderRadius: 16,
  height: '100%'
}));

export const TitleTextStyled = styled(Typography)(() => ({
  fontWeight: 700,
  padding: '16px 24px'
}));

export const TabPanelStyled = styled(THQTabPanel)(() => ({
  maxHeight: 'calc(100% - 150px)',
  overflow: 'auto',
  padding: '16px 24px'
}));

export const DividerBoxStyled = styled(Box)(() => ({
  display: 'flex',
  justifyContent: 'center',
  width: '100%'
}));

export const DividerStyled = styled(Divider)(({ theme }) => ({
  borderColor: theme.palette.common.pearl,
  marginTop: '8px',
  width: '100%'
}));
