import { useContext, useEffect, useState } from 'react';

import { PasswordPolicy, User } from '@trainhq/trainhq-client-core';
import { Subscription } from 'rxjs';

import { UserManagementServiceContext } from '@/context/userManagement';

export const useGetCurrentUser = (): User => {
  const userManagementService = useUserManagementService();
  const [user, setUser] = useState<User>();

  useEffect(() => {
    const subs = new Subscription();
    subs.add(
      userManagementService.getUser().subscribe({
        next: (res) => {
          setUser(res);
        }
      })
    );
    return () => {
      subs.unsubscribe();
    };
  }, [userManagementService]);

  return user;
};

export const useGetPasswordPolicy = (token?: string): PasswordPolicy => {
  const [passPolicy, setPassPolicy] = useState<PasswordPolicy>();
  const userManagementService = useUserManagementService();

  useEffect(() => {
    const subs = new Subscription();
    subs.add(
      userManagementService.getPasswordPolicy(token).subscribe({
        next: (policy) => {
          setPassPolicy(policy);
        },
        error: (err) => {}
      })
    );
  }, [userManagementService, token]);
  return passPolicy;
};

export const useUserManagementService = () => useContext(UserManagementServiceContext);
