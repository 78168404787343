import { Container, styled } from '@mui/material';

export interface CardContainerStyledProps {
  bgColor?: string;
}

export const CardContainerStyled = styled(Container, {
  shouldForwardProp: (prop) => prop !== 'bgColor'
})<CardContainerStyledProps>(({ theme, bgColor }) => ({
  position: 'relative',
  width: '100%',
  borderRadius: '12px',
  padding: '24px',
  border: `1px solid ${theme.palette.common.paleSilver}`,
  backgroundColor: bgColor ? bgColor : theme.palette.common.zima,
  opacity: 1,
  transition: 'all .2s linear'
}));
