import {
  ChallengeEventsCountStats,
  ChallengeScoreRate,
  ErrorsPerPairData,
  Injectable,
  InsightsUserStatus,
  NaturalDetailsData,
  NRPSection,
  PageRequest,
  ProgressStatus,
  RolePlayCategoryEventsCountData,
  RolePlayErrorsPerAttemptData,
  RolePlayLMSAnalyticsRequest
} from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { RolePlayAnalyticsCloudService } from '@/services/repositoryData/cloud/rolePlayAnalyticsCloudService';

export interface RolePlayAnalyticsRepository {
  eventsCountStatsStrict(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayCategoryEventsCountData[]>;
  eventsCountStatsNatural(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayCategoryEventsCountData[]>;
  errorsByAttemptsStrict(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayErrorsPerAttemptData>;
  errorsByAttemptsNatural(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayErrorsPerAttemptData>;
  errorsPerPair(request: RolePlayLMSAnalyticsRequest): Observable<ErrorsPerPairData>;
  exportLatestActivity(request: RolePlayLMSAnalyticsRequest): Observable<void>;
  exportLeaderboard(request: RolePlayLMSAnalyticsRequest): Observable<void>;
  exportLearners(pageRequest: PageRequest): Observable<void>;
  exportUserValidationReports(pageRequest: PageRequest): Observable<void>;
  getChallengesEventsCountNatural(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeEventsCountStats[]>;
  getChallengeScores(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeScoreRate[]>;
  getNaturalDetails(request: RolePlayLMSAnalyticsRequest): Observable<NaturalDetailsData>;
  getNaturalReport(uuid: string): Observable<any>;
  getNaturalSections(rpUuid: string): Observable<NRPSection[]>;
  getUserStatus(rolePlayUuid: string): Observable<InsightsUserStatus>;
  searchLogs(searchParams): Observable<any>;
  searchSubmittedNRPReports(searchParams: PageRequest): Observable<any>;
  addSelfAssessment(payload: {
    reportUuid: string;
    goals: { uuid: string; evaluation: ProgressStatus }[];
    objections: { uuid: string; evaluation: ProgressStatus }[];
  }): Observable<any>;
}

@Injectable('rolePlayAnalyticsRepository')
export class RolePlayAnalyticsRepositoryImpl implements RolePlayAnalyticsRepository {
  constructor(private rolePlayAnalyticsCloudService = new RolePlayAnalyticsCloudService()) {}

  eventsCountStatsStrict(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayCategoryEventsCountData[]> {
    return this.rolePlayAnalyticsCloudService.getCategoryEventsCountStatsStrict(request);
  }

  eventsCountStatsNatural(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayCategoryEventsCountData[]> {
    return this.rolePlayAnalyticsCloudService.getCategoryEventsCountNatural(request);
  }

  errorsByAttemptsStrict(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayErrorsPerAttemptData> {
    return this.rolePlayAnalyticsCloudService.getErrorsPerAttemptStatsStrict(request);
  }

  errorsByAttemptsNatural(request: RolePlayLMSAnalyticsRequest): Observable<RolePlayErrorsPerAttemptData> {
    return this.rolePlayAnalyticsCloudService.getErrorsPerAttemptStatsNatural(request);
  }

  errorsPerPair(request: RolePlayLMSAnalyticsRequest): Observable<ErrorsPerPairData> {
    return this.rolePlayAnalyticsCloudService.getErrorsPerPairStats(request);
  }

  exportLatestActivity(request: RolePlayLMSAnalyticsRequest): Observable<void> {
    return this.rolePlayAnalyticsCloudService.exportLatestActivity(request);
  }

  exportLeaderboard(request: RolePlayLMSAnalyticsRequest): Observable<void> {
    return this.rolePlayAnalyticsCloudService.exportLeaderboard(request);
  }

  exportLearners(pageRequest: PageRequest): Observable<void> {
    return this.rolePlayAnalyticsCloudService.exportLearners(pageRequest);
  }

  exportUserValidationReports(pageRequest: PageRequest): Observable<void> {
    return this.rolePlayAnalyticsCloudService.exportUserValidationReports(pageRequest);
  }

  getChallengesEventsCountNatural(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeEventsCountStats[]> {
    return this.rolePlayAnalyticsCloudService.getChallengesEventsCountNatural(request);
  }

  getChallengeScores(request: RolePlayLMSAnalyticsRequest): Observable<ChallengeScoreRate[]> {
    return this.rolePlayAnalyticsCloudService.getChallengeScores(request);
  }

  getNaturalDetails(request: RolePlayLMSAnalyticsRequest): Observable<NaturalDetailsData> {
    return this.rolePlayAnalyticsCloudService.getNaturalDetails(request);
  }

  getNaturalReport(uuid: string): Observable<any> {
    return this.rolePlayAnalyticsCloudService.getNaturalReport(uuid);
  }

  getNaturalSections(rpUuid: string): Observable<NRPSection[]> {
    return this.rolePlayAnalyticsCloudService.getNaturalSections(rpUuid);
  }

  getUserStatus = (rolePlayUuid: string): Observable<InsightsUserStatus> => {
    return this.rolePlayAnalyticsCloudService.getUserStatus(rolePlayUuid);
  };

  searchLogs(searchParams: any): Observable<any> {
    return this.rolePlayAnalyticsCloudService.searchLogs(searchParams);
  }

  searchSubmittedNRPReports = (searchParams: PageRequest): Observable<any> => {
    return this.rolePlayAnalyticsCloudService.searchSubmittedNRPReports(searchParams);
  };

  addSelfAssessment = (payload: {
    reportUuid: string;
    goals: { uuid: string; evaluation: ProgressStatus }[];
    objections: { uuid: string; evaluation: ProgressStatus }[];
  }): Observable<any> => {
    return this.rolePlayAnalyticsCloudService.addSelfAssessment(payload);
  };
}
