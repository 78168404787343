import React from 'react';

import { SMSCodeVerify } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';

import { CONFIRM_PASSWORD, RESET_PASSWORD_SMS } from '@/constants/router';
import { useUserManagementService } from '@/hooks/userManagement/useUserManagementService';

const SMSCodeVerification: React.FC = () => {
  const navigate = useNavigate();
  const userManagerService = useUserManagementService();

  const handleRequest = (code: string) => {
    return userManagerService.validateToken(code);
  };

  const handleGoBack = () => {
    navigate(RESET_PASSWORD_SMS);
  };

  // TODO: why 1.5sec timeout?
  const onSuccess = (token: string) => {
    setTimeout(() => {
      navigate(`${CONFIRM_PASSWORD}?token=${token}`);
    }, 1500);
  };

  return <SMSCodeVerify onSuccessCallback={onSuccess} onGoBack={handleGoBack} onSubmit={handleRequest} />;
};

export default SMSCodeVerification;
