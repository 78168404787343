import React from 'react';

import { CardContainerStyled } from '@/components/insights/role-play/common/cards/styles';

interface CardContainerProps {
  bgColor?: string;
  children?: React.ReactNode;
}

const CardContainer: React.FC<CardContainerProps> = ({ children, bgColor }) => {
  return <CardContainerStyled bgColor={bgColor}>{children}</CardContainerStyled>;
};

export default CardContainer;
