import { styled } from '@mui/material/styles';

export const HintDivStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.info.background,
  color: theme.palette.info.foreground,
  fontSize: '14px',
  borderRadius: 12,
  padding: 16
}));

export const WarningDivStyled = styled('div')(({ theme }) => ({
  backgroundColor: theme.palette.warning.background,
  color: theme.palette.warning.foreground,
  fontSize: '14px',
  borderRadius: 12,
  padding: 16
}));
