import { Injectable } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { TranslationCloudService } from '@/services/repositoryData/cloud/translationCloudService';

export interface TranslationService {
  getTranslations(locale?: string): Observable<any>;
  getAvailableLanguages(): Observable<any>;
}

@Injectable('translationService')
export class TranslationServiceImpl implements TranslationService {
  constructor(private translationCloudService = new TranslationCloudService()) {}

  getTranslations(locale: string): Observable<any> {
    return this.translationCloudService.getTranslations(locale);
  }

  getAvailableLanguages(): Observable<any> {
    return this.translationCloudService.getAvailableLanguages();
  }
}
