import React from 'react';

import { FeatureFlag, FeaturePermission } from '@trainhq/trainhq-client-core';

import JourneyLayout from '@/components/journey/JourneyLayout';
import NotFoundPage from '@/pages/notFound/NotFoundPage';

const JourneyPage: React.FC = () => {
  return (
    <FeatureFlag featurePermissions={[FeaturePermission.JOURNEY]} render={<NotFoundPage />}>
      <JourneyLayout />
    </FeatureFlag>
  );
};

export default JourneyPage;
