import {
  FeedbackMessage,
  Inject,
  LearnerFeedbackThread,
  NetworkService,
  Pageable,
  PageRequest
} from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import {
  ADD_LEARNER_FEEDBACK,
  ADD_MULTI_CHOICE_ANSWER,
  BLOCK_PENDING_FEEDBACK_THREAD,
  COURSE_PENDING_FEEDBACK_THREADS
} from '@/constants/api';

export class FeedbackCloudService {
  @Inject('networkService')
  protected networkService!: NetworkService;

  addMultiChoiceAnswer(chosenAnswer: { blockUuid: string; answerUuid: string; journeyUuid?: string }): Observable<any> {
    return this.networkService.post(`${ADD_MULTI_CHOICE_ANSWER}`, chosenAnswer);
  }

  addFeedback(feedback: { blockUuid: string; feedbackMessage: FeedbackMessage }): Observable<LearnerFeedbackThread> {
    return this.networkService.post(`${ADD_LEARNER_FEEDBACK}`, feedback);
  }

  getPendingThreads: (pageRequest: PageRequest) => Observable<Pageable<LearnerFeedbackThread>> = (
    pageRequest: PageRequest
  ) => {
    return this.networkService.post(`${COURSE_PENDING_FEEDBACK_THREADS}`, pageRequest);
  };

  getPendingThread(quizUuid: string, journeyUuid?: string): Observable<LearnerFeedbackThread> {
    return this.networkService.get(
      `${BLOCK_PENDING_FEEDBACK_THREAD}?blockUuid=${encodeURIComponent(quizUuid)}${
        journeyUuid ? '&journeyUuid=' + journeyUuid : ''
      }`
    );
  }
}
