import React, { useEffect } from 'react';

import { FeatureFlag, FeaturePermission } from '@trainhq/trainhq-client-core';
import i18n from 'i18next';
import { useSearchParams } from 'react-router-dom';

import NotFoundPage from '@/pages/notFound/NotFoundPage';
import RolePlay from '@/roleplay/RolePlay';

const RolePlayPage: React.FC = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    const urlLanguage = searchParams.get('lang');
    const currentLanguageCode = i18n.language.includes('-') ? i18n.language.split('-')[0] : i18n.language;

    if (urlLanguage && urlLanguage !== currentLanguageCode) {
      i18n.changeLanguage(urlLanguage).catch((error) => {});
    }
  }, [searchParams, i18n]);

  return (
    <FeatureFlag featurePermissions={[FeaturePermission.ROLE_PLAY]} render={<NotFoundPage />}>
      <RolePlay />
    </FeatureFlag>
  );
};

export default RolePlayPage;
