import { ProgressStatus } from '@trainhq/trainhq-client-core';

export const getButtonText = (t: (key: string) => string, courseProgress: ProgressStatus, entityName?: string) => {
  return courseProgress === ProgressStatus.FEEDBACK_REQUIRED
    ? t('respond')
    : courseProgress === ProgressStatus.COMPLETED
    ? entityName === 'role-play'
      ? t('practice')
      : t('view')
    : courseProgress === ProgressStatus.IN_PROGRESS || courseProgress === ProgressStatus.PENDING_RESPONSE
    ? t('continue')
    : entityName
    ? `${t('start')} ${entityName}`
    : t('start_course');
};
