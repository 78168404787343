import { CallExecutionType } from '@trainhq/trainhq-client-core';

export const mapTypeOption = (typeOption: string): CallExecutionType[] => {
  switch (typeOption) {
    case '-':
      return ['PRACTICE', 'CALL_INTELLIGENCE_EVALUATION'];
    case 'role-play':
      return ['PRACTICE'];
    case 'calls':
      return ['CALL_INTELLIGENCE_EVALUATION'];
  }
};
