import { createContext, useEffect, useState } from 'react';

import { container, User } from '@trainhq/trainhq-client-core';
import { EMPTY, map, Subscription, switchMap } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { useUserManagementService } from '@/hooks/userManagement/useUserManagementService';
import { UserManagementService } from '@/services/userManagement/userManagementService';
import { getRealmConfig } from '@/utils/urlUtils';

export const useCheckSSOAuthentication = (): { authenticated: boolean; user: User } => {
  const userManagementService = useUserManagementService();
  const [authData, setAuthData] = useState<{ authenticated: boolean; user: User }>({
    authenticated: null,
    user: null
  });

  useEffect(() => {
    const subs = new Subscription();
    subs.add(
      userManagementService.authError
        .pipe(
          switchMap((error) => {
            if (error.errorType === 'authRequired') {
              const realmConfig = getRealmConfig();
              return userManagementService.login(realmConfig?.idpHint);
            }
            return EMPTY;
          })
        )
        .subscribe()
    );
    subs.add(
      userManagementService
        .checkSso()
        .pipe(
          mergeMap(() => {
            return userManagementService.getUser().pipe(
              map((user) => {
                return { authenticated: userManagementService.isAuthenticated, user: user };
              })
            );
          })
        )
        .subscribe({
          next: (authData) => {
            setAuthData(authData);
          },
          error: (err) => {
            setAuthData({ authenticated: false, user: null });
          }
        })
    );
    return () => {
      subs.unsubscribe();
    };
  }, [userManagementService]);

  return authData;
};
export const UserManagementServiceContext = createContext<UserManagementService>(
  container.resolve('userManagementService')
);
