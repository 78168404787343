import { Inject, NetworkService } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { SAVE_SCORM_STATE } from '@/constants/api';

export class ScormCloudService {
  @Inject('networkService')
  private networkService!: NetworkService;

  saveScormData(blockUuid: string, status: string, currentState: any, journeyUuid?: string): Observable<any> {
    return this.networkService.post<any>(SAVE_SCORM_STATE, {
      blockUuid,
      status,
      state: currentState
    });
  }
}
