import { createContext } from 'react';

import {
  RoleplayConfiguration,
  RoleplayExecutionConfig,
  RoleplayNaturalScriptConfiguration,
  RoleplayPairContextMedia,
  RoleplayUserSummary
} from '@trainhq/trainhq-client-core';
import * as SpeechSDKType from 'microsoft-cognitiveservices-speech-sdk';
import { Observable } from 'rxjs';

interface AzureSDKContextProps {
  recognizer: SpeechSDKType.SpeechRecognizer;
  getRecognizer: (forceRefresh?: boolean) => Observable<SpeechSDKType.SpeechRecognizer | null>;
  synthesizer;
  roleplayExecutionConfig: RoleplayExecutionConfig;
  roleplayConfig: RoleplayConfiguration;
  roleplayContext: string;
  roleplaySummary: RoleplayUserSummary;
  roleplayPairContextMedia: RoleplayPairContextMedia[];
  scriptConfig: RoleplayNaturalScriptConfiguration;
  setRoleplaySummary: (newRpSummary: RoleplayUserSummary) => void;
  refreshRoleplaySummary: () => Observable<any>;
  getSynth: (forceRefresh?: boolean) => Observable<SpeechSDKType.SpeechSynthesizer | null>;
  getPlayer?: () => Observable<SpeechSDKType.SpeakerAudioDestination | null>;
  getSsmlMessage: (content: string, expression?: string, voice?: string, speed?: string) => string;
  createSessionAndFetchConfig(): Observable<RoleplayExecutionConfig>;
  providePlayerEndCallback: (callback: () => void) => void;
}

export const AzureSDKContext = createContext<AzureSDKContextProps>(null);
