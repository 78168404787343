import { Drawer } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQPrimaryButton, XIcon } from '@trainhq/trainhq-client-core';

import { detailsDrawerConfig } from '@/components/journey/drawer/JourneyDetailsDrawer';
import { itemDrawerConfig } from '@/components/journey/drawer/JourneyItemDrawer';

export const ItemDrawerStyled = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  position: 'relative',
  width: itemDrawerConfig.width,
  zIndex: 32,
  [`& .MuiDrawer-paper`]: {
    borderLeft: `1px solid ${theme.palette.common.pearl}`,
    width: itemDrawerConfig.width
  },
  [theme.breakpoints.down('md')]: {
    width: '100vw',
    [`& .MuiDrawer-paper`]: {
      width: '100vw'
    }
  }
}));

export const DetailsDrawerStyled = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  position: 'relative',
  width: detailsDrawerConfig.width,
  zIndex: 31,
  [`& .MuiDrawer-paper`]: {
    overflowX: 'hidden',
    borderRight: `1px solid ${theme.palette.common.pearl}`,
    width: detailsDrawerConfig.width
  },
  [theme.breakpoints.down('sm')]: {
    width: '100vw',
    [`& .MuiDrawer-paper`]: {
      overflowX: 'hidden',
      width: '100vw'
    }
  }
}));

export const XButtonStyled = styled(THQPrimaryButton)(({ theme }) => ({
  borderRadius: 4,
  minHeight: 24,
  minWidth: 24,
  padding: 4,
  position: 'absolute',
  right: 32,
  top: 32,
  zIndex: 30,
  [theme.breakpoints.down('md')]: {
    top: 32
  }
}));

export const XIconStyled = styled(XIcon)(() => ({
  height: 12,
  strokeWidth: 3,
  width: 12
}));
