import React from 'react';

import {
  AnalyticsConvoMessage,
  DetailsCardPercentages,
  DetailsCardPercentagesProps
} from '@trainhq/trainhq-client-core';

import CardContainer from '@/components/insights/role-play/common/cards/CardContainer';

export interface ConvoPairProps extends DetailsCardPercentagesProps {
  botAvatar: string;
  botContent: any; // TODO: add type
  userAvatar: string;
  userContent: any; // TODO: add type
}

const AnalyticsPair: React.FC<ConvoPairProps> = (props) => {
  const { botAvatar, botContent, userAvatar, userContent } = props;

  return (
    <>
      <CardContainer>
        <AnalyticsConvoMessage
          avatar={userAvatar}
          content={userContent}
          highlight={false}
          maxWidth={205}
          primary={true}
          side="right"
        />
        <AnalyticsConvoMessage
          avatar={botAvatar}
          content={botContent}
          highlight={false}
          maxWidth={205}
          primary={false}
          side={'left'}
        />
        <DetailsCardPercentages {...props} />
      </CardContainer>
    </>
  );
};

export default AnalyticsPair;
