import React, { useCallback } from 'react';

import { Box } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';

import {
  GrowGridStyled,
  RootGridStyled,
  TitleDivStyled,
  TitleParentTypographyStyled,
  TitleTypographyStyled
} from '@/components/course/block/blockContent/styles';
import { MediaBlock } from '@/components/course/block/media/MediaBlock';
import MultipleChoice from '@/components/course/block/multipleChoice/MultipleChoice';
import Quiz from '@/components/course/block/quiz/Quiz';
import { ScormBlock } from '@/components/course/block/scormBlock/ScormBlock';
import { ComposeBlock } from '@/components/course/block/textBlock/ComposeBlock';
import { LearnerBlock, LearnerSection } from '@/models/learnerModels';

export interface BlockContentProps {
  blockDetails: LearnerBlock;
  section?: LearnerSection;
}

export const BlockContent: React.FC<BlockContentProps> = ({ blockDetails, section }) => {
  const { t } = useTranslation();
  const [searchParams] = useSearchParams();
  const journeyUuid = searchParams.get('journeyUuid');

  const renderBlockDetails = useCallback(() => {
    switch (blockDetails?.blockTemplate?.blockType) {
      case 'MEDIA':
      case 'SCREEN_RECORDING':
        return <MediaBlock content={blockDetails?.content} />;
      case 'QUIZ':
        return <Quiz block={blockDetails} journeyUuid={journeyUuid} />;
      case 'MULTIPLE_CHOICE_QUIZ':
        return <MultipleChoice block={blockDetails} journeyUuid={journeyUuid} />;
      case 'SCORM':
        return <ScormBlock block={blockDetails} journeyUuid={journeyUuid} />;
      default:
        return <ComposeBlock block={blockDetails} />;
    }
  }, [blockDetails, journeyUuid]);

  return (
    <>
      <Box>
        <TitleDivStyled>
          {section && <TitleParentTypographyStyled>{t(section.title)}</TitleParentTypographyStyled>}
          <TitleTypographyStyled>{t(blockDetails?.title)}</TitleTypographyStyled>
        </TitleDivStyled>
      </Box>
      <RootGridStyled alignItems="center" container>
        <GrowGridStyled item xs={12}>
          {!!blockDetails && renderBlockDetails()}
        </GrowGridStyled>
      </RootGridStyled>
    </>
  );
};
