import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';
import { insightsHeaderHeight, mobileInsightsHeaderHeight } from '@trainhq/trainhq-client-core';

export const MainGridStyled = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.common.pearl}`,
  minHeight: insightsHeaderHeight,
  padding: '16px 24px',
  position: 'fixed',
  width: 'calc(100% - 272px)',
  zIndex: 100,
  [theme.breakpoints.down('md')]: {
    width: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    minHeight: mobileInsightsHeaderHeight
  }
}));
