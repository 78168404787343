import React from 'react';

import { FeatureFlag, FeaturePermission } from '@trainhq/trainhq-client-core';

import CourseDetails from '@/components/course/courseDetails/CourseDetails';
import CourseInactivityDialog from '@/components/course/inactivityDialog/CourseInactivityDialog';
import ConfettiOverlay from '@/components/overlays/confettiOverlay/ConfettiOverlay';
import NotFoundPage from '@/pages/notFound/NotFoundPage';

const CourseDetailsPage: React.FC = () => {
  return (
    <FeatureFlag featurePermissions={[FeaturePermission.COURSE]} render={<NotFoundPage />}>
      <ConfettiOverlay>
        <CourseInactivityDialog>
          <CourseDetails />
        </CourseInactivityDialog>
      </ConfettiOverlay>
    </FeatureFlag>
  );
};

export default CourseDetailsPage;
