import { Injectable, Pageable, PageRequest } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { CallIntelligenceCloudService } from '@/services/repositoryData/cloud/callIntelligenceCloudService';

export interface CallIntelligenceService {
  getAvailableCallTypes(): Observable<any>;
  callIntelligenceCallsPage(pageRequest: PageRequest): Observable<Pageable<any>>;
}

@Injectable('callIntelligenceService')
export class CallIntelligenceServiceImpl implements CallIntelligenceService {
  private callIntelligenceCloudService = new CallIntelligenceCloudService();

  getAvailableCallTypes(): Observable<any> {
    return this.callIntelligenceCloudService.getAvailableCallTypes();
  }

  callIntelligenceCallsPage: (pageRequest: PageRequest) => Observable<Pageable<any>> = (pageRequest: PageRequest) => {
    return this.callIntelligenceCloudService.callsPage(pageRequest);
  };
}
