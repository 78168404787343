import { Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Player } from '@trainhq/trainhq-client-core';

export const RootGridStyled = styled(Grid)(() => ({
  height: '100%'
}));

export const CenteredTextDivStyled = styled('div')(() => ({
  textAlign: 'center'
}));

export const ImageStyled = styled('img')(() => ({
  aspectRatio: '5/3',
  width: '100%',
  height: 'auto',
  objectFit: 'cover'
}));

export const TitleTypographyStyled = styled(Typography)(({ theme }) => ({
  lineHeight: 1.25,
  wordBreak: 'break-word',
  fontSize: 18,
  fontWeight: 700,
  marginBottom: 16,
  color: theme.text.primary,
  textAlign: 'center'
}));

export const SubTitleTypographyStyled = styled(Typography)(({ theme }) => ({
  lineHeight: '3vmin',
  wordBreak: 'break-word',
  fontSize: '16px',
  fontWeight: 400,
  color: theme.text.secondary,
  textAlign: 'center'
}));

export const MediaTitleAndSubtitleContainer = styled('div')(() => ({
  justifyContent: 'center',
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  position: 'absolute',
  top: 'calc(75% + 36px)',
  left: '50%',
  width: '80%',
  paddingLeft: '12%',
  paddingRight: '12%',
  transform: 'translateX(-50%)'
}));

export const MediaComponentPlayerStyled = styled(Player)(({ theme }) => ({
  position: 'static',
  height: 'auto',
  width: '100%',
  minWidth: '40%',
  maxHeight: '60vh',
  maxWidth: 'calc((60vh * 16) / 9)',
  marginLeft: '50%',
  aspectRatio: '16/9',
  marginTop: '32px',
  zIndex: '7',
  [theme.breakpoints.down('lg')]: {
    height: 'auto',
    maxWidth: '100%'
  },
  [theme.breakpoints.down('md')]: {
    border: '8px solid #ECF1F4'
  },
  [theme.breakpoints.down('sm')]: {
    border: 'none',
    maxWidth: '100vw',
    maxHeight: 'calc((60vh * 9) / 16)',
    width: '100%',
    borderRadius: 0
  }
}));
