import React, { createContext, useContext } from 'react';

import { LearnerBlock, LearnerCourse } from '@/models/learnerModels';

interface CourseContextProps {
  blockDetails?: LearnerBlock; // TODO: check with Pavke if he could return block details for current block so we can avoid fetching blocks an then redirecting to that route
  course?: LearnerCourse; // TODO: get rid of learner course and use CourseBuilder from core
  currentBlock?: LearnerBlock;
  isFirst: boolean;
  isLast: boolean;
  nextBlock: LearnerBlock;
  previousBlock: LearnerBlock;
  previewModeOn?: boolean;
  sidebarExpanded: boolean;
  timeSpent?: number;
  goToBlock(block: LearnerBlock, forceIfCurrent?: boolean, newBlockStatus?: string);
  setBlockDetails?: React.Dispatch<React.SetStateAction<LearnerBlock>>;
  setCourse: React.Dispatch<React.SetStateAction<LearnerCourse>>;
}

export const CourseContext = createContext<CourseContextProps>(null);
export const useCourseContext = () => useContext(CourseContext);
