import React, { useCallback, useMemo, useState } from 'react';

import { Grid, Typography, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import {
  insightsHeaderHeight,
  mobileInsightsHeaderHeight,
  THQCourseProgressChip,
  THQMainPageContainer,
  THQSelect,
  UserStatus
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { SelectedRPInsightsTabContext, useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import NaturalRolePlay from '@/components/insights/role-play/naturalRolePlay/NaturalRolePlay';
import StrictRolePlay from '@/components/insights/role-play/strictRolePlay/StrictRolePlay';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useGetRPUserStatus } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const RolePlayInsights: React.FC = () => {
  const { t } = useTranslation();
  const context = useSelectedRPInsightsTabContext();
  const theme = useTheme();
  const { rolePlayUuid } = useParams<{ rolePlayUuid: string }>();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const user = useAuthenticatedUserContext();

  const insightTypeOptions = [
    { label: t('all'), value: '-' },
    { label: t('roleplay'), value: 'role-play' },
    { label: t('calls'), value: 'calls' }
  ];

  const status = useGetRPUserStatus(rolePlayUuid);

  const [selectedTypeOption, setSelectedTypeOption] = useState<string>('-');

  const handleOnChangeTypeOption = useCallback((e) => {
    setSelectedTypeOption(e.target.value);
  }, []);

  const contextValue = useMemo(
    () => ({
      ...context,
      selectedTypeOption: selectedTypeOption
    }),
    [context, selectedTypeOption]
  );

  return (
    <SelectedRPInsightsTabContext.Provider value={contextValue}>
      <THQMainPageContainer
        sx={{ height: 'auto', marginTop: `${isMobile ? mobileInsightsHeaderHeight : insightsHeaderHeight}px` }}
      >
        <Typography fontWeight={700} sx={{ marginBottom: '32px' }} variant="h2">
          {user.firstName} {user.lastName}{' '}
          {selectedTypeOption !== 'calls' && status?.progressState && (
            <THQCourseProgressChip courseProgressStatus={status?.progressState} sx={{ height: '22px' }} />
          )}
        </Typography>
        {status && <UserStatus status={status} />}
        <Grid container>
          <Grid item>
            <THQSelect
              label={t('type')}
              options={insightTypeOptions}
              value={selectedTypeOption}
              onChange={handleOnChangeTypeOption}
            />
          </Grid>
        </Grid>
        {context?.selectedRolePlay &&
          (context.selectedRolePlay.rolePlayType === 'STRICT' ? <StrictRolePlay /> : <NaturalRolePlay />)}
      </THQMainPageContainer>
    </SelectedRPInsightsTabContext.Provider>
  );
};

export default RolePlayInsights;
