import { Grid, styled } from '@mui/material';
import { THQPrimaryButton } from '@trainhq/trainhq-client-core';

export const RecordingStudioGridStyled = styled(Grid)(({ theme }) => ({
  aspectRatio: '16/9',
  border: `8px solid ${theme.palette.common.pearl}`,
  borderRadius: 24,
  marginTop: 20
}));

// TODO: move this button to core and use it both in manager and learner
export const RecordingButtonStyled = styled(THQPrimaryButton)(() => ({
  background: 'linear-gradient(92.53deg, #FB5D64 1.24%, #E8178A 99.03%)',
  border: 'none',
  borderRadius: 24,
  color: '#FFFFFF',
  display: 'block',
  height: 48,
  margin: '0 auto',
  '&:hover': {
    border: 'none'
  }
}));
