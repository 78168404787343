import { Injectable, Milestone } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { MilestoneCloudService } from '@/services/repositoryData/cloud/milestoneCloudService';

export interface MilestoneRepository {
  completeMilestone(milestone: Milestone, journeyUuid: string): Observable<Milestone>;
}

@Injectable('milestoneRepository')
export class MilestoneRepositoryImpl implements MilestoneRepository {
  constructor(private milestoneCloudService = new MilestoneCloudService()) {}

  completeMilestone(milestone: Milestone, journeyUuid: string): Observable<Milestone> {
    return this.milestoneCloudService.completeMilestone(milestone, journeyUuid);
  }
}
