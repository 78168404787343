import React from 'react';

import '@/services/bootstrap';

import { responsiveFontSizes, ThemeProvider } from '@mui/material/styles';
import { lightTheme } from '@trainhq/trainhq-client-core';
import { createRoot } from 'react-dom/client';
import { RouterProvider } from 'react-router-dom';

import { ThemeConfigContext } from './context/appConfigContext';
import { router } from '@/router/browserRouter';
import { font } from '@/themes/utils';
import { getRealmConfig } from '@/utils/urlUtils';

const container = document.getElementById('root');
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = createRoot(container!);
const realmConfig = getRealmConfig();

fetch(`https://trainhq-static-files.s3.amazonaws.com/themes/${realmConfig.realm}/theme.json`)
  .then((response) => {
    console.log(response);
    return response.json();
  })
  .then((themeConfig) => {
    // Work with JSON data here
    console.log(themeConfig);
    if (themeConfig.favicon) {
      const link: any = document.querySelector("link[rel~='icon']");
      link.href = themeConfig.favicon;
    }
    if (themeConfig.learnerAppTitle) {
      document.title = themeConfig.learnerAppTitle;
    }
    root.render(
      <ThemeConfigContext.Provider value={{ themeConfig: themeConfig }}>
        <ThemeProvider
          theme={responsiveFontSizes(
            lightTheme(
              font(themeConfig.font, themeConfig.fontItalic, themeConfig.fontFamily),
              `${themeConfig.fontFamily}, DM Sans, sans-serif`,
              themeConfig.colorPalette
            )
          )}
        >
          <RouterProvider router={router} />
        </ThemeProvider>
      </ThemeConfigContext.Provider>
    );
  })
  .catch((err) => {
    // Do something for an error here
    console.log('Error Reading data ' + err);
    root.render(
      <ThemeProvider theme={responsiveFontSizes(lightTheme(font(), 'DM Sans, sans-serif'))}>
        <RouterProvider router={router} />
      </ThemeProvider>
    );
  });
