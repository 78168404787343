import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { Divider, Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  ContentItem,
  ContentItemList,
  ContentItemType,
  EmptyStateTemplate,
  FeatureFlag,
  FeaturePermission,
  LibraryFilters,
  LibraryItemHeader,
  THQActions,
  THQAppBar,
  THQMainPageContainer,
  useFeaturesEnabled,
  usePrevious
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';

import noCoursesInLP from '@/assets/images/no_courses_in_lp.png';
import { useActions } from '@/components/library/actions/useActions';
import BreadcrumbLink from '@/components/library/link/BreadcrumbLink';
import { ItemCounterStyled } from '@/components/library/styles';
import { getPageCriteria, getSearchCriteria } from '@/components/library/utils';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import {
  useContentLibraryService,
  useGetAllByUuids,
  useGetContentItem,
  useGetLibraryContentItems
} from '@/hooks/contentLibrary/useContentLibraryService';
import useSetAppBar from '@/layout/useSetAppBar';

const Library: React.FC = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();
  const routerParams = useParams();
  const contentLibraryService = useContentLibraryService();
  const stringRouterParams = routerParams?.['*'];
  const previousStringRouterParams = usePrevious(stringRouterParams);
  const routerParamsArray = useMemo(() => routerParams?.['*'].split('/'), [routerParams]);
  const { activeCompany } = useAuthenticatedUserContext();
  const rootUuid = activeCompany?.contentLibraryRootUuid;
  const directoryId = routerParamsArray[routerParamsArray.length - 1] || rootUuid;
  const isRootDirectory = !routerParamsArray[routerParamsArray.length - 1];
  const initialLoaded = useRef<boolean>(false);
  const usedNavigation = useRef<boolean>(false);
  const shouldResetFilters =
    previousStringRouterParams !== stringRouterParams && (stringRouterParams || previousStringRouterParams);
  const courseFeatureEnabled = useFeaturesEnabled([FeaturePermission.COURSE]);

  const [dirsThroughNavigation, setDirsThroughNavigation] = useState<ContentItem[]>([]);
  const [backNavDirs, setBackNavDirs] = useState<ContentItem[]>([]);
  const [selectedContentItem, setSelectedContentItem] = useState<ContentItem>();
  const [searchResults, setSearchResults] = useState<ContentItem[]>([]);
  const [loadMorePage, setLoadMorePage] = useState<number>(1);
  const [isLastPage, setIsLastPage] = useState<boolean>(false);
  const [loadingSearch, setLoadingSearch] = useState<boolean>(false);

  const { contentItem: fetchedDirectory } = useGetContentItem(
    directoryId,
    dirsThroughNavigation.length === 0 && !isRootDirectory
  );
  const { contentItems: breadcrumbFolders } = useGetAllByUuids(
    routerParamsArray,
    dirsThroughNavigation.length === 0 && !isRootDirectory
  );

  const currentDirectory = useMemo(
    () => dirsThroughNavigation?.[dirsThroughNavigation.length - 1] || fetchedDirectory,
    [dirsThroughNavigation, fetchedDirectory]
  );
  const breadcrumbs = (dirsThroughNavigation.length > 0 ? dirsThroughNavigation : breadcrumbFolders)?.slice(0, -1); // remove active folder from breadcrumbs

  const coursesPageCoordinator = courseFeatureEnabled
    ? useGetLibraryContentItems({
        page: 0,
        size: 3,
        sort: [{ column: 'createdAt', sort: 'DESC' }],
        criterias: getPageCriteria(ContentItemType.COURSE, directoryId)
      })
    : undefined;
  const {
    hasNextPage: hasMoreCourses,
    loadedItems: courses,
    loading: loadingCourses,
    totalCount: totalCourses,
    loadMore: loadMoreCourses,
    search: searchCourses
  } = useMemo(() => ({ ...coursesPageCoordinator }), [coursesPageCoordinator]);

  const {
    hasNextPage: hasMoreFolders,
    loadedItems: folders,
    loading: loadingFolders,
    loadMore: loadMoreFolders,
    search: searchFolders
  } = useGetLibraryContentItems({
    page: 0,
    size: 12,
    sort: [{ column: 'createdAt', sort: 'DESC' }],
    criterias: getPageCriteria(ContentItemType.DIRECTORY, directoryId)
  });

  const {
    hasNextPage: hasMoreFiles,
    loadedItems: mediaFiles,
    loading: loadingFiles,
    totalCount: totalFiles,
    loadMore: loadMoreMediaFiles,
    search: searchMediaFiles
  } = useGetLibraryContentItems({
    page: 0,
    size: 3,
    sort: [{ column: 'createdAt', sort: 'DESC' }],
    criterias: getPageCriteria(ContentItemType.MEDIA_FILE, directoryId)
  });

  const noCourses = (!loadingCourses && !courses) || courses?.length === 0;
  const noFolders = folders.length === 0;
  const noFiles = mediaFiles.length === 0;
  const emptyLib = noCourses && noFolders && noFiles;
  const showEmptyState = emptyLib && !loadingCourses && !loadingFolders && !loadingFiles;

  // handles navigating through breadcrumbs, urls, browsers' native back/forward
  useEffect(() => {
    if (initialLoaded?.current) {
      if (searchCourses) {
        searchCourses(getPageCriteria(ContentItemType.COURSE, directoryId));
      }
      searchFolders(getPageCriteria(ContentItemType.DIRECTORY, directoryId));
      searchMediaFiles(getPageCriteria(ContentItemType.MEDIA_FILE, directoryId));
    }
    // handles removal of breadcrumb from breadcrumbs array
    setDirsThroughNavigation((prevState) => {
      const newState: ContentItem[] = [];
      let i = 0;
      const j = prevState.length;
      for (i; j; i < j) {
        if (!prevState[i] || isRootDirectory) {
          break;
        }
        newState.push(prevState[i]);
        if (prevState[i].uuid === directoryId) {
          break;
        }
        i++;
      }
      return newState;
    });
  }, [directoryId, isRootDirectory, searchCourses, searchFolders, searchMediaFiles]);

  useEffect(() => {
    initialLoaded.current = true;
  }, []);
  // -----------------------------

  const handleSearch = useCallback(
    (value: string) => {
      setLoadingSearch(true);
      if (value) {
        contentLibraryService.contentItemPage(getSearchCriteria(rootUuid, value, 0)).subscribe({
          next: (res) => {
            setSearchResults(res.content);
          },
          complete: () => {
            setLoadingSearch(false);
          }
        });
      } else {
        setSearchResults([]);
      }
      setLoadMorePage(1);
      setIsLastPage(false);
    },
    [rootUuid, contentLibraryService]
  );

  const handleClearSearchResults = useCallback(() => {
    setSearchResults([]);
  }, []);

  const handleBackButtonClick = useCallback(() => {
    if (backNavDirs.length > 0) {
      setDirsThroughNavigation(backNavDirs);
    }
    setBackNavDirs([]);
  }, [backNavDirs]);

  const handleOnClickSearchedItem = useCallback(
    (path: string, isFolder?: boolean) => {
      usedNavigation.current = true;
      if (isFolder) {
        contentLibraryService.getAllByUuids(path.split('/')).subscribe({
          next: (result) => {
            setDirsThroughNavigation((prevState) => {
              setBackNavDirs(prevState);
              return result;
            });
          }
        });
      }
      navigate(path, { state: { backLink: location.pathname } });
      handleClearSearchResults();
    },
    [contentLibraryService, navigate, location.pathname, handleClearSearchResults]
  );

  const handleSelectContentItem = useCallback((contentItem: ContentItem) => {
    setSelectedContentItem(contentItem);
  }, []);

  const handleDeselectContentItem = useCallback(() => {
    setSelectedContentItem(undefined);
  }, []);

  const handleDoubleClickContentItem = useCallback(
    (contentItem: ContentItem) => {
      if (contentItem.contentItemType === ContentItemType.DIRECTORY) {
        navigate(contentItem.uuid, { relative: 'path' });
        setDirsThroughNavigation((prevState) => {
          // handles various cases, for example when user comes to a non-root folder through link, but then
          // continues navigation using breadcrumbs
          if (!usedNavigation.current) {
            usedNavigation.current = true;
            return [...(breadcrumbFolders || []), ...prevState, contentItem];
          }
          return [...prevState, contentItem];
        });
        setBackNavDirs([]);
      }
    },
    [breadcrumbFolders, navigate]
  );

  const handleOnLoadMoreFoldersClick = useCallback(() => {
    loadMoreFolders();
  }, [loadMoreFolders]);

  const handleOnLoadMoreCoursesClick = useCallback(() => {
    loadMoreCourses(6);
  }, [loadMoreCourses]);

  const handleOnLoadMoreFilesClick = useCallback(() => {
    loadMoreMediaFiles(6);
  }, [loadMoreMediaFiles]);

  const handleLoadMore = useCallback(
    (value: string) => {
      if (!isLastPage) {
        return contentLibraryService.contentItemPage(getSearchCriteria(rootUuid, value, loadMorePage)).subscribe({
          next: (res) => {
            setSearchResults((prevState) => [...prevState, ...res.content]);
            if (!res.last) {
              setLoadMorePage((prevState) => prevState + 1);
            } else {
              setIsLastPage(true);
            }
          }
        });
      }
    },
    [contentLibraryService, isLastPage, loadMorePage, rootUuid]
  );

  const actions = useActions(selectedContentItem, handleDoubleClickContentItem);
  const appBar = useMemo(
    () =>
      isTablet && isRootDirectory ? (
        <THQAppBar text={t('library')} />
      ) : !isRootDirectory ? (
        <LibraryItemHeader
          Link={BreadcrumbLink}
          breadcrumbItems={breadcrumbs}
          contentItem={currentDirectory}
          backButtonClick={handleBackButtonClick}
          clearSearchResults={handleClearSearchResults}
        />
      ) : null,
    [breadcrumbs, currentDirectory, handleBackButtonClick, handleClearSearchResults, isRootDirectory, isTablet]
  );

  useSetAppBar(appBar, 125);

  return (
    <>
      <THQMainPageContainer sx={{ marginBottom: '100px' }}>
        {!isTablet && isRootDirectory && (
          <Grid item sx={{ marginBottom: '40px' }}>
            <Grid container alignItems="center">
              <Grid item>
                <Typography sx={{ fontWeight: 600 }} variant="h3">
                  {t('library')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        )}
        <LibraryFilters
          loading={loadingSearch}
          resetFilters={shouldResetFilters}
          searchResults={searchResults}
          loadMore={handleLoadMore}
          onClick={handleOnClickSearchedItem}
          search={handleSearch}
        />
        {folders?.length > 0 && (
          <>
            <Typography fontSize={18} fontWeight={700}>
              {t('folders')}
            </Typography>
            <Divider sx={{ border: 'none', height: '16px', width: '100%' }} />
            <ContentItemList
              contentItems={folders}
              selectedItem={selectedContentItem}
              showLoadMore={hasMoreFolders}
              onContentItemAwayClick={handleDeselectContentItem}
              onContentItemClick={handleSelectContentItem}
              onContentItemDoubleClick={handleDoubleClickContentItem}
              onLoadMoreClick={handleOnLoadMoreFoldersClick}
              setSelectedItem={setSelectedContentItem}
            />
          </>
        )}
        <FeatureFlag featurePermissions={[FeaturePermission.COURSE]}>
          {courses?.length > 0 && (
            <>
              <Divider sx={{ border: 'none', height: '32px', width: '100%' }} />
              <Typography fontSize={18} fontWeight={700}>
                {t('courses')} <ItemCounterStyled>({totalCourses})</ItemCounterStyled>
              </Typography>
              <Divider sx={{ border: 'none', height: '16px', width: '100%' }} />
              <ContentItemList
                contentItems={courses}
                selectedItem={selectedContentItem}
                showLoadMore={hasMoreCourses}
                onContentItemAwayClick={handleDeselectContentItem}
                onContentItemClick={handleSelectContentItem}
                onContentItemDoubleClick={handleDoubleClickContentItem}
                onLoadMoreClick={handleOnLoadMoreCoursesClick}
                setSelectedItem={setSelectedContentItem}
              />
            </>
          )}
        </FeatureFlag>
        {mediaFiles?.length > 0 && (
          <>
            <Divider sx={{ border: 'none', height: '32px', width: '100%' }} />
            <Typography fontSize={18} fontWeight={700}>
              {t('files')} <ItemCounterStyled>({totalFiles})</ItemCounterStyled>
            </Typography>
            <Divider sx={{ border: 'none', height: '16px', width: '100%' }} />
            <ContentItemList
              contentItems={mediaFiles}
              selectedItem={selectedContentItem}
              showLoadMore={hasMoreFiles}
              onContentItemAwayClick={handleDeselectContentItem}
              onContentItemClick={handleSelectContentItem}
              onContentItemDoubleClick={handleDoubleClickContentItem}
              onLoadMoreClick={handleOnLoadMoreFilesClick}
              setSelectedItem={setSelectedContentItem}
            />
          </>
        )}
        {showEmptyState && (
          <EmptyStateTemplate
            containerSx={{ height: 'auto' }}
            title={t('no_items_library')}
            description={t('no_items_library_desc')}
            heroImage={noCoursesInLP}
            heroImageMaxWidth={200}
          />
        )}
      </THQMainPageContainer>
      <THQActions actions={actions} open={!!selectedContentItem} onClose={handleDeselectContentItem} />
    </>
  );
};

export default Library;
