import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

// TODO: create proper layout for this without overflow hidden
export const RootGridStyled = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100%',
  maxHeight: 'calc(100vh - 101px)', // 40px for padding top of the page, 60px for header height (title) and 1px border
  overflowX: 'hidden',
  overflowY: 'auto',
  paddingLeft: 0,
  paddingRight: 0
}));

export const NoGrowGridStyled = styled(Grid)(() => ({
  flexGrow: 0
}));

export const GrowGridStyled = styled(Grid)(() => ({
  height: '100%',
  flexGrow: 1,
  overflowY: 'visible'
}));

export const TitleDivStyled = styled('div')(() => ({
  textAlign: 'center',
  backgroundColor: 'white',
  paddingLeft: 0,
  paddingRight: 0,
  paddingTop: '0',
  paddingBottom: '12px'
}));

export const TitleParentTypographyStyled = styled('div')(({ theme }) => ({
  fontWeight: 600,
  fontSize: 12,
  color: theme.palette.common.silver
}));

export const TitleTypographyStyled = styled('div')(() => ({
  fontWeight: 700,
  fontSize: 16
}));
