import React from 'react';

import { Drawer, useMediaQuery, useTheme } from '@mui/material';

import { DrawerDivStyled, SidebarRootGridStyled } from '@/layouts/sidebar/styles';

interface SideBarMainProps {
  content: React.ReactElement;
  expanded?: boolean;
  toggleExpanded?: () => void;
}

// TODO: change name to be different than SideBarMain from core
const SideBarMain: React.FC<SideBarMainProps> = ({ content, expanded, toggleExpanded }) => {
  const theme = useTheme();
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

  return (
    <>
      {!isTablet && (
        <SidebarRootGridStyled expanded={expanded} item>
          <DrawerDivStyled expanded={expanded}>{content}</DrawerDivStyled>
        </SidebarRootGridStyled>
      )}
      {isTablet && (
        <Drawer anchor={'left'} elevation={0} open={expanded} onClose={toggleExpanded}>
          <DrawerDivStyled expanded={true}>{content}</DrawerDivStyled>
        </Drawer>
      )}
    </>
  );
};

export default SideBarMain;
