import React, { useEffect } from 'react';

import { useNavigate, useSearchParams } from 'react-router-dom';

import { ProgressStyled } from '@/components/authentication/tokenAuth/styles';
import { COURSE_DETAILS_STRING, HOME } from '@/constants/router';

const TokenAuthComponent: React.FC = () => {
  const [searchParams] = useSearchParams();
  const courseUuid = searchParams.get('courseUuid');
  const navigateTo = searchParams.get('navigateTo');
  const navigate = useNavigate();

  useEffect(() => {
    if (navigateTo === 'COURSE' && courseUuid) {
      navigate(`${COURSE_DETAILS_STRING}/${courseUuid}?preview=1`);
    } else {
      navigate(HOME);
    }
  });
  return <ProgressStyled size={60} />;
};

export default TokenAuthComponent;
