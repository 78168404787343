import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  FeaturePermission,
  PageRequest,
  PaginationCoordinator,
  useFeaturesEnabled,
  useGetPagination
} from '@trainhq/trainhq-client-core';
import { finalize, map, Subscription } from 'rxjs';

import { CourseServiceContext, PreviewCourseServiceContext } from '@/context/courseServiceContext';
import { LearnerContentBuilder, LearnerCourse } from '@/models/learnerModels';

export const useGetCourse = (
  courseUuid: string,
  journeyUuid?: string,
  previewMode?: boolean
): {
  course: LearnerCourse;
  setCourse: React.Dispatch<React.SetStateAction<LearnerCourse>>;
  loading: boolean;
} => {
  const [course, setCourse] = useState<LearnerCourse>();
  const [loading, setLoading] = useState<boolean>();
  const courseService = useCourseService(previewMode);
  useEffect(() => {
    setLoading(true);
    const subs = courseService
      .getCourse(courseUuid, journeyUuid)
      .pipe(
        map((course: LearnerCourse) => {
          const sortedSections = [...course.sections.sort((a, b) => a.order - b.order)];
          sortedSections.forEach((section) => section.blocks.sort((a, b) => a.order - b.order));
          return {
            ...course,
            ...(course.currentBlock && {
              currentSection: sortedSections.find(
                (section) => section.blocks.findIndex((block) => block.uuid === course.currentBlock?.uuid) > -1
              )
            }),
            sections: [...sortedSections]
          };
        }),
        finalize(() => {
          setLoading(false);
        })
      )
      .subscribe({
        next: (course) => {
          setCourse(course);
        }
      });
    return () => subs.unsubscribe();
  }, [courseService, courseUuid, journeyUuid]);

  return useMemo(() => ({ course, setCourse, loading }), [course, loading]);
};

export const useGetAllContentBuilders = (): { contentBuilders: LearnerContentBuilder[]; loading: boolean } => {
  const courseService = useCourseService();
  const courseFeatureEnabled = useFeaturesEnabled([FeaturePermission.COURSE]);

  const [contentBuilders, setContentBuilders] = useState<LearnerContentBuilder[]>();
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    const subs = new Subscription();

    setLoading(true);
    subs.add(
      courseService
        .allContent()
        .pipe(
          map((courses) =>
            courses.map((contentBuilder) => {
              if (contentBuilder.contentBuilderType === 'ROLE_PLAY') {
                return contentBuilder;
              }
              if (contentBuilder.contentBuilderType === 'JOURNEY') {
                return contentBuilder;
              } else {
                const course = contentBuilder.course;
                const sortedSections = course.sections.sort((a, b) => a.order - b.order);
                sortedSections.forEach((section) => section.blocks.sort((a, b) => a.order - b.order));
                return {
                  ...contentBuilder,
                  course: {
                    ...course,
                    ...(course.currentBlock && {
                      currentSection: sortedSections.find(
                        (section) => section.blocks.findIndex((block) => block.uuid === course.currentBlock?.uuid) > -1
                      )
                    }),
                    sections: [...sortedSections]
                  }
                };
              }
            })
          ),
          finalize(() => {
            setLoading(false);
          })
        )
        .subscribe({
          next: (courses) => {
            setContentBuilders(courses);
          }
        })
    );

    return () => subs.unsubscribe();
  }, [courseFeatureEnabled, courseService]);

  return useMemo(
    () => ({
      contentBuilders,
      loading
    }),
    [contentBuilders, loading]
  );
};

export const useGetCoursesPage = (pageRequest: PageRequest): PaginationCoordinator<LearnerCourse> => {
  const courseService = useCourseService();
  return useGetPagination<LearnerCourse>(courseService.page, pageRequest);
};

export const useCourseService = (preview?: boolean) =>
  preview ? useContext(PreviewCourseServiceContext) : useContext(CourseServiceContext);
