import React, { createContext, useCallback, useContext, useState } from 'react';

import { Typography } from '@mui/material';
import { THQDialog, THQDialogActions, THQDialogContent, THQPrimaryButton } from '@trainhq/trainhq-client-core';

import NotebookImage from '@/assets/images/notebook.png';

type MicPermissionDialogContext = (onSubmitCallback: () => void) => void;
const context = createContext<MicPermissionDialogContext>(null);
export const useMicPermissionDialog = () => useContext(context);

interface MicPermissionDialogProps {
  children?: React.ReactNode;
}

const MicPermissionDialog: React.FC<MicPermissionDialogProps> = ({ children }) => {
  const [open, setOpen] = useState<boolean>(false);
  const [onSubmitCallback, setOnSubmitCallback] = useState<() => void>();

  const handleSubmit = () => {
    setOpen(false);
    onSubmitCallback();
  };

  const contextValue = useCallback<MicPermissionDialogContext>((onSubmitCallback: () => void) => {
    setOpen(true);
    setOnSubmitCallback(() => onSubmitCallback);
  }, []);

  return (
    <context.Provider value={contextValue}>
      {children}
      <THQDialog fullWidth open={open} showXButton={false}>
        <THQDialogContent>
          <img
            alt="notebook-image"
            src={NotebookImage}
            style={{ display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
          />
          <Typography align="center" fontSize={18} fontWeight={700} marginBottom="16px" marginTop="24px">
            Microphone Access Required
          </Typography>
          <Typography align="center">
            Please allow TrainHQ to use your microphone in your browser settings in order to start this role-play.
          </Typography>
        </THQDialogContent>
        <THQDialogActions>
          <THQPrimaryButton sx={{ marginLeft: 'auto', marginRight: 'auto' }} variant="main" onClick={handleSubmit}>
            Try again
          </THQPrimaryButton>
        </THQDialogActions>
      </THQDialog>
    </context.Provider>
  );
};

export default MicPermissionDialog;
