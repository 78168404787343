import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const EmptyCardContainer = styled(Grid)(({ theme }) => ({
  paddingBottom: '32px',
  paddingTop: '32px',
  display: 'flex',
  flexDirection: 'column',
  flexWrap: 'nowrap',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: theme.palette.common.zima,
  maxWidth: '335px',
  maxHeight: '204px',
  minHeight: '204px',
  border: `1px solid ${theme.palette.common.pearl}`,
  borderRadius: '24px'
}));
