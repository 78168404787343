import React from 'react';

import Insights from '@/components/insights/Insights';
import useSetAppBar from '@/layout/useSetAppBar';

const InsightsPage: React.FC = () => {
  useSetAppBar(null); // since on this page we use custom app bar because of specific CSS requests, we must remove the app bar that could have been set on previous page

  return <Insights />;
};

export default InsightsPage;
