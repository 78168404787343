import { Link } from '@mui/material';
import { styled } from '@mui/material/styles';

export const LinkStyled = styled(Link)(({ theme }) => ({
  color: theme.palette.common.silver,
  fontSize: 14,
  textDecoration: 'none',
  '&:hover': {
    color: theme.palette.common.smoke,
    textDecoration: 'underline'
  }
})) as typeof Link;
