import React from 'react';

import { Grid } from '@mui/material';

import { StyledPlayer } from '@/components/course/block/quiz/styles';

export const renderMediaContent = (mediaContent) => {
  if (!mediaContent) {
    return <></>;
  }
  switch (mediaContent?.fileMetadata?.mediaFileType) {
    case 'VIDEO':
    case 'AUDIO':
      return (
        <Grid item xs={12}>
          <StyledPlayer key={mediaContent?.uuid} file={mediaContent} sx={{ zIndex: 0, marginTop: 0 }} />
        </Grid>
      );
    case 'IMAGE':
      return (
        <Grid item xs={12}>
          <img
            style={{
              objectFit: 'contain',
              width: '100%',
              height: 'auto',
              maxHeight: '360px',
              borderRadius: '16px'
            }}
            src={mediaContent?.streamingUrl}
          />
        </Grid>
      );
  }
};
