import React from 'react';

import { RolePlayModePhase } from '@trainhq/trainhq-client-core';

export const naturalRolePlayPhaseOrder = [RolePlayModePhase.WATCH, RolePlayModePhase.PRACTICE];

export const getNextPhase = (currentPhase: RolePlayModePhase) => {
  const currentIndex = naturalRolePlayPhaseOrder.findIndex((item) => item === currentPhase);
  if (currentIndex === -1 || currentIndex + 1 >= naturalRolePlayPhaseOrder.length) {
    return null;
  }
  return naturalRolePlayPhaseOrder[currentIndex + 1];
};

export const getPhaseOrder = (currentPhase: RolePlayModePhase) => {
  return naturalRolePlayPhaseOrder.findIndex((item) => item === currentPhase);
};

export const comparePhases = (currentPhase: RolePlayModePhase, phaseToCompareWith: RolePlayModePhase) => {
  const currentIndex = naturalRolePlayPhaseOrder.findIndex((item) => item === currentPhase);
  const compareIndex = naturalRolePlayPhaseOrder.findIndex((item) => item === phaseToCompareWith);
  return compareIndex - currentIndex;
};

export const createFormRoleplayContext = (roleplayContext: string) => {
  return roleplayContext.split(/\n/gm).map((item, index) => (
    <React.Fragment key={'' + index}>
      <span>{item}</span>
      <br />
    </React.Fragment>
  ));
};

export const loaderMessages = [
  '11001011 10010010',
  '11001011 10010010',
  '11001011 10010010',
  '11001011 10010010',
  'Installing a human avatar',
  'Installing a human avatar',
  'Installing a human avatar',
  'Passing the Turing test',
  'Passing the Turing test',
  'Passing the Turing test',
  'Passing the Turing test',
  'Definitely not preparing to take over the world',
  'Definitely not preparing to take over the world',
  'Definitely not preparing to take over the world',
  'Definitely not preparing to take over the world',
  'Definitely not preparing to take over the world',
  'Siri and Alexa who?',
  'Oh, hello',
  'Just a second, I will be right with you',
  'Just need to find the',
  'What we will be going through',
  'Oh, here it is! Now'
];

export const sidebarGoals = (rolePlaySections, jsonGoals) => {
  if (rolePlaySections) {
    const sectionsToRender = rolePlaySections?.sort((a, b) => a.order - b.order);
    sectionsToRender.map((section) => {
      return { ...section, goals: section.goals.sort((a, b) => a.order - b.order) };
    });
    return sectionsToRender;
  }
  if (jsonGoals) {
    return JSON.parse(jsonGoals);
  }
  return null;
};

export const sidebarObjections = (rolePlayObjections, objectionsBank) => {
  if (rolePlayObjections) {
    return rolePlayObjections?.sort((a, b) => a.order - b.order);
  }
  if (!objectionsBank) {
    return null;
  }
  return JSON.parse(objectionsBank);
};

export interface TTSReadMessage {
  message: string;
  chatMessages: { sender: string; message: string }[];
  expression?: string;
  messageEndCallback?: () => void;
}
