import { Box, Grid, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

interface NameTypographyStyledProps {
  isItemLocked?: boolean;
  isSelected?: boolean;
}

interface SidebarRootGridStyledProps {
  noPointer?: boolean;
}

export const SidebarRootGridStyled = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'noPointer'
})<SidebarRootGridStyledProps>(({ noPointer }) => ({
  cursor: noPointer ? 'initial' : 'pointer',
  position: 'relative',
  color: '#3D3D3D',
  fontWeight: 'bold',
  wordBreak: 'break-all'
}));

export const ContainerGridStyled = styled(Grid)(() => ({
  // paddingLeft: 32,
  fontWeight: 'normal'
}));

export const BlockNameTypographyStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isItemLocked' && prop !== 'isSelected'
})<NameTypographyStyledProps>(({ theme, isItemLocked, isSelected }) => ({
  whiteSpace: 'pre-line',
  wordBreak: 'break-word',
  color: theme.palette.grey[400],
  ...(isItemLocked && {
    color: theme.typography.body2.color,
    cursor: 'auto',
    fontSize: '14px'
  }),
  ...(isSelected && {
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: '14px'
  })
}));

export const NameTypographyStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'isItemLocked' && prop !== 'isSelected'
})<NameTypographyStyledProps>(({ theme, isItemLocked, isSelected }) => ({
  fontSize: '12px',
  display: 'flex',
  textTransform: 'uppercase',
  ...(isItemLocked && {
    cursor: 'auto',
    color: theme.sidebar.text.title.color
  }),
  ...(isSelected && {
    color: theme.palette.primary.main,
    fontWeight: 700
  })
}));

export const LockedIconChildGridStyled = styled('span')(({ theme }) => ({
  color: theme.palette.grey[400],
  marginRight: '0',
  marginLeft: 'auto',
  backgroundColor: theme.palette.grey[110],
  padding: '4px 6px',
  borderRadius: '6px',
  maxWidth: '34px',
  minHeight: '22px',
  minWidth: '34px',
  maxHeight: '22px',
  fontSize: '10px',
  textAlign: 'center',
  verticalAlign: 'text-top'
}));

interface CheckMarkIconContainerProps {
  currentSectionIndex: number;
  currentBlockIndex: number;
  sectionOrder?: number;
  sidebarItemOrder: number;
  sidebarItemState?: string;
  isQuiz?: boolean;
}

export const CheckMarkIconContainer = styled('span', {
  shouldForwardProp: (prop) =>
    prop !== 'currentSectionIndex' &&
    prop !== 'currentBlockIndex' &&
    prop !== 'sectionOrder' &&
    prop !== 'sidebarItemOrder' &&
    prop !== 'sidebarItemState' &&
    prop !== 'isQuiz'
})<CheckMarkIconContainerProps>(({
  theme,
  currentSectionIndex,
  currentBlockIndex,
  sectionOrder,
  sidebarItemOrder,
  sidebarItemState,
  isQuiz
}) => {
  const checkmarkStyle = {
    color: theme.palette.background.paper,
    height: '16px'
  };
  if (
    currentSectionIndex > sectionOrder ||
    (currentSectionIndex === sectionOrder && currentBlockIndex >= sidebarItemOrder)
  ) {
    if (!isQuiz) {
      checkmarkStyle.color = theme.palette.success.main;
    } else {
      switch (sidebarItemState) {
        case 'ACCEPTED':
          checkmarkStyle.color = theme.palette.success.main;
          break;
        case 'PENDING_RESPONSE':
          checkmarkStyle.color = theme.palette.warning.main;
          break;
        case 'APPROVER_REJECTED':
        case 'FEEDBACK_REQUIRED':
          checkmarkStyle.color = theme.palette.error.main;
          break;
      }
    }
  }
  return {
    overflow: 'hidden',
    transition: 'width 150ms ease-in, min-width 150ms ease-in, color 150ms ease-in .2s',
    ...checkmarkStyle
  };
});

interface OutlineItemGridStyledProps {
  noPointer?: boolean;
}

export const OutlineItemGridStyled = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'noPointer'
})<OutlineItemGridStyledProps>(({ noPointer }) => ({
  cursor: noPointer ? 'intial' : 'pointer',
  position: 'relative',
  color: '#3D3D3D',
  fontWeight: 'bold',
  wordBreak: 'break-all'
}));

export const ItemOrderGridStyled = styled(Grid)(() => ({
  color: '#AEAECE'
}));

export const LockedIconGridStyled = styled(Grid)(() => ({}));

export const SectionChildrenContainer = styled(Box)(() => ({ paddingTop: '16px' }));
