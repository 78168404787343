import React from 'react';

import { Grid } from '@mui/material';
import { RoleplayBuilder, THQCourseCardGhost, THQTabPanel } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

import noCompletedCourses from '@/assets/images/no_completed_courses.png';
import satelite from '@/assets/images/satelite.png';
import EmptyCard from '@/components/common/emptyCard/EmptyCard';
import RoleplayListItem from '@/components/roleplays/roleplayList/roleplayListContentBuilder/RoleplayListItem';

interface RoleplayListTabPanelProps {
  roleplays?: RoleplayBuilder[];
  index: number;
  loading?: boolean;
  tabValue: number;
}

// TODO: use context to prevent prop drilling
const RoleplayListTabPanel: React.FC<RoleplayListTabPanelProps> = ({ roleplays, index, loading, tabValue }) => {
  const { t } = useTranslation();
  return (
    <THQTabPanel sx={{ overflowY: 'auto' }} value={tabValue} index={index}>
      <Grid sx={{ marginTop: 0, marginBottom: '64px' }} container spacing={2}>
        {loading && (
          <>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <THQCourseCardGhost />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <THQCourseCardGhost />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <THQCourseCardGhost />
            </Grid>
          </>
        )}
        {!loading && (!roleplays || roleplays?.length === 0) && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <EmptyCard
              description={index === 0 ? t('rp_no_assigned') : t('rp_assigned_info')}
              image={index === 0 ? satelite : noCompletedCourses}
            />
          </Grid>
        )}
        {!loading &&
          roleplays &&
          roleplays?.map((roleplay) => <RoleplayListItem roleplay={roleplay} key={roleplay.uuid} />)}
      </Grid>
    </THQTabPanel>
  );
};

export default RoleplayListTabPanel;
