import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQPrimaryButton } from '@trainhq/trainhq-client-core';

export const BurgerButtonStyled = styled(THQPrimaryButton)(() => ({
  borderRadius: 12,
  maxWidth: '48px',
  padding: 0
}));

export const PanelIconWrapperStyled = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  backgroundColor: theme.palette.common.pearl,
  borderRadius: '50%',
  display: 'flex',
  height: 32,
  justifyContent: 'center',
  width: 32,
  '& > svg': {
    color: theme.palette.common.smoke,
    display: 'flex',
    height: 18,
    width: 18,
    '& path': {
      strokeWidth: 2
    }
  }
}));
