import { Chip, Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { ChallengeType } from '@trainhq/trainhq-client-core';

interface ChipStyledProps {
  type: ChallengeType;
}

const colorsCalculator = (theme: Theme, type: ChallengeType): { backgroundColor: string; color: string } => {
  switch (type) {
    case ChallengeType.GOAL:
      return { backgroundColor: theme.palette.info.background, color: theme.palette.info.foreground };
    case ChallengeType.OBJECTION:
      return { backgroundColor: theme.palette.warning.background, color: theme.palette.warning.foreground };
  }
};

export const ChipStyled = styled(Chip, {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'type'
})<ChipStyledProps>(({ theme, type }) => ({
  borderRadius: 6,
  display: 'inline-flex',
  fontSize: 10,
  fontWeight: 700,
  height: 22,
  minWidth: 35,
  padding: 6,
  ...colorsCalculator(theme, type),
  '& > span': {
    padding: 0
  }
}));
