import React, { useCallback, useEffect, useState } from 'react';

import { LogAnalytics } from '@trainhq/trainhq-client-core';
import { useParams } from 'react-router';

import { useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useRolePlayAnalyticsService } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const StrictResponsesTabPanel: React.FC = () => {
  const { tabValue } = useSelectedRPInsightsTabContext();
  const rpAnalyticsService = useRolePlayAnalyticsService();
  const { rolePlayUuid } = useParams<{ rolePlayUuid: string }>();
  const user = useAuthenticatedUserContext();

  const [createdAtBefore, setCreatedAtBefore] = useState(0);
  const [createdAtAfter, setCreatedAtAfter] = useState(0);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);

  const [logResults, setLogResults] = useState([]);
  const [loadingResults, setLoadingResults] = useState(false);

  const [selectedEventTypes, setSelectedEventTypes] = useState<
    {
      label: string;
      values: string[];
      uuid: string;
    }[]
  >([]);

  const showValidResponseColumn = !!(
    selectedEventTypes.length === 0 ||
    selectedEventTypes?.find((item) => item.uuid === 'failure' || item.uuid === 'hint')
  );

  const handleSetCreatedAtAfter = (e) => {
    if (e === null) {
      setCreatedAtBefore(0);
    } else {
      const newTime = new Date(e).setHours(0, 0, 0, 0).valueOf();
      setCreatedAtBefore(newTime);
    }
  };

  const handleSetCreatedAtBefore = (e) => {
    if (e === null) {
      setCreatedAtAfter(0);
    } else {
      const newTime = new Date(e).setHours(0, 0, 0, 0).valueOf();
      setCreatedAtAfter(newTime);
    }
  };

  const handleSearchAnalyticLogs = useCallback(() => {
    const params = {
      ...(createdAtBefore !== undefined && createdAtBefore !== 0 && { startDate: createdAtBefore }),
      ...(createdAtAfter !== undefined && createdAtAfter !== 0 && { endDate: createdAtAfter }),
      eventTypes: selectedEventTypes.reduce((acc, item) => [...acc, ...item.values], []),
      roleplayUuid: !rolePlayUuid ? null : rolePlayUuid,
      selectedUsername: user.username
    };

    setLogResults([]);
    setLoadingResults(true);
    rpAnalyticsService.searchLogs(params).subscribe({
      next: (res) => {
        setLogResults(res);
        setLoadingResults(false);
      },
      error: () => {
        setLoadingResults(false);
      }
    });
  }, [createdAtBefore, createdAtAfter, selectedEventTypes, rolePlayUuid, user.username, rpAnalyticsService]);

  useEffect(() => {
    if (rolePlayUuid && tabValue === 2) {
      handleSearchAnalyticLogs();
    }
  }, [handleSearchAnalyticLogs, rolePlayUuid, tabValue]);

  const handleChangePage = (e, val) => {
    setPage(val);
  };

  const handleChangeRowsPerPage = (e) => {
    setRowsPerPage(e.target.value);
    setPage(0);
  };

  const skip = tabValue !== 2;

  return skip ? null : (
    <LogAnalytics
      createdAtAfter={createdAtAfter}
      createdAtBefore={createdAtBefore}
      focusedUsername={user.username}
      loadingResults={loadingResults}
      logResults={logResults}
      page={page}
      rowsPerPage={rowsPerPage}
      showValidResponseColumn={showValidResponseColumn}
      onChangeCreatedAtAfter={handleSetCreatedAtAfter}
      onChangeCreatedAtBefore={handleSetCreatedAtBefore}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
      selectedEventTypes={selectedEventTypes}
      setSelectedEventTypes={setSelectedEventTypes}
    />
  );
};

export default StrictResponsesTabPanel;
