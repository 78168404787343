import React, { useEffect, useRef, useState } from 'react';

import { ScormDriverIFrame } from '@/components/course/block/scormBlock/styles';
import { useCourseContext } from '@/components/course/courseDetails/context';
import { useScormService } from '@/hooks/scorm/useScormService';
import { LearnerBlock } from '@/models/learnerModels';

export interface ScormBlockProps {
  block: LearnerBlock;
  journeyUuid?: string;
}

export const ScormBlock: React.FC<ScormBlockProps> = ({ block, journeyUuid }) => {
  const scormService = useScormService();
  const { goToBlock } = useCourseContext();

  const [scormState, setScormState] = useState();

  const courseRef = useRef<HTMLIFrameElement>();

  useEffect(() => {
    setTimeout(() => {
      if (courseRef?.current && block?.learnerScormProgressData) {
        const payload = {
          ...block.learnerScormProgressData.state,
          action: 'initialData'
        };
        courseRef?.current?.contentWindow?.postMessage(JSON.stringify(payload), '*');
      }
    }, 1000);
  }, [block?.learnerScormProgressData]);

  window.onmessage = function (e) {
    let messageData = null;
    try {
      messageData = JSON.parse(e.data);
    } catch (e) {
      return;
    }

    switch (messageData.action) {
      case 'LMSSetValue':
        handleSetScormState(messageData);
        return console.log('LMSSetValue');
      case 'LMSGetValue':
        break;
      case 'LMSInitialize':
        return console.log('LMSInitialize');
      case 'LMSCommit':
        return console.log('LMSCommit');
      case 'LMSFinish':
        handleScormFinish();
        return console.log('LMSFinish');
      case 'LMSGetLastError':
        return console.log('LMSGetLastError');
      case 'LMSGetDiagnostic':
        return console.log('LMSGetDiagnostic');
      case 'LMSGetErrorString':
        return console.log('LMSGetErrorString');
    }
  };

  const handleScormFinish = () => {
    if (
      scormState &&
      ((scormState['cmi.core.lesson_status'] &&
        ['passed', 'completed'].includes(scormState['cmi.core.lesson_status'])) ||
        (scormState['cmi.success_status'] && scormState['cmi.success_status'] === 'passed'))
    ) {
      saveScormState(scormState, 'COMPLETED');
    } else {
      saveScormState(scormState);
    }
  };

  const handleSetScormState = (payload) => {
    setScormState({ ...payload });
  };

  const saveScormState = (payload: any, status?: string) => {
    scormService.saveScormData(block.uuid, status ?? 'IN_PROGRESS', payload, journeyUuid).subscribe({
      next: () => {
        goToBlock(block, true);
      }
    });
  };

  return (
    <>
      <ScormDriverIFrame
        key={block?.uuid}
        ref={courseRef}
        width="100%"
        height="400px"
        id="contentFrame"
        src={block?.learnerScormProgressData?.scormUrl}
      />
    </>
  );
};
