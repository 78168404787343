import React from 'react';

import { Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

import { RecordingButtonStyled, RecordingStudioGridStyled } from '@/components/common/quizRecordingStudio/styles';

interface QuizRecordingStudioProps {
  type: 'audio' | 'video';
  onRecordStart(): void;
}

const QuizRecordingStudio: React.FC<QuizRecordingStudioProps> = ({ type, onRecordStart }) => {
  const { t } = useTranslation();
  return (
    <RecordingStudioGridStyled alignItems="center" container rowSpacing={2}>
      <Grid alignSelf="end" item xs={12}>
        <Typography align="center">
          {t('record')} {type === 'audio' ? t('an_lower') : t('a_lower')} {type} {t('response_lower')}
        </Typography>
      </Grid>
      <Grid alignSelf="start" item xs={12}>
        <RecordingButtonStyled onClick={onRecordStart}>{t('start_recording')}</RecordingButtonStyled>
      </Grid>
    </RecordingStudioGridStyled>
  );
};

export default QuizRecordingStudio;
