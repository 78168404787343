import React from 'react';

import { CircularProgress, Grid } from '@mui/material';
import { ChallengeCompletionRateChart, Challenges, ErrorsByAttempt, SuccessRate } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import {
  useGetChallengeScores,
  useGetChallengesEventsCountStats,
  useGetErrorsByAttemptsStats,
  useGetRolePlayEventsCountStats
} from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const NaturalOverviewTabPanel: React.FC = () => {
  const { tabValue, selectedTypeOption } = useSelectedRPInsightsTabContext();
  const { t } = useTranslation();
  const { rolePlayUuid } = useParams<{ rolePlayUuid: string }>();
  const user = useAuthenticatedUserContext();
  const skip = tabValue !== 0;

  const successRateData = useGetRolePlayEventsCountStats(rolePlayUuid, selectedTypeOption, 'natural', skip);

  const errorsByAttemptsData = useGetErrorsByAttemptsStats(rolePlayUuid, selectedTypeOption, 'natural', skip);

  const { best, worst, loadingChallenges } = useGetChallengeScores(rolePlayUuid, selectedTypeOption, skip);

  const { challengesEventsCount, challengesEventsCountLoading } = useGetChallengesEventsCountStats(
    rolePlayUuid,
    selectedTypeOption,
    skip
  );

  const loading = loadingChallenges || errorsByAttemptsData?.errorsByAttemptsLoading || challengesEventsCountLoading;

  return (
    <>
      {loading ? (
        <Grid>
          <CircularProgress />
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid alignItems="center" container spacing={2}>
              <Grid item xs={12} lg={4}>
                <SuccessRate successRateData={successRateData} type="natural" />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ErrorsByAttempt
                  errorsByAttemptData={errorsByAttemptsData}
                  selectedUser={`${user.firstName} ${user.lastName}`}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <ChallengeCompletionRateChart challengesEventsCount={challengesEventsCount} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item md={6} xs={12}>
                <Challenges scores={best} title={t('challenges_best_performing')} />
              </Grid>
              <Grid item md={6} xs={12}>
                <Challenges scores={worst} title={t('challenges_worst_performing')} />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
    </>
  );
};

export default NaturalOverviewTabPanel;
