import {
  AuthenticationService,
  DataLoadType,
  Inject,
  Injectable,
  KeycloakAuthenticationService,
  PasswordPolicy,
  ResetPasswordData,
  User,
  VerifyData
} from '@trainhq/trainhq-client-core';
import { AuthError } from '@trainhq/trainhq-client-core/lib/trainhq-client-cloud/trainhq-client-auth/services/authentication';
import { defer, Observable, of } from 'rxjs';
import { mergeMap } from 'rxjs/operators';

import { USER_LEARNER_RESET_PASSWORD, USER_LEARNER_TOKEN_AUTH, USER_MANAGER_VERIFY } from '@/constants/api';
import { VALIDATE_SMS_TOKEN } from '@/constants/router';
import { REALM_CONFIGURATIONS } from '@/realmConfig/realm-config';
import { UserCloudService } from '@/services/repositoryData/cloud/userCloudService';
import { UserRepository } from '@/services/repositoryData/userRepository';
import { getRealmConfig, getSupportedDomain } from '@/utils/urlUtils';

export abstract class UserManagementService {
  abstract verify(verifyData: VerifyData): Observable<void>;

  abstract login(idpHint?: string, forceLogin?: boolean): Observable<User>;

  abstract logout(): Observable<any>;

  abstract getUser(cacheType?: DataLoadType): Observable<User>;

  abstract sendForgotLink(email: string): Observable<any>;

  abstract resetPassword(resetPasswordData: ResetPasswordData): Observable<any>;

  abstract resendVerificationLink(email: string): Observable<any>;

  abstract changePassword(oldPassword: string, newPassword: string): Observable<any>;

  abstract authenticateViaToken(token: string): Observable<any>;

  abstract validateToken(token: string): Observable<any>;

  abstract checkSso(): Observable<boolean>;

  abstract get isAuthenticated(): boolean;

  abstract get authError(): Observable<AuthError>;

  abstract getPasswordPolicy(verificationToken?: string): Observable<PasswordPolicy>;
}

@Injectable('userManagementService')
export class UserManagementServiceImpl implements UserManagementService {
  PROFILE_MODE = import.meta.env.MODE;

  @Inject('authenticationService')
  private authenticationService: AuthenticationService;
  @Inject('keycloakAuthenticationService')
  private keycloakService: KeycloakAuthenticationService;
  @Inject('userRepository')
  private userRepository: UserRepository;

  constructor(private userCloudService = new UserCloudService()) {}

  get isAuthenticated(): boolean {
    return this.keycloakService.isAuthenticated();
  }

  get authError(): Observable<AuthError> {
    return this.keycloakService.error;
  }

  checkSso(): Observable<boolean> {
    const currentRealmConfig = getRealmConfig();
    return this.keycloakService.init(currentRealmConfig, currentRealmConfig?.idpHint);
  }

  login = (idpHint?: string, forceLogin = false): Observable<User> => {
    return this.keycloakService.login(idpHint, forceLogin).pipe(
      mergeMap(() => {
        return this.getUser();
      })
    );
  };

  logout(): Observable<any> {
    return this.keycloakService.logout();
  }

  getPasswordPolicy(verificationToken?: string): Observable<PasswordPolicy> {
    if (verificationToken) {
      return this.userCloudService.getPasswordPolicyForVerification(verificationToken);
    } else {
      return this.userCloudService.getPasswordPolicy();
    }
  }

  verify(verifyData: VerifyData): Observable<void> {
    return this.keycloakService.verify(USER_MANAGER_VERIFY, verifyData);
  }

  getUser(cacheType?: DataLoadType): Observable<User> {
    return this.userRepository.get(undefined, cacheType);
  }

  changePassword(oldPassword: string, newPassword: string): Observable<any> {
    return this.userCloudService.changePassword({
      oldPassword: oldPassword,
      newPassword: newPassword
    });
  }

  resendVerificationLink(email: string): Observable<any> {
    return this.userCloudService.resendVerificationLink(email);
  }

  resetPassword(resetPasswordData: ResetPasswordData): Observable<any> {
    return this.authenticationService.resetPassword(USER_LEARNER_RESET_PASSWORD, resetPasswordData).pipe(
      mergeMap(() => {
        return this.getUser();
      })
    );
  }

  sendForgotLink(email: string): Observable<any> {
    return this.userCloudService.sendForgotLink(email);
  }

  authenticateViaToken(token: string): Observable<any> {
    return this.authenticationService.authenticateViaToken(USER_LEARNER_TOKEN_AUTH, token).pipe(
      mergeMap(() => {
        return this.getUser();
      })
    );
  }

  validateToken(token: string): Observable<any> {
    return this.authenticationService.validateToken(VALIDATE_SMS_TOKEN, token);
  }
}
