import {
  Chip,
  TableCellProps,
  Typography,
  TypographyProps,
  Container,
  Table,
  TableCell,
  TableProps,
  IconButton,
  IconButtonProps
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQPrimaryButton, ArrowDown } from '@trainhq/trainhq-client-core';

export const CourseListRootStyled = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  height: '100%'
}));

export const CourseListContainerStyled = styled(Container)(({ theme }) => ({
  background: theme.palette.background.default,
  height: '100%',
  paddingTop: 40
}));

export interface TableStyledProps {
  noBorder?: boolean;
  noMargin?: boolean;
}

export const TableStyled = styled(Table, { shouldForwardProp: (prop) => prop !== 'noBorder' && prop !== 'noMargin' })<
  TableProps & TableStyledProps
>(({ theme, noBorder, noMargin }) => ({
  background: theme.palette.background.paper,
  border: noBorder ? 'none' : `1px solid ${theme.palette.common.pearl}`,
  borderCollapse: 'separate',
  borderRadius: 10,
  ...(!noMargin && { marginTop: 20 })
}));

export const TableDownloadOverlay = styled('div')(() => ({
  position: 'absolute',
  height: '100%',
  width: '100%',
  boxShadow: 'inset 0px 0px 19px 3px rgba(255, 255, 255, 1)',
  zIndex: '50',
  backgroundColor: '#FFFFFFAB',
  '@keyframes pulse': {
    '0%': {
      backgroundColor: '#FFFFFFAB'
    },
    '50%': {
      backgroundColor: '#FFFFFFDE'
    },
    '100%': {
      backgroundColor: '#FFFFFFAB'
    }
  },
  animation: 'pulse 1.5s infinite'
}));
export const TableCellStyled = styled(TableCell)(() => ({
  paddingLeft: '24px',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap'
}));

export const ClearFiltersButtonStyled = styled(THQPrimaryButton)(({ theme }) => ({
  border: 'none',
  color: theme.palette.common.smoke,
  float: 'right'
}));

export const ProgressTableCell = styled(TableCell)<TableCellProps>(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  borderBottom: 'none',
  boxShadow: 'none',
  paddingLeft: '24px'
}));

interface LearningTimeDurationTextStyledProps extends TypographyProps {
  danger?: boolean;
}

export const LearningTimeDurationTextStyled = styled(Typography, {
  shouldForwardProp: (prop) => prop !== 'danger'
})<LearningTimeDurationTextStyledProps>(({ theme, danger }) => ({
  color: danger ? theme.palette.error.foreground : 'inherit'
}));

export const LearningTimeRequiredChipStyled = styled(Chip)(({ theme, label }) => ({
  backgroundColor: label === 'Yes' ? theme.palette.success.background : theme.palette.common.pearl,
  borderRadius: 6,
  color: label === 'Yes' ? theme.palette.success.foreground : theme.palette.common.smoke,
  fontSize: 10,
  fontWeight: 700
}));

export const PaginationButtonStyled = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  border: `1px solid ${theme.palette.common.pearl}`,
  borderRadius: '8px',
  padding: '4px',
  height: '32p',
  marginRight: '5px',
  width: '32px'
}));

export const NavArrowDownStyled = styled(ArrowDown)(() => ({
  height: 18,
  marginTop: -2,
  width: 18
}));
