import { useContext, useRef } from 'react';

import { NetworkServiceContext } from '@/context/appConfigContext';

export const ROOT_URL = import.meta.env.VITE_API_BASE_URL;
export const ML_URL = import.meta.env.VITE_ML_BASE_URL;
export const ML_URL_V2 = import.meta.env.VITE_ML_BASE_URL_V2;
export const SOCKET_URL = import.meta.env.VITE_COLLAB_SOCKET;

export const useLoadAppConfig = (): void => {
  const networkService = useNetworkService();

  const initialized = useRef(false);
  if (!initialized.current) {
    networkService.configure(ROOT_URL);
    initialized.current = true;
  }
};

export const useNetworkService = () => useContext(NetworkServiceContext);
