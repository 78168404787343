import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { detailsDrawerConfig } from '@/components/journey/drawer/JourneyDetailsDrawer';
import { itemDrawerConfig } from '@/components/journey/drawer/JourneyItemDrawer';

interface AnimatedGridStyledProps {
  detailsOpen?: boolean;
  itemOpen?: boolean;
}

export const AnimatedGridStyled = styled(Grid, {
  shouldForwardProp: (prop: PropertyKey) => prop !== 'detailsOpen' && prop !== 'itemOpen'
})<AnimatedGridStyledProps>(({ theme, detailsOpen, itemOpen }) => ({
  transition: theme.transitions.create(['padding'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  paddingLeft: 0,
  paddingRight: 0,
  ...(itemOpen && {
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    paddingRight: `${itemDrawerConfig.width}px`
  }),
  ...(detailsOpen && {
    transition: theme.transitions.create('padding', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen
    }),
    paddingLeft: `${detailsDrawerConfig.width}px`
  }),
  [theme.breakpoints.down('md')]: {
    paddingLeft: 0,
    paddingRight: 0
  }
}));

export const InnerBoxStyled = styled(Box)(() => ({
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  '&::after': {
    position: 'absolute',
    left: '50%',
    content: '" "',
    minWidth: '1px',
    borderRight: '1px dashed #AEAECE',
    top: '100px',
    height: 'calc(100% - 168px)'
  }
}));

export const ItemWrapperStyled = styled(Box)(() => ({
  marginBottom: 64,
  zIndex: 20
}));

export const GroupGridContainer = styled(Grid)(({ theme }) => ({
  backgroundColor: theme.palette.background.default
}));
