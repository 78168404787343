import React from 'react';

import { ReactComponent as Logo } from '@/assets/icons/logo.svg';
import { useThemeConfigContext } from '@/context/appConfigContext';

export interface ThemeConfigProps {
  width?: string;
  height?: string;
}

const ThemeLogo: React.FC<ThemeConfigProps> = ({ width = '144px', height = 'auto' }) => {
  const themeConfig = useThemeConfigContext();
  return (
    <>
      {themeConfig?.themeConfig?.logo ? (
        <img style={{ width: width, height: height }} alt={''} src={themeConfig.themeConfig.logo} />
      ) : (
        <Logo />
      )}
    </>
  );
};

export default ThemeLogo;
