import React from 'react';

import { Typography, Grid } from '@mui/material';
import {
  THQDialog,
  THQDialogTitle,
  THQDialogContent,
  THQDialogActions,
  THQDialogActionButton
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
export interface SkipWatchDialogProps {
  open: boolean;
  handleOnClose?: () => void;
  handleOnConfirm?: () => void;
}

const SkipWatchDialog: React.FC<SkipWatchDialogProps> = ({ open, handleOnClose, handleOnConfirm }) => {
  const { t } = useTranslation();
  return (
    <THQDialog fullWidth open={open} onClose={handleOnClose}>
      <THQDialogTitle>{t('skip_watching')}</THQDialogTitle>
      <THQDialogContent>
        <Typography>{t('skip_watching_message')}</Typography>
      </THQDialogContent>
      <THQDialogActions sx={{ borderTop: 'none' }}>
        <Grid container justifyContent="flex-end">
          <Grid item sm="auto" justifyContent="flex-end" xs={12}>
            <Grid container justifyContent="flex-end" columnGap={'8px'}>
              <Grid item>
                <THQDialogActionButton variant="standard" onClick={handleOnClose}>
                  <Typography
                    sx={(theme) => ({ fontSize: '14px', fontWeight: 700, color: theme.palette.common.midnight })}
                  >
                    {t('cancel')}
                  </Typography>
                </THQDialogActionButton>
              </Grid>
              <Grid item>
                <THQDialogActionButton color={'error'} onClick={handleOnConfirm}>
                  <Typography
                    sx={(theme) => ({ fontSize: '14px', fontWeight: 700, color: theme.palette.error.foreground })}
                  >
                    {t('skip_anyway')}
                  </Typography>
                </THQDialogActionButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </THQDialogActions>
    </THQDialog>
  );
};
export default SkipWatchDialog;
