import React, { createContext, useContext } from 'react';

import { FeatureFlagsProvider, User } from '@trainhq/trainhq-client-core';

const context = createContext<User>(null);
export const useAuthenticatedUserContext = () => useContext(context);
interface AuthUserProviderProps {
  children?: React.ReactNode;
  user: User;
}
const AuthUserProvider: React.FC<AuthUserProviderProps> = ({ children, user }) => {
  return (
    <context.Provider value={user}>
      <FeatureFlagsProvider features={user?.activeCompany?.features}>{children}</FeatureFlagsProvider>
    </context.Provider>
  );
};

export default AuthUserProvider;
