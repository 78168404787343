import React from 'react';

import { Fade } from '@mui/material';

import { ReactComponent as Mic } from '@/assets/icons/mic.svg';
import { ReactComponent as Stop } from '@/assets/icons/stop.svg';
import { RPButton, RPRecordButtonContainer, WindmillLoader } from '@/roleplay/styles';

export interface RecordingButtonProps {
  recognizedSpeech: string;
  recording: boolean;
  disabled?: boolean;
  startingRecord?: boolean;
  stopRecording: () => void;
  startRecording: () => void;
}

export const RecordingButton = React.forwardRef<HTMLButtonElement, RecordingButtonProps>(
  ({ recognizedSpeech, recording, disabled, startingRecord, stopRecording, startRecording }, ref) => {
    return (
      <RPRecordButtonContainer recognizedSpeech={recognizedSpeech} recording={recording}>
        <RPButton
          ref={ref}
          autoFocus
          disabled={disabled}
          recording={recording}
          variant="main"
          sx={{ position: 'relative' }}
          onClick={recording ? stopRecording : startRecording}
        >
          <Fade in={!startingRecord && !recording} unmountOnExit>
            <div style={{ position: 'absolute' }}>
              <Mic />
            </div>
          </Fade>
          <Fade in={!startingRecord && recording} unmountOnExit>
            <div style={{ position: 'absolute' }}>
              <Stop />
            </div>
          </Fade>
          <Fade in={startingRecord} unmountOnExit>
            <div>
              <WindmillLoader />
            </div>
          </Fade>
        </RPButton>
      </RPRecordButtonContainer>
    );
  }
);
