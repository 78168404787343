import { styled } from '@mui/material/styles';
import { THQPrimaryButton, XIcon } from '@trainhq/trainhq-client-core';

export const XButtonStyled = styled(THQPrimaryButton)(() => ({
  position: 'fixed',
  right: '40px',
  top: '40px'
}));

export const XIconStyled = styled(XIcon)(() => ({
  width: '16px',
  height: '16px'
}));
