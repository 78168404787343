import React from 'react';

import { ItemActionsStyled } from '@/components/journey/item/actions/styles';

interface ItemActionsProps {
  children?: React.ReactNode;
}

const ItemActions: React.FC<ItemActionsProps> = ({ children }) => {
  return <ItemActionsStyled>{children}</ItemActionsStyled>;
};

export default ItemActions;
