import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpBackend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import { Subscription } from 'rxjs';

const translationLoader = (translationService, options, url, payload, callback) => {
  if (!url) {
    url = 'en';
  }

  const subs = new Subscription();
  subs.add(
    translationService.getTranslations(url).subscribe({
      next: (data) => {
        callback(null, { status: 200, data });
      },
      error: (error) => {
        callback(error, { status: 500 });
      }
    })
  );

  return () => {
    subs.unsubscribe();
  };
};

const initializeI18n = (translationService) => {
  i18n
    .use(HttpBackend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      fallbackLng: 'en',
      load: 'languageOnly',
      debug: false,
      backend: {
        //loadPath: `${ROOT_URL}/api/translations/{{lng}}`
        loadPath: `{{lng}}`,
        request: (options, url, payload, callback) =>
          translationLoader(translationService, options, url, payload, callback)
      },
      interpolation: {
        escapeValue: false
      }
    });
  return i18n;
};

export default initializeI18n;
