import React, { useEffect, useState } from 'react';

import { Dialog } from '@mui/material';
import { THQBody } from '@trainhq/trainhq-client-core';

import { BorderLinearProgress, LPAnimatedEllipsis } from './styles';

interface RPLoaderProps {
  visible?: boolean;
  loaderMessages: string[];
  totalLength: number;
}

export const RPLoader: React.FC<RPLoaderProps> = ({ visible, loaderMessages, totalLength = 25 }) => {
  const [currentProgress, setCurrentProgress] = useState(0);
  const [currentMessage, setCurrentMessage] = useState(0);

  useEffect(() => {
    if (visible) {
      const interval = setInterval(() => {
        // setCurrentMessage((curMes) => (curMes === loaderMessages.length - 1 ? loaderMessages.length - 1 : curMes + 1));
        setCurrentMessage((curMes) => (curMes + 1) % loaderMessages.length);
        setCurrentProgress((current) => {
          if (current >= 100) {
            clearInterval(interval);
          }
          const newProgress = Math.round(current + Math.random() * ((100 / totalLength) * 2));
          return newProgress >= 100 ? 100 : newProgress;
        });
      }, 1200);

      return () => {
        if (interval) {
          clearInterval(interval);
          setCurrentProgress(0);
          setCurrentMessage(0);
        }
      };
    }
  }, [loaderMessages.length, totalLength, visible]);

  return (
    <Dialog fullScreen open={visible}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          width: '100%',
          height: '100%',
          backgroundColor: 'eggshell'
        }}
      >
        <div>
          <THQBody>{currentProgress}%</THQBody>
        </div>
        <BorderLinearProgress
          sx={{ marginTop: '16px', marginBottom: '24px', minWidth: '20%', maxWidth: '500px', width: '100%' }}
          variant="determinate"
          value={currentProgress}
        />
        <div>
          <LPAnimatedEllipsis>{loaderMessages[currentMessage]}</LPAnimatedEllipsis>
        </div>
      </div>
    </Dialog>
  );
};
