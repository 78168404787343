import React, { useContext, useEffect, useMemo, useState } from 'react';

import {
  FeaturePermission,
  Journey,
  PageRequest,
  PaginationCoordinator,
  useFeaturesEnabled,
  useGetPagination
} from '@trainhq/trainhq-client-core';
import { Subscription } from 'rxjs';

import { JourneyServiceContext } from '@/context/journey';

export const useJourneyService = () => useContext(JourneyServiceContext);

export const useGetJourney = (
  uuid: string
): {
  loading: boolean;
  journey: Journey;
  notFound: boolean;
  setJourney: React.Dispatch<React.SetStateAction<Journey>>;
} => {
  const journeyService = useJourneyService();
  const journeyFeatureEnabled = useFeaturesEnabled([FeaturePermission.JOURNEY]);

  const [journey, setJourney] = useState<Journey>();
  const [loading, setLoading] = useState<boolean>(true);
  const [notFound, setNotFound] = useState<boolean>(false);

  useEffect(() => {
    const sub = new Subscription();
    if (uuid && journeyFeatureEnabled) {
      sub.add(
        journeyService.getJourney(uuid).subscribe({
          next: (journey) => {
            setLoading(false);
            setNotFound(false);
            setJourney({
              ...journey,
              journeyItems: journey.journeyItems.sort((a, b) => a.itemOrder - b.itemOrder)
            });
          },
          error: (err) => {
            if (err.status === 400 || err.status === 403) {
              setNotFound(true);
            }
          }
        })
      );
    }
    return () => sub.unsubscribe();
  }, [journeyService, journeyFeatureEnabled, setJourney, uuid]);
  return useMemo(() => ({ loading, journey, notFound, setJourney }), [journey, loading, notFound]);
};

export const useGetJourneysPage = (pageRequest: PageRequest): PaginationCoordinator<Journey> => {
  const journeyService = useJourneyService();
  return useGetPagination<Journey>(journeyService.page, pageRequest);
};
