import { LinearProgress, linearProgressClasses } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQBody } from '@trainhq/trainhq-client-core';

export const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: '6px',
  marginBottom: '8px',
  borderRadius: '32px',
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor: theme.palette.common.pearl
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.primary.main
  }
}));

export const LPAnimatedEllipsis = styled(THQBody)(({ theme }) => ({
  color: theme.palette.common.midnight,
  fontSize: '14px',
  '&:after': {
    overflow: 'hidden',
    display: 'inline-block',
    verticalAlign: 'bottom',
    '@keyframes ellipsis': {
      to: {
        width: '14px',
        marginRight: '0'
      }
    },
    animation: 'ellipsis steps(4, end) 900ms infinite',
    content: '"\\2026"',
    width: '0px',
    marginRight: '14px'
  }
}));
