import { Grid, styled } from '@mui/material';

interface AnswerRootGridStyledProps {
  animate?: boolean | string;
  selected?: boolean | string;
  error?: boolean;
  success?: boolean;
  isPressed?: boolean;
  committingAnswer?: boolean;
}

export const AnswerContentGridStyled = styled(Grid)(({ theme }) => ({
  margin: '24px',
  color: theme.palette.common.midnight
}));

interface ExplanationContentGridStyledProps {
  show: boolean;
}
export const ExplanationContentGridStyled = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'show'
})<ExplanationContentGridStyledProps>(({ theme, show }) => ({
  overflow: show ? 'auto' : 'hidden',
  maxHeight: show ? '250px' : '0',
  margin: show ? '24px' : '0',
  color: theme.palette.common.smoke,
  transition: 'all .2s ease-out'
}));

export const AnswerRootGridStyled = styled(Grid, {
  shouldForwardProp: (prop) =>
    prop !== 'success' &&
    prop !== 'error' &&
    prop !== 'selected' &&
    prop !== 'animate' &&
    prop !== 'isPressed' &&
    prop !== 'committingAnswer'
})<AnswerRootGridStyledProps>(({ committingAnswer, theme, animate, selected, error, success, isPressed }) => ({
  height: '100%',
  backgroundColor: theme.palette.common.zima,
  border: `1px solid ${theme.palette.common.pearl}`,
  borderRadius: '12px',
  maxWidth: '560px',
  margin: 'auto',
  transition: 'all 100ms ease-out, box-shadow 250ms ease-out',
  width: '100%',
  ...(animate && {
    cursor: 'pointer',
    transition: 'transform 100ms ease-out, box-shadow 250ms ease-out',
    '&:hover': {
      backgroundColor: '#FFFFFF',
      border: '1px solid #ECF1F4',
      boxShadow: '0px 4px 24px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05)'
    }
  }),
  ...(selected && {
    '&&': {
      cursor: 'pointer',
      //   transform: 'scale(1.05)',
      transition: 'transform 100ms ease-out, box-shadow 250ms ease-out',
      border: `1px solid ${theme.palette.primary.main}`,
      boxShadow: '0px 0px 0px 4px rgba(35, 0, 252, 0.15)'
    }
  }),
  ...(isPressed && {
    '&&': {
      backgroundColor: theme.palette.common.pow
    }
  }),
  ...(error &&
    selected && {
      '&&': {
        cursor: 'pointer',
        transition: 'all 100ms ease-out, box-shadow 250ms ease-out',
        border: theme.adLib.adLibInput.error.border,
        boxShadow: theme.adLib.adLibInput.error['&:focus'].boxShadow
      }
    }),
  ...(error &&
    !selected && {
      '&&': {
        backgroundColor: theme.palette.common.pow,
        cursor: 'pointer',
        transition: 'all 400ms ease-out, box-shadow 250ms ease-out'
      }
    }),
  ...(success && {
    '&&': {
      cursor: 'pointer',
      transition: 'transform 100ms ease-out, box-shadow 250ms ease-out',
      border: `2px solid ${theme.palette.success.foreground}`,
      boxShadow: '0px 0px 0px 4px #23E39E26'
    }
  }),
  '@keyframes borderchange': {
    '0%': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 0px 4px rgba(35, 0, 252, 0.15)'
    },
    '50%': {
      border: '1px solid #ECF1F4',
      boxShadow: '0px 4px 24px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05)'
    },
    '100%': {
      borderColor: theme.palette.primary.main,
      boxShadow: '0px 0px 0px 4px rgba(35, 0, 252, 0.15)'
    }
  },
  ...(committingAnswer &&
    selected && {
      animation: 'borderchange 2s infinite'
    }),
  [theme.breakpoints.down('md')]: {
    borderRadius: 12,
    maxWidth: '100%'
  }
}));
