import React from 'react';

import { CircularProgress, Grid, Typography } from '@mui/material';
import { EmptyAvatar } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { useSelectedRPInsightsTabContext } from '@/components/insights/role-play/context';
import AnalyticsPair from '@/components/insights/role-play/details/analyticsPair/AnalyticsPair';
import { BoxStyled, LineBox } from '@/components/insights/role-play/details/styles';
import { useAuthenticatedUserContext } from '@/context/providers/AuthUserProvider';
import { useGetErrorPerPairPlain } from '@/hooks/roleplay/insights/useRolePlayAnalyticsService';

const StrictDetailsTabPanel: React.FC = () => {
  const { t } = useTranslation();
  const { rolePlayUuid } = useParams<{ rolePlayUuid: string }>();
  const { tabValue, selectedTypeOption } = useSelectedRPInsightsTabContext();
  const user = useAuthenticatedUserContext();

  const skip = tabValue !== 1;
  const { data: eppData, loading } = useGetErrorPerPairPlain(rolePlayUuid, selectedTypeOption, skip);

  const dataExists = eppData?.user?.length > 0;

  return skip ? null : (
    <>
      {loading ? (
        <Grid sx={{ marginTop: '16px' }}>
          <CircularProgress />
        </Grid>
      ) : (
        <>
          {dataExists ? (
            <BoxStyled>
              {eppData?.user.map((pair, i) => (
                <React.Fragment key={`${pair.uuid}${i}`}>
                  <AnalyticsPair
                    botAvatar={pair.botImage || EmptyAvatar}
                    botContent={pair.botInput}
                    compareUserAttempts={eppData?.comparingUser?.[i]?.attempts}
                    compareUserErrorCount={eppData?.comparingUser?.[i]?.errorCount}
                    globalAttempts={eppData?.global?.[i]?.attempts}
                    globalErrorCount={eppData?.global?.[i]?.errorCount}
                    isComparing={!!eppData?.comparingUser}
                    selectedLearner={user}
                    tabValue={tabValue}
                    userAttempts={eppData?.user?.[i]?.attempts}
                    userAvatar={pair.userImage || EmptyAvatar}
                    userContent={pair.learnerInput}
                    userErrorCount={eppData?.user?.[i]?.errorCount}
                  />
                  {i < eppData?.user?.length - 1 && <LineBox />}
                </React.Fragment>
              ))}
            </BoxStyled>
          ) : (
            <Typography>{t('no_data_available')}</Typography>
          )}
        </>
      )}
    </>
  );
};

export default StrictDetailsTabPanel;
