import { ContentItemType, PageRequest, SearchCriteria } from '@trainhq/trainhq-client-core';

export const getPageCriteria = (type: ContentItemType, directoryId?: string) => {
  const criteria: SearchCriteria[] = [
    { key: 'contentItemType', value: type, relationType: 'AND', operation: 'equals' }
  ];
  if (directoryId) {
    criteria.push({ key: 'parentContentItem.uuid', value: directoryId, relationType: 'AND', operation: 'equals' });
  }
  return criteria;
};

export const getSearchCriteria = (directoryUuid: string, value: string, page: number): PageRequest => ({
  page,
  size: 10,
  sort: [{ column: 'name', sort: 'ASC' }],
  criterias: [
    { key: 'name', value, operation: 'contains', relationType: 'OR' },
    { key: 'tags.name', value, operation: 'contains', relationType: 'OR' },
    { key: 'courseBuilder.description', value, operation: 'contains', relationType: 'OR' },
    { key: 'courseBuilder.tags.name', value, operation: 'contains', relationType: 'OR' },
    { key: 'path', value: `${directoryUuid}/`, relationType: 'AND', operation: 'contains' }
  ]
});
