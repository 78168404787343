import React from 'react';

import { MainLayout } from '@trainhq/trainhq-client-core';
import { Outlet } from 'react-router-dom';

import DashboardSidebar from '@/components/common/sidebar/DashboardSidebar';
import LibraryActionsDialogsWrapper from '@/components/library/actions/dialogWrapper/LibraryActionsDialogsWrapper';

const SidebarLayout: React.FC = () => {
  return (
    <LibraryActionsDialogsWrapper>
      <MainLayout sidebarContent={<DashboardSidebar />}>
        <Outlet />
      </MainLayout>
    </LibraryActionsDialogsWrapper>
  );
};

export default SidebarLayout;
