import React, { useState } from 'react';
import 'microsoft-cognitiveservices-speech-sdk/distrib/browser/microsoft.cognitiveservices.speech.sdk.bundle';

import { CssBaseline } from '@mui/material';
import { ChangePasswordDialog, THQSnackbar } from '@trainhq/trainhq-client-core';
import { Outlet } from 'react-router-dom';

import BrowserNotSupportedComponent from '@/components/browserNotSupported/BrowserNotSupportedComponent';
import SavingOverlay from '@/components/overlays/savingOverlay/SavingOverlay';
import { LoadingOverlayContext } from '@/context/loadingOverlay';
import { useLoadAppConfig } from '@/hooks/config/useAppConfig';
import { getUserEnv } from '@/utils/userAgentUtils';

/**
 * @description Entry level component, contains HOCs, wrappers, providers etc.
 */
const App: React.FC = () => {
  // TODO: upgrade react and react router, this old router has issues with nested routes and passing props to them
  // TODO: extract common type definitions and models to core
  // TODO: move all copied common to core
  // TODO: fix all warnings for dependency injection (non-trivial, some were left that way cause simply adding recommended deps breaks the functionality. Have to figure out a better way)
  // TODO: Rework load app config once we have new improved DI framework in core
  // TODO: Don't use this app through iFrame for preview mode, instead extract common that are required both in manager and learner,
  // then create UI in manager app. Remove all logic and flags from CourseDetails and it's sub-common that is related to preview mode
  // TODO: eventually switch to router's action and loaders and replace useNavigate() with redirect() where needed

  useLoadAppConfig();
  // TODO: rework saving overlay to work like other dialogs (hooks)
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState('');
  const userEnv = getUserEnv(window);

  return (
    <>
      {/* TODO: move Loading overlay context from Manager to Core and use it from there  */}
      <LoadingOverlayContext.Provider value={{ loading, setLoading, setLoadingText }}>
        <CssBaseline />
        <THQSnackbar>
          {userEnv.browser !== 'chrome' && userEnv.browser !== 'ff' && userEnv.browser !== 'edge' ? (
            <BrowserNotSupportedComponent />
          ) : (
            <>
              <SavingOverlay loading={loading} loadingText={loadingText} setLoadingText={setLoadingText} />
              <Outlet />
            </>
          )}
        </THQSnackbar>
      </LoadingOverlayContext.Provider>
    </>
  );
};

export default App;
