import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

import { ReactComponent as Language } from '@/assets/icons/language.svg';
import { ReactComponent as Lock } from '@/assets/icons/lock.svg';

export const SidebarContentRootGridStyled = styled(Grid)(() => ({
  height: '100%'
}));

export const LogoGridStyled = styled(Grid)(() => ({
  marginBottom: 20
}));

export const BlueRectStyled = styled('div')(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: 2,
  width: 16,
  height: 16
}));

export const ListGridStyled = styled(Grid)(() => ({
  flexGrow: 1
}));

export const LogoutGridContainerStyled = styled(Grid)(() => ({
  cursor: 'pointer'
}));

export const LogoutIconGridStyled = styled(Grid)(() => ({
  marginTop: 5
}));

export const CurrentUserContainer = styled('div')(() => ({
  paddingLeft: '16px',
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  width: '100%',
  justifyContent: 'space-between'
}));

export const LockIconStyled = styled(Lock)(({ theme }) => ({
  color: `${theme.palette.common.steel}!important`
}));

export const LanguageIconStyled = styled(Language)(({ theme }) => ({
  color: `${theme.palette.common.steel}!important`
}));
