import { Theme } from '@mui/material';
import { styled } from '@mui/material/styles';
import { EnrollIcon, DownloadIcon, LinkIcon, THQPrimaryButton } from '@trainhq/trainhq-client-core';
export const iconsStyle = (theme: Theme, onlyDesktopVariant?: boolean) => ({
  height: 16,
  width: 16,
  ...(!onlyDesktopVariant && {
    [theme.breakpoints.down('sm')]: {
      marginRight: 8
    }
  })
});

interface IconStyledProps {
  onlyDesktopVariant?: boolean;
}

export const ShareIconStyled = styled(LinkIcon, {
  shouldForwardProp: (prop) => prop !== 'onlyDesktopVariant'
})<IconStyledProps>(({ theme, onlyDesktopVariant = false }) => iconsStyle(theme, onlyDesktopVariant));
export const DownloadIconStyled = styled(DownloadIcon, {
  shouldForwardProp: (prop) => prop !== 'onlyDesktopVariant'
})<IconStyledProps>(({ theme, onlyDesktopVariant = false }) => iconsStyle(theme, onlyDesktopVariant));

export const ButtonStyled = styled(THQPrimaryButton)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '100%'
  }
}));

export const EnrollIconStyled = styled(EnrollIcon, {
  shouldForwardProp: (prop) => prop !== 'onlyDesktopVariant'
})<IconStyledProps>(({ theme, onlyDesktopVariant = false }) => ({
  ...iconsStyle(theme, onlyDesktopVariant),
  marginRight: '8px'
}));
