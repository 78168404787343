import { styled } from '@mui/material/styles';
import { THQPrimaryButton } from '@trainhq/trainhq-client-core';

interface RPContainerProps {
  accentedBackground?: boolean;
}

export const RPContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'accentedBackground'
})<RPContainerProps>(({ theme, accentedBackground }) => ({
  paddingTop: '16px',
  height: '100%',
  width: '100%',
  backgroundColor: accentedBackground ? theme.palette.primary.brand01 : theme.palette.common.pow,
  transition: 'background-color .3s ease-out'
}));

export interface RPChatContainerProps {
  fadeChat?: boolean;
}

export const RPChatContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'fadeChat'
})<RPChatContainerProps>(({ fadeChat }) => ({
  paddingTop: '32px',
  paddingBottom: '32px',
  display: 'flex',
  flexDirection: 'column-reverse',
  minHeight: '300px',
  width: '100%',
  maxWidth: '520px',
  maxHeight: 'calc(100vh - 200px)',
  overflow: 'auto',
  margin: 'auto',
  padding: '0 16px',
  transition: 'opacity .3s ease-in',
  opacity: fadeChat ? 0 : 1
}));
export interface RPActionBarProps {
  recognizedSpeech?: string;
  recording?: boolean;
}

export const RPRecordedActionsBar = styled('div', {
  shouldForwardProp: (prop) => prop !== 'recording' && prop !== 'recognizedSpeech'
})<RPActionBarProps>(({ recognizedSpeech, recording }) => ({
  position: 'fixed',
  bottom: recognizedSpeech && !recording ? '80px' : '-140px',
  opacity: recognizedSpeech && !recording ? 1 : 0,
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'flex',
  justifyContent: 'center',
  gap: '12px',
  transition: 'bottom .5s ease-in, opacity .5s ease-in'
}));

export const RPRecordButtonContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'recording' && prop !== 'recognizedSpeech'
})<RPActionBarProps>(({ recognizedSpeech, recording }) => ({
  position: 'fixed',
  bottom: recognizedSpeech && !recording ? '-180px' : '40px',
  opacity: recognizedSpeech && !recording ? 0 : 1,
  left: '50%',
  transform: 'translateX(-50%)',
  marginTop: '48px',
  display: 'flex',
  marginLeft: 'auto',
  marginRight: 'auto',
  justifyContent: 'center',
  transition: 'bottom .5s ease-in, opacity .5s ease-in'
}));

interface RPButtonProps {
  recording?: boolean;
}
export const RPButton = styled(THQPrimaryButton, { shouldForwardProp: (prop) => prop !== 'recording' })<RPButtonProps>(
  ({ theme, recording = false }) => ({
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    minHeight: '56px',
    maxHeight: '56px',
    minWidth: '56px',
    maxWidth: '56px',
    position: 'relative',
    margin: '32px',
    '&::before': {
      zIndex: -1,
      content: '" "',
      backgroundColor: 'rgba(255,255,255,1)',
      position: 'absolute',
      width: '200%',
      height: '200%',
      borderRadius: '50%',
      ...(recording && {
        '@keyframes recording': {
          '0%': {
            transform: 'scale(1)'
          },
          '20%': {
            transform: 'scale(1.5)'
          },
          '50%': {
            transform: 'scale(1)'
          },
          '70%': {
            transform: 'scale(0.9)'
          },
          '100%': {
            transform: 'scale(1)'
          }
        },
        animation: 'recording 5s ease-out',
        animationIterationCount: 'infinite'
      })
    },
    '&::after': {
      zIndex: -2,
      content: '" "',
      backgroundColor: 'rgba(255,255,255,0.5)',
      position: 'absolute',
      width: '300%',
      height: '300%',
      borderRadius: '50%',
      ...(recording && {
        '@keyframes pulsing': {
          '0%': {
            transform: 'scale(1)'
          },
          '20%': {
            transform: 'scale(0.7)'
          },
          '50%': {
            transform: 'scale(1)'
          },
          '70%': {
            transform: 'scale(1.1)'
          },
          '100%': {
            transform: 'scale(1)'
          }
        },
        animation: 'pulsing 5s ease-out',
        animationIterationCount: 'infinite'
      })
    }
  })
);

export const WindmillLoader = styled('div')(({ theme }) => ({
  position: 'relative',
  top: '-10px',
  width: '10px',
  height: '10px',
  borderRadius: '5px',
  backgroundColor: theme.palette.common.zima,
  color: theme.palette.common.zima,
  transformOrigin: '5px 15px',
  opacity: 0.85,
  animation: 'dot-windmill 3s infinite linear',
  '&::before': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    left: '-8.66254px',
    top: '15px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.zima,
    color: theme.palette.common.zima
  },
  '&::after': {
    content: '""',
    display: 'inline-block',
    position: 'absolute',
    left: '8.66254px',
    top: '15px',
    width: '10px',
    height: '10px',
    borderRadius: '5px',
    backgroundColor: theme.palette.common.zima,
    color: theme.palette.common.zima
  },
  '@keyframes dot-windmill': {
    '0%': {
      transform: 'rotateZ(0deg) translate3d(0, 0, 0)'
    },
    '100%': {
      transform: 'rotateZ(720deg) translate3d(0, 0, 0)'
    }
  }
}));
