import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Grid, Typography } from '@mui/material';
import {
  ContentItem,
  ContentItemType,
  LibraryContentItemDetails,
  LibraryContentItemPreview,
  LibraryCourseOverview,
  LibraryCourseOverviewHeader,
  THQActions,
  THQCloseButton,
  THQDialog,
  THQDialogActions,
  THQDialogContent,
  THQDialogTitle,
  THQMainPageContainer,
  THQPrimaryButton,
  useTHQSnackbar
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router';
import { useLocation, useSearchParams } from 'react-router-dom';

import { EnrollIconStyled, ShareIconStyled } from '@/components/library/actions/styles';
import { useActions } from '@/components/library/actions/useActions';
import { COURSE_DETAILS_STRING, LIBRARY, LIBRARY_OVERVIEW_STRING } from '@/constants/router';
import { useContentLibraryService } from '@/hooks/contentLibrary/useContentLibraryService';
import { useCourseService } from '@/hooks/course/useCourseService';

export const LibraryContentOverview: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const routerParams = useParams();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const contentLibService = useContentLibraryService();
  const courseService = useCourseService();
  const routerParamsArray = useMemo(() => routerParams?.['*'].split('/'), [routerParams]);
  const contentItemUuid = routerParamsArray[routerParamsArray.length - 1];
  const filteredParams = useMemo(
    () => routerParamsArray.filter((item, index) => index !== routerParamsArray.length - 1),
    [routerParamsArray]
  );

  const libraryPath = useMemo(
    () => `${LIBRARY}${filteredParams.length > 0 ? '/' : ''}${filteredParams.join('/')}`,
    [filteredParams]
  );
  const goBack: string = location?.state?.backLink || searchParams.get('libraryBackLink');

  const [contentItem, setContentItem] = useState<ContentItem>(null);
  const [enrollmentDialog, setEnrollmentDialog] = useState(false);
  const snackbar = useTHQSnackbar();

  useEffect(() => {
    contentLibService.get(contentItemUuid, 'cloud').subscribe((res) => {
      setContentItem(res);
    });
  }, [contentItemUuid, contentLibService]);

  const handleBack = useCallback(() => {
    navigate(goBack || libraryPath);
    setContentItem(undefined);
  }, [goBack, libraryPath, navigate]);

  const handlePreviewCourse = useCallback(() => {
    navigate(
      `${COURSE_DETAILS_STRING}/${contentItem?.courseBuilder
        ?.uuid}?library=1&preview=1&returnTo=/${LIBRARY_OVERVIEW_STRING}/${routerParams?.['*']}${
        goBack ? `&libraryBackLink=${goBack}` : ''
      }`
    );
  }, [contentItem?.courseBuilder?.uuid, goBack, navigate, routerParams]);

  const actions = useActions(contentItem, () => {}, window.location.href, true, true);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href).then(() => {
      snackbar(t('link_copied'));
    });
  };

  const handleOnClose = () => {
    setEnrollmentDialog(false);
  };

  const handleOpenEnrollmentDialog = () => {
    setEnrollmentDialog(true);
  };

  const handleRequestEnrollment = () => {
    if (contentItem?.courseBuilder?.uuid) {
      courseService.enrollToCourse(contentItem?.uuid).subscribe(() => {
        setEnrollmentDialog(false);
        snackbar(t('enrollment_request_sent'));
      });
    }
  };

  return (
    <>
      {contentItem ? (
        <>
          <THQMainPageContainer>
            {contentItem.contentItemType === ContentItemType.COURSE ? (
              <>
                <LibraryCourseOverviewHeader
                  learnerVariant
                  handleBack={handleBack}
                  previewCourse={handlePreviewCourse}
                  contentItem={contentItem}
                  headerActons={
                    <>
                      <THQPrimaryButton onClick={handleCopyLink}>
                        <ShareIconStyled sx={{ marginRight: '8px' }} /> {t('copy_link')}
                      </THQPrimaryButton>
                      <THQPrimaryButton onClick={handleOpenEnrollmentDialog}>
                        <EnrollIconStyled />
                        {t('request_enrollment')}
                      </THQPrimaryButton>
                    </>
                  }
                  mobileActionItems={[
                    {
                      text: t('copy_link'),
                      action: handleCopyLink,
                      icon: (
                        <ShareIconStyled
                          sx={(theme) => ({
                            '& > path': {
                              stroke: theme.palette.common.midnight
                            },
                            maxHeight: '16px',
                            maxWidth: '16px'
                          })}
                        />
                      )
                    },
                    {
                      text: t('request_enrollment'),
                      action: handleOpenEnrollmentDialog,
                      icon: (
                        <EnrollIconStyled
                          sx={(theme) => ({
                            '&&': {
                              '& > path': {
                                stroke: theme.palette.common.midnight
                              },
                              maxHeight: '16px',
                              maxWidth: '16px'
                            }
                          })}
                        />
                      )
                    }
                  ]}
                />
                <Grid container sx={{ marginTop: '84px' }}>
                  <LibraryCourseOverview course={contentItem} />
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  container
                  sx={(theme) => ({
                    marginTop: '64px',
                    paddingBottom: '100px',
                    marginBottom: 'auto',
                    [theme.breakpoints.down('sm')]: { marginTop: '86px' }
                  })}
                  justifyContent={'space-between'}
                >
                  <Grid
                    item
                    xs={12}
                    sm={7}
                    sx={(theme) => ({
                      paddingRight: '16px',
                      [theme.breakpoints.down('sm')]: { paddingRight: 0 }
                    })}
                  >
                    <THQCloseButton
                      sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                          right: '24px',
                          top: '24px'
                        }
                      })}
                      onClose={handleBack}
                    />
                    <LibraryContentItemPreview contentItem={contentItem} />
                  </Grid>
                  <Grid
                    sx={(theme) => ({
                      width: '100%',
                      [theme.breakpoints.down('sm')]: { marginTop: '40px' }
                    })}
                    item
                    xs={'auto'}
                    sm={5}
                    md={4}
                  >
                    <LibraryContentItemDetails contentItem={contentItem} />
                  </Grid>
                </Grid>
              </>
            )}
          </THQMainPageContainer>
          {contentItem.contentItemType !== ContentItemType.COURSE && (
            <THQActions onlyDesktopVariant fullWidth actions={actions} open={true} onClose={undefined} />
          )}
          <THQDialog fullWidth open={enrollmentDialog} onClose={handleOnClose}>
            <THQDialogTitle>{t('library_request_enrolment')}</THQDialogTitle>
            <THQDialogContent>
              <Typography>{t('library_request_enrolment_content')}</Typography>
            </THQDialogContent>
            <THQDialogActions>
              <THQPrimaryButton onClick={handleOnClose}>{t('cancel')}</THQPrimaryButton>
              <THQPrimaryButton variant="main" onClick={handleRequestEnrollment}>
                {t('send_request')}
              </THQPrimaryButton>
            </THQDialogActions>
          </THQDialog>
        </>
      ) : (
        <>{t('loading_lower')}</>
      )}
    </>
  );
};
