import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { THQCard } from '@trainhq/trainhq-client-core';

// TODO: extract to 'common' because it's used in Home.tsx and Feedback.tsx
export const SubsectionHeadline = styled(Typography)(() => ({
  fontWeight: '700',
  fontSize: '18px',
  lineHeight: '125%'
}));

export const CourseListRootStyled = styled('div')(({ theme }) => ({
  background: theme.palette.background.default,
  height: '100%'
}));

export const DashboardSummaryCard = styled(THQCard)(() => ({
  borderRadius: 24,
  padding: 24
}));
