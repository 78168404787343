import { MediaFile } from '@trainhq/trainhq-client-core';

export enum RecordingSpeaker {
  LEARNER = 'LEARNER',
  BOT = 'BOT'
}

export interface RolePlaySessionDto {
  uuid: string;
}

export interface RecodingUploadDto {
  sessionUuid: string;
  speaker: RecordingSpeaker;
  mediaFile: MediaFile;
}
