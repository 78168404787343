import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const BoxStyled = styled(Box)(() => ({
  maxWidth: 541
}));

export const LineBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.steel,
  display: 'block',
  marginLeft: 'auto',
  marginRight: 'auto',
  height: 30,
  width: 1
}));
