import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

export const DashboardSummarySubtitle = styled(Typography)(({ theme }) => ({
  userSelect: 'none',
  fontSize: '12px',
  color: theme.palette.common.smoke
}));

export const CourseNameLink = styled(Typography)(({ theme }) => ({
  marginBottom: '12px',
  cursor: 'pointer',
  color: theme.palette.common.silver,
  fontSize: '12px',
  lineHeight: '21px',
  transition: 'color .1s ease-out',
  '&:hover': {
    transition: 'color .2s ease-out',
    color: theme.palette.common.smoke
  }
}));

export const DashboardSummaryTitle = styled('div')(({ theme }) => ({
  maxWidth: '100%',
  '& > p': {
    userSelect: 'none',
    fontSize: '12px',
    color: theme.palette.common.smoke,
    fontWeight: 600,
    maxWidth: '100%'
  }
}));

interface DashboardSummaryContainerProps {
  isPressed?: boolean;
}
export const DashboardSummaryContainer = styled('div', {
  shouldForwardProp: (prop) => prop !== 'isPressed'
})<DashboardSummaryContainerProps>(({ theme, isPressed }) => ({
  position: 'relative',
  zIndex: '2',
  padding: '24px',
  marginBottom: '16px',
  borderRadius: '24px',
  border: `1px solid ${theme.palette.grey[110]}`,
  background: theme.palette.background.paper,
  transition: 'box-shadow 250ms ease-out',
  '&:hover': {
    boxShadow: '0px 4px 24px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05)'
  },
  ...(isPressed && {
    '&&': {
      backgroundColor: theme.palette.common.pow
    }
  })
}));
