import { DataLoadType, Inject, Injectable, Pageable, PageRequest } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { LearnerBlock, LearnerContentBuilder, LearnerCourse } from '@/models/learnerModels';
import { BlockRepository } from '@/services/repositoryData/blockRepository';
import { CourseRepository } from '@/services/repositoryData/courseRepository';

export interface CourseService {
  all(): Observable<LearnerCourse[]>;
  allContent(): Observable<LearnerContentBuilder[]>;
  getCourse(uuid: string, journeyUuid?: string, loadType?: DataLoadType): Observable<LearnerCourse>;
  getCourseBlock(uuid: string, journeyUuid?: string, loadType?: DataLoadType): Observable<LearnerBlock>;
  enrollToCourse(uuid: string): Observable<any>;
  page(pageRequest: PageRequest): Observable<Pageable<LearnerCourse>>;
  setCourseProgress(courseUuid: string, blockUuid: string, journeyUuid?: string): Observable<LearnerCourse>;
}

@Injectable('courseService')
export class CourseServiceImpl implements CourseService {
  @Inject('courseRepository')
  private courseRepository: CourseRepository;
  @Inject('blockRepository')
  private blockRepository: BlockRepository;

  allContent(): Observable<LearnerContentBuilder[]> {
    return this.courseRepository.getAllContent('cacheAndCloud');
  }

  all(): Observable<LearnerCourse[]> {
    return this.courseRepository.getAll('cacheAndCloud').pipe(
      map((courses: LearnerCourse[]) => {
        return courses.sort((a, b) => b.createdAt - a.createdAt);
      })
    );
  }

  setCourseProgress(courseUuid: string, blockUuid: string, journeyUuid?: string): Observable<LearnerCourse> {
    return this.courseRepository.setCurrentProgress(courseUuid, blockUuid, journeyUuid);
  }

  getCourse(uuid: string, journeyUuid?: string, loadType: DataLoadType = 'cloud'): Observable<LearnerCourse> {
    if (journeyUuid) {
      return this.courseRepository.getLpCourse(uuid, journeyUuid);
    }
    return this.courseRepository.get(uuid, loadType);
  }

  getCourseBlock(uuid: string, journeyUuid?: string, loadType?: DataLoadType): Observable<LearnerBlock> {
    if (journeyUuid) {
      return this.blockRepository.getLpBlock(uuid, journeyUuid, loadType);
    }
    return this.blockRepository.get(uuid, loadType);
  }

  page = (pageRequest: PageRequest): Observable<Pageable<LearnerCourse>> => {
    return this.courseRepository.page(pageRequest);
  };

  enrollToCourse(uuid: string): Observable<any> {
    return this.courseRepository.enrollToCourse(uuid);
  }
}

@Injectable('previewCourseService')
export class PreviewCourseServiceImpl implements CourseService {
  @Inject('coursePreviewRepository')
  private courseRepository: CourseRepository;
  @Inject('previewBlockRepository')
  private blockRepository: BlockRepository;

  all(): Observable<LearnerCourse[]> {
    return this.courseRepository.getAll('cacheAndCloud').pipe(
      map((courses: LearnerCourse[]) => {
        return courses.sort((a, b) => b.createdAt - a.createdAt);
      })
    );
  }

  setCourseProgress(courseUuid: string, blockUuid: string, journeyUuid?: string): Observable<LearnerCourse> {
    return this.courseRepository.setCurrentProgress(courseUuid, blockUuid, journeyUuid);
  }

  getCourse(uuid: string): Observable<LearnerCourse> {
    return this.courseRepository.get(uuid, 'cache');
  }

  getCourseBlock(uuid: string, loadType: DataLoadType = 'cache'): Observable<LearnerBlock> {
    return this.blockRepository.get(uuid, loadType);
  }

  allContent(): Observable<LearnerContentBuilder[]> {
    return this.courseRepository.getAllContent('cacheAndCloud');
  }

  enrollToCourse(uuid: string): Observable<any> {
    throw new Error('Method not implemented.');
  }

  page = (pageRequest: PageRequest): Observable<Pageable<LearnerCourse>> => {
    return this.courseRepository.page(pageRequest);
  };
}
