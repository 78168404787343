import { Injectable } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { ScormCloudService } from '@/services/repositoryData/cloud/scormCloudService';

export interface ScormService {
  saveScormData(blockUuid: string, status: string, currentState: any, journeyUuid?: string): Observable<any>;
}

@Injectable('scormService')
export class ScormServiceImpl implements ScormService {
  private scormCloudService = new ScormCloudService();

  saveScormData(blockUuid: string, status: string, currentState: any, journeyUuid?: string): Observable<any> {
    return this.scormCloudService.saveScormData(blockUuid, status, currentState, journeyUuid);
  }
}
