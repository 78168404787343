import {
  ItemBadgeProgressStatus,
  JourneyItem,
  JourneyItemType,
  MilestoneStatus,
  ProgressStatus
} from '@trainhq/trainhq-client-core';

export const mapProgressStatus = (item: JourneyItem): ItemBadgeProgressStatus => {
  switch (item.journeyItemType) {
    case JourneyItemType.COURSE:
      return item.courseBuilder.courseStatus === ProgressStatus.COMPLETED
        ? ItemBadgeProgressStatus.COMPLETED
        : item.courseBuilder.courseStatus === ProgressStatus.REJECTED
        ? ItemBadgeProgressStatus.REJECTED
        : item.courseBuilder.courseStatus === ProgressStatus.DUE
        ? ItemBadgeProgressStatus.OVERDUE
        : item.courseBuilder.courseStatus === ProgressStatus.NOT_STARTED
        ? ItemBadgeProgressStatus.NOT_STARTED
        : item.courseBuilder.courseStatus === ProgressStatus.FEEDBACK_REQUIRED ||
          item.courseBuilder.courseStatus === ProgressStatus.PENDING_RESPONSE ||
          item.courseBuilder.courseStatus === ProgressStatus.IN_PROGRESS
        ? ItemBadgeProgressStatus.WAITING
        : null;
    case JourneyItemType.ROLE_PLAY:
      return item.rolePlay.progressStatus === ProgressStatus.COMPLETED
        ? ItemBadgeProgressStatus.COMPLETED
        : item.rolePlay.progressStatus === ProgressStatus.REJECTED
        ? ItemBadgeProgressStatus.REJECTED
        : item.rolePlay.progressStatus === ProgressStatus.DUE
        ? ItemBadgeProgressStatus.OVERDUE
        : item.rolePlay.progressStatus === ProgressStatus.IN_PROGRESS
        ? ItemBadgeProgressStatus.WAITING
        : null;
    case JourneyItemType.MILESTONE:
      return item.milestone.milestoneStatus === MilestoneStatus.NOT_STARTED
        ? ItemBadgeProgressStatus.NOT_STARTED
        : item.milestone.milestoneStatus === MilestoneStatus.REJECTED
        ? ItemBadgeProgressStatus.REJECTED
        : item.milestone.milestoneStatus === MilestoneStatus.APPROVED
        ? ItemBadgeProgressStatus.COMPLETED
        : item.milestone.milestoneStatus === MilestoneStatus.LEARNER_COMPLETED
        ? ItemBadgeProgressStatus.WAITING
        : null;
  }
};

export const isItemCompleted = (item: JourneyItem): boolean => {
  switch (item.journeyItemType) {
    case JourneyItemType.COURSE:
      return item.courseBuilder.courseStatus === ProgressStatus.COMPLETED;
    case JourneyItemType.ROLE_PLAY:
      return item.rolePlay.progressStatus === ProgressStatus.COMPLETED;
    case JourneyItemType.MILESTONE:
      return (
        item.milestone.milestoneStatus === MilestoneStatus.LEARNER_COMPLETED ||
        item.milestone.milestoneStatus === MilestoneStatus.APPROVED
      );
  }
};
