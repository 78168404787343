import React, { useEffect } from 'react';

import { useMainLayoutContext } from '@trainhq/trainhq-client-core';

const useSetAppBar = (element: React.ReactElement, appBarHeight?: number) => {
  const { setAppBar, setAppBarHeight } = useMainLayoutContext();

  useEffect(() => {
    setAppBar(element);
    setAppBarHeight(appBarHeight);
  }, [appBarHeight, element, setAppBar, setAppBarHeight]);
};

export default useSetAppBar;
