import React from 'react';

import { FeatureFlag, FeaturePermission, THQMainPageContainer } from '@trainhq/trainhq-client-core';

import NotFoundPage from '../notFound/NotFoundPage';
import CallsList from '@/components/callIntelligence/CallsList';
import useSetAppBar from '@/layout/useSetAppBar';

const CallsListPage = () => {
  // const rolePlayFeatureEnabled = useFeaturesEnabled([FeaturePermission.ROLE_PLAY]);
  const callIntFeatureEnabled = true;

  useSetAppBar(undefined);

  return (
    // <PermissionRestricted redirect allowed={[RoleNameEnum.COMPANY_MANAGER, RoleNameEnum.COMPANY_ROLE_PLAY_CREATOR]}>
    <FeatureFlag featurePermissions={[FeaturePermission.ROLE_PLAY]} render={<NotFoundPage />}>
      <THQMainPageContainer>
        <CallsList />
      </THQMainPageContainer>
    </FeatureFlag>
    // </PermissionRestricted>
  );
};

export default CallsListPage;
