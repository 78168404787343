import React, { useContext, useEffect, useState } from 'react';

import { FeaturePermission, LearnerFeedbackThread, useFeaturesEnabled } from '@trainhq/trainhq-client-core';
import { Subscription } from 'rxjs';

import { FeedbackServiceContext } from '@/context/feedbackServiceContext';

export const useGetAllPendingThreads = (): [
  feedbackThreads: LearnerFeedbackThread[],
  setFeedbackThreads: React.Dispatch<React.SetStateAction<LearnerFeedbackThread[]>>
] => {
  const feedbackService = useFeedbackService();
  const courseFeatureEnabled = useFeaturesEnabled([FeaturePermission.COURSE]);

  const [feedbackThreads, setFeedbackThreads] = useState<LearnerFeedbackThread[]>();

  useEffect(() => {
    const subs = new Subscription();

    if (courseFeatureEnabled) {
      subs.add(
        feedbackService.getAllPendingThreads().subscribe({
          next: (threads) => {
            setFeedbackThreads(threads);
          }
        })
      );
    }
    return () => {
      subs.unsubscribe();
    };
  }, [courseFeatureEnabled, feedbackService]);

  return [feedbackThreads, setFeedbackThreads];
};

export const useFeedbackService = () => useContext(FeedbackServiceContext);
