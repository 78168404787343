import React, { useCallback } from 'react';

import { Grid } from '@mui/material';
import { I18nextProvider, useTranslation } from 'react-i18next';

import { ReactComponent as Logo } from '@/assets/icons/logo.svg';
import ChromeLogo from '@/assets/images/chrome.png';
import FirefoxLogo from '@/assets/images/firefox.png';
import {
  BrowserCard,
  DownloadLinkTypography,
  HeadlineTypography,
  LogoContainerStyled,
  MainContentContainerStyled,
  SubtextTypography
} from '@/components/browserNotSupported/styles';
import ThemeLogo from '@/components/common/themeLogo/ThemeLogo';
import i18n from '@/services/i18n-no-auth';
const downloadChrome = 'https://www.google.com/chrome/';
const downloadFF = 'https://www.mozilla.org/en-US/firefox/new/';

const BrowserNotSupportedComponent: React.FC = () => {
  const { t } = useTranslation();
  const handleOpenDownloadPage = useCallback((e) => {
    if (e.currentTarget.id === 'ff') {
      return window.open(downloadFF, '_blank');
    }
    return window.open(downloadChrome, '_blank');
  }, []);

  return (
    <I18nextProvider i18n={i18n}>
      <LogoContainerStyled>
        <ThemeLogo width={'96px'} />
      </LogoContainerStyled>

      <MainContentContainerStyled>
        <Grid sx={{ justifyContent: 'center', margin: 'auto', maxWidth: '600px' }} container>
          <Grid sx={{ marginLeft: '16px', marginRight: '16px', marginBottom: '26px', maxWidth: '600px' }} item xs={12}>
            <Grid container>
              <Grid item>
                <HeadlineTypography>{t('supported_browsers')}</HeadlineTypography>
                <SubtextTypography>{t('browser_support_text')}</SubtextTypography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item display={'flex'} justifyContent={'center'} xs={12} sm={'auto'}>
            <BrowserCard id="chrome" onClick={handleOpenDownloadPage}>
              <img height={100} width={'auto'} src={ChromeLogo} />
              <DownloadLinkTypography target="_blank" rel="noopener" underline="none" href={downloadChrome}>
                {t('download_chrome')}
              </DownloadLinkTypography>
            </BrowserCard>
          </Grid>
          <Grid item display={'flex'} justifyContent={'center'} xs={12} sm={'auto'}>
            <BrowserCard id="ff" onClick={handleOpenDownloadPage}>
              <img height={100} width={'auto'} src={FirefoxLogo} />
              <DownloadLinkTypography target="_blank" rel="noopener" underline="none" href={downloadFF}>
                {t('download_firefox')}
              </DownloadLinkTypography>
            </BrowserCard>
          </Grid>
        </Grid>
      </MainContentContainerStyled>
    </I18nextProvider>
  );
};

export default BrowserNotSupportedComponent;
