import React from 'react';

import { Grid } from '@mui/material';
import { Journey, THQCourseCardGhost, THQTabPanel } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';

import noCompletedCourses from '@/assets/images/no_completed_courses.png';
import satelite from '@/assets/images/satelite.png';
import EmptyCard from '@/components/common/emptyCard/EmptyCard';
import JourneyListItem from '@/components/journey/list/JourneyListItem';

interface JourneyListTabPanelProps {
  journeys?: Journey[];
  index: number;
  loading?: boolean;
  tabValue: number;
}

const JourneyListTabPanel: React.FC<JourneyListTabPanelProps> = ({ journeys, index, loading, tabValue }) => {
  const { t } = useTranslation();
  return (
    <THQTabPanel value={tabValue} index={index}>
      <Grid sx={{ marginTop: 0, marginBottom: '64px' }} container spacing={2}>
        {loading && (
          <>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <THQCourseCardGhost />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <THQCourseCardGhost />
            </Grid>
            <Grid item lg={4} md={6} sm={6} xs={12}>
              <THQCourseCardGhost />
            </Grid>
          </>
        )}
        {!loading && (!journeys || journeys?.length === 0) && (
          <Grid item lg={4} md={6} sm={6} xs={12}>
            <EmptyCard
              description={index === 0 ? t('journeys_no_assigned') : t('journeys_assigned_info')}
              image={index === 0 ? satelite : noCompletedCourses}
            />
          </Grid>
        )}
        {!loading && journeys && journeys?.map((journey) => <JourneyListItem journey={journey} key={journey?.uuid} />)}
      </Grid>
    </THQTabPanel>
  );
};

export default JourneyListTabPanel;
