import React, { useCallback } from 'react';

import { Box, Grid, Typography } from '@mui/material';
import {
  itemTypeToStr,
  minutesToHoursAndMinutes,
  ProgressStatus,
  THQAvatar,
  THQPrimaryButton
} from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ReactComponent as DocStar } from '@/assets/icons/doc-star.svg';
import { PanelIconWrapperStyled } from '@/components/journey/common/styles';
import ItemActions from '@/components/journey/item/actions/ItemActions';
import { actionsHeight } from '@/components/journey/item/actions/styles';
import { ItemTypeTextStyled } from '@/components/journey/item/milestone/styles';
import { COURSE_DETAILS_STRING, JOURNEY_STRING } from '@/constants/router';
import { useJourneyContext, useJourneySetters } from '@/hooks/journey/useJourneyContext';

const CoursePanelItem: React.FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { selectedItem, journey } = useJourneyContext();
  const { setSelectedItem, setOpenItemDrawer } = useJourneySetters();

  const ownersFirstName = selectedItem?.courseBuilder?.owner?.firstName;
  const ownersLastName = selectedItem?.courseBuilder?.owner?.lastName;
  const courseCompleted = selectedItem?.courseBuilder?.courseStatus === ProgressStatus.COMPLETED;
  const courseInProgress = selectedItem?.courseBuilder?.courseStatus === ProgressStatus.IN_PROGRESS;

  const handleOnCancel = useCallback(() => {
    setSelectedItem(undefined);
    setOpenItemDrawer(false);
  }, [setOpenItemDrawer, setSelectedItem]);

  const handleCourseAction = useCallback(() => {
    navigate(
      `${COURSE_DETAILS_STRING}/${selectedItem?.courseBuilder?.uuid}?journeyUuid=${journey?.uuid}&returnTo=${JOURNEY_STRING}/${journey?.uuid}`
    );
  }, [journey?.uuid, navigate, selectedItem?.courseBuilder?.uuid]);

  return (
    <>
      <Box sx={{ height: `calc(100% - ${actionsHeight}px)`, overflowY: 'auto', position: 'relative' }}>
        <Grid container sx={{ height: '100%', overflowY: 'auto', padding: '32px' }}>
          <Grid item sx={{ marginBottom: '29px' }} xs={12}>
            <ItemTypeTextStyled>{itemTypeToStr(selectedItem.journeyItemType, t).toUpperCase()}</ItemTypeTextStyled>
          </Grid>
          <Grid item sx={{ marginBottom: '40px' }} xs={12}>
            <Typography fontSize={24} fontWeight={700}>
              {selectedItem?.courseBuilder?.name}
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '24px' }} xs={12}>
            <Typography fontSize={18} fontWeight={700}>
              {t('requirements')}
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '40px' }} xs={12}>
            <Grid alignItems="center" container columnSpacing={1}>
              <Grid item>
                <PanelIconWrapperStyled>
                  <DocStar />
                </PanelIconWrapperStyled>
              </Grid>
              {selectedItem.courseBuilder?.timeTrackingEnabled ? (
                <>
                  <Grid item>
                    <Typography>
                      {t('you_have_to_spend_1')}{' '}
                      <Typography component="span" fontWeight={700}>
                        {minutesToHoursAndMinutes(selectedItem?.courseBuilder?.minimumLearningTime)}
                      </Typography>{' '}
                      {t('you_have_to_spend_2')}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item>
                    <Typography>{t('course_panel_no_minimum_req')}</Typography>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item sx={{ marginBottom: '16px' }} xs={12}>
            <Typography fontWeight={700}>{t('course_owner')}</Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '16px' }} xs={12}>
            <Grid alignItems="center" container columnSpacing={1}>
              <Grid item>
                <THQAvatar colorRandomizeString={`${ownersFirstName}${ownersLastName}`} sx={{ fontSize: 14 }}>
                  {ownersFirstName?.[0]}
                  {ownersLastName?.[0]}
                </THQAvatar>
              </Grid>
              <Grid item>
                <Typography>
                  {ownersFirstName} {ownersLastName}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sx={{ marginBottom: '40px' }} xs={12}>
            <Typography fontSize={18} fontWeight={700}>
              {t('overview')}
            </Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '24px' }} xs={12}>
            <Typography fontWeight={700}>{t('course_panel_overview')}</Typography>
          </Grid>
          <Grid item sx={{ marginBottom: '16px' }} xs={12}>
            <Typography>{selectedItem?.courseBuilder?.description}</Typography>
          </Grid>
        </Grid>
      </Box>
      <ItemActions>
        <Grid container columnSpacing={1} justifyContent="right">
          <Grid item>
            <THQPrimaryButton onClick={handleOnCancel}>{t('cancel')}</THQPrimaryButton>
          </Grid>
          <Grid item>
            <THQPrimaryButton variant="main" onClick={handleCourseAction}>
              {courseCompleted ? t('view') : courseInProgress ? t('continue') : t('start')}
            </THQPrimaryButton>
          </Grid>
        </Grid>
      </ItemActions>
    </>
  );
};

export default CoursePanelItem;
