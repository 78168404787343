import React from 'react';

import { ItemDrawerStyled, XButtonStyled, XIconStyled } from '@/components/journey/drawer/styles';
import { useJourneySetters } from '@/hooks/journey/useJourneyContext';

interface DrawerConfig {
  width: number;
}

export const itemDrawerConfig: DrawerConfig = {
  width: 560
};

interface JourneyItemDrawerProps {
  children: React.ReactNode;
  open: boolean;
}

const JourneyItemDrawer: React.FC<JourneyItemDrawerProps> = ({ children, open }) => {
  const { setOpenItemDrawer, setSelectedItem } = useJourneySetters();

  const handleOnClose = () => {
    setOpenItemDrawer(false);
    setSelectedItem(undefined);
  };

  return (
    <ItemDrawerStyled
      anchor="right"
      elevation={0}
      hideBackdrop
      ModalProps={{ disableScrollLock: true }}
      open={open}
      onClose={handleOnClose}
    >
      <XButtonStyled onClick={handleOnClose}>
        <XIconStyled />
      </XButtonStyled>
      {children}
    </ItemDrawerStyled>
  );
};

export default JourneyItemDrawer;
