import React from 'react';

import { PickPasswordResetMethod } from '@trainhq/trainhq-client-core';
import { useNavigate } from 'react-router';

import { HOME, RESET_PASSWORD_EMAIL, RESET_PASSWORD_SMS } from '@/constants/router';

const ResetPasswordPage: React.FC = () => {
  const navigate = useNavigate();

  const handleGoBack = () => {
    navigate(HOME);
  };

  const handleMethodPicked = (choice: string) => {
    if (choice === 'sms') {
      navigate(RESET_PASSWORD_SMS);
    }
    if (choice === 'email') {
      navigate(RESET_PASSWORD_EMAIL);
    }
  };

  return <PickPasswordResetMethod onGoBack={handleGoBack} choiceCallback={handleMethodPicked} />;
};

export default ResetPasswordPage;
