import React from 'react';

import { Grid } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { ProgressStatus, THQSmallHeadline } from '@trainhq/trainhq-client-core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { DescriptionStyled, THQContentBuilderCardStyled } from '@/components/common/contentBuilderList/styles';
import { getButtonText } from '@/components/common/contentBuilderList/utils';
import { COURSE_DETAILS_STRING } from '@/constants/router';
import { LearnerCourse } from '@/models/learnerModels';
import { getChipInfo } from '@/utils/courseUtils';

interface CourseListItemProps {
  course: LearnerCourse;
}

// TODO: use context to prevent prop drilling
const CourseListItem: React.FC<CourseListItemProps> = ({ course }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const handleOnCourseClick = () => {
    navigate(`${COURSE_DETAILS_STRING}/${course?.uuid}`);
  };

  return (
    <Grid item lg={4} md={6} sm={6} xs={12}>
      {
        <THQContentBuilderCardStyled
          button={{
            variant: course.courseStatus === ProgressStatus.NOT_STARTED ? 'main' : 'standard',
            text: getButtonText(t, course.courseStatus),
            action: handleOnCourseClick
          }}
          chipInfo={getChipInfo(course.courseStatus, theme, t)}
          description={course?.description ? <DescriptionStyled>{course?.description}</DescriptionStyled> : undefined}
          descriptionPlaceholder={<DescriptionStyled asPlaceholder>{t('no_course_description')}</DescriptionStyled>}
          entity={course}
          learnerLayout
          progressTooltip={t('completed_without_error')}
          progressBar={course.courseStatus !== ProgressStatus.NOT_STARTED ? course.progressPercentage : undefined}
          subtitle={<THQSmallHeadline>{t('course_all_capital')}</THQSmallHeadline>}
        />
      }
    </Grid>
  );
};

export default CourseListItem;
