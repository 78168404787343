import React from 'react';

import { Container, useMediaQuery } from '@mui/material';

import BlockNav from '@/components/course/block/navigation/BlockNav';
import { RichTextEditorStyled } from '@/components/course/block/textBlock/styles';
import { LearnerBlock } from '@/models/learnerModels';

export interface ComposeBlockComponentProps {
  block: LearnerBlock;
  sidebarExpanded?: boolean;
}

export const ComposeBlock: React.FC<ComposeBlockComponentProps> = ({ block }) => {
  const isTablet = useMediaQuery('(max-width:768px)');
  return (
    <>
      {block?.content?.textContent && (
        <Container maxWidth="md">
          <RichTextEditorStyled
            sx={{ ...(block.content.narrationContent && { paddingBottom: isTablet ? '250px' : '120px' }) }}
            key={block.uuid}
            value={JSON.parse(block?.content?.textContent)}
            readonly={true}
            noScroll
            containerSx={{ height: 'initial', marginBottom: '80px' }}
            noBorder
            className="enable-wrap-styled"
          />
        </Container>
      )}
      <BlockNav />
    </>
  );
};
