import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';

export const actionsHeight = 112;

export const ItemActionsStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.zima,
  borderTop: `1px solid ${theme.palette.common.pearl}`,
  bottom: 0,
  height: actionsHeight,
  padding: 36,
  position: 'absolute',
  width: '100%'
}));
