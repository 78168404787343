import { Container, styled } from '@mui/material';
import { THQPrimaryButton } from '@trainhq/trainhq-client-core';

export const ContentContainerStyled = styled(Container)(({ theme }) => ({
  height: '100%',
  [theme.breakpoints.down('md')]: {
    overflowY: 'auto',
    height: 'auto'
  }
}));

export const ConfirmAnswerButton = styled(THQPrimaryButton)(() => ({
  borderRadius: 8,
  display: 'block',
  margin: '12px auto',
  minWidth: 100,
  textTransform: 'none',
  transition: 'all .2s ease-out'
}));
