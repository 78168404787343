import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

interface BlockContentWrapperStyledProps {
  sidebarExpanded?: boolean;
}

export const BlockContentWrapperStyled = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'sidebarExpanded'
})<BlockContentWrapperStyledProps>(({ theme, sidebarExpanded }) => ({
  backgroundColor: theme.palette.background.paper,
  height: '100vh',
  overflow: 'auto',
  paddingTop: 40,
  position: 'relative',
  transition: 'width 0.2s, margin 0.2s',
  width: sidebarExpanded ? 'calc(100% - 17rem)' : 'calc(100% - 2px)', // TODO: refactor this with new layout improvements
  ...(!sidebarExpanded && {
    transition: 'width 0.2s, margin 0.2s'
  }),
  [theme.breakpoints.down('lg')]: {
    width: '100%'
  },
  [theme.breakpoints.down('sm')]: {
    paddingTop: 16
  }
}));
