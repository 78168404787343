import { Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

interface SidebarRootGridStyledProps {
  expanded?: boolean;
}
// TODO: if we are going to keep this , use @mui transitions like in manager app
export const SidebarRootGridStyled = styled(Grid, {
  shouldForwardProp: (prop) => prop !== 'expanded'
})<SidebarRootGridStyledProps>(({ theme, expanded }) => ({
  paddingTop: '120px',
  backgroundColor: theme.sidebar.backgroundColor,
  borderRight: `1px solid ${theme.palette.grey[50]}`,
  height: '100%',
  transition: 'width 0.2s',
  width: '272px',
  zIndex: 8,
  [theme.breakpoints.down('lg')]: {
    position: 'absolute',
    width: '272px',
    ...(!expanded && {
      width: 0,
      transition: 'width 0.2s'
    })
  },
  ...(!expanded && {
    width: 0,
    transition: 'width 0.2s'
  })
}));

export const DrawerDivStyled = styled('div', {
  shouldForwardProp: (prop) => prop !== 'expanded'
})<SidebarRootGridStyledProps>(({ theme, expanded }) => ({
  width: 'min(272px,80vw)',
  height: '100%',
  overflowY: 'auto',
  padding: 32,
  transition: 'padding 0.2s',
  [theme.breakpoints.down('lg')]: {
    boxShadow: '0px 20px 36px -8px rgba(14, 14, 44, 0.1), 0px 1px 1px rgba(0, 0, 0, 0.05)',
    ...(!expanded && {
      boxShadow: 'none'
    })
  },
  ...(!expanded && {
    boxShadow: 'none',
    padding: 0,
    borderRight: 'none',
    transition: 'padding 0.2s'
  })
}));
