import { ContentItem, DataLoadType, Inject, Injectable, Pageable, PageRequest } from '@trainhq/trainhq-client-core';
import { Observable } from 'rxjs';

import { ContentLibraryRepository } from '@/services/repositoryData/contentLibraryRepository';

export abstract class ContentLibraryService {
  abstract contentItemPage(pageRequest: PageRequest): Observable<Pageable<ContentItem>>;
  abstract get(uuid: string, cacheType?: DataLoadType): Observable<ContentItem>;
  abstract getAllByUuids(uuids: string[]): Observable<ContentItem[]>;
}

@Injectable('contentLibraryService')
export class ContentLibraryServiceImpl implements ContentLibraryService {
  @Inject('contentLibraryRepository')
  private contentLibraryRepository: ContentLibraryRepository;

  contentItemPage = (pageRequest: PageRequest): Observable<Pageable<ContentItem>> => {
    return this.contentLibraryRepository.contentItemPage(pageRequest);
  };

  get(uuid: string, cacheType: DataLoadType = 'cacheAndCloud'): Observable<ContentItem> {
    return this.contentLibraryRepository.get(uuid, cacheType);
  }

  getAllByUuids(uuids: string[]): Observable<ContentItem[]> {
    return this.contentLibraryRepository.getAllByUuids(uuids);
  }
}
