import { Box, Grid } from '@mui/material';
import { styled } from '@mui/material/styles';

export const ContainerGridStyled = styled(Grid)(() => ({
  height: '100%',
  padding: '24px 32px'
}));

export const ImageStyled = styled('img')(() => ({
  height: 100,
  marginTop: 10,
  position: 'relative',
  zIndex: 1
}));

export const ImageBoxStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.pow,
  height: 110,
  left: -96,
  position: 'absolute',
  top: 0,
  width: 272,
  zIndex: 0
}));

export const IconWrapperStyled = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.common.pearl,
  borderRadius: '50%',
  height: 28,
  paddingTop: 5,
  width: 28
}));
