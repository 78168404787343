import React, { useCallback } from 'react';

import { Grid } from '@mui/material';
import { getJourneyItemImage, itemDimensions, JourneyItemBadge, JourneyItemType } from '@trainhq/trainhq-client-core';

import {
  AnimatedGridStyled,
  GroupGridContainer,
  InnerBoxStyled,
  ItemWrapperStyled
} from '@/components/journey/item/list/styles';
import { mapProgressStatus } from '@/components/journey/utils';
import { useJourneyContext, useJourneySetters } from '@/hooks/journey/useJourneyContext';

const JourneyItemList: React.FC = () => {
  const { journey, openDetailsDrawer, openItemDrawer, selectedItem } = useJourneyContext();
  const { setOpenItemDrawer, setSelectedItem, setParentGroupUuid } = useJourneySetters();

  const handleOnItemClick = useCallback(
    (itemUuid: string) => {
      let found;
      for (let i = 0; i < journey?.journeyItems?.length; i++) {
        if (journey?.journeyItems[i]?.journeyGroup) {
          let groupUuid;
          found = journey?.journeyItems[i]?.journeyGroup?.journeyItems?.find((groupItem) => {
            groupUuid = groupItem.uuid;
            return groupItem.uuid === itemUuid;
          });
          if (found) {
            setParentGroupUuid(groupUuid);
            break;
          }
        }
        if (!found) {
          setParentGroupUuid(undefined);
          found = journey?.journeyItems?.find((item) => item.uuid === itemUuid);
        }
      }
      setSelectedItem(found);
      setOpenItemDrawer(true);
    },
    [journey?.journeyItems, setOpenItemDrawer, setParentGroupUuid, setSelectedItem]
  );

  return (
    <AnimatedGridStyled container detailsOpen={openDetailsDrawer} itemOpen={openItemDrawer} justifyContent="center">
      <Grid item sx={{ maxWidth: '100vw', width: 3 * itemDimensions.maxWidth }}>
        <InnerBoxStyled>
          {journey?.journeyItems?.map((item) => (
            <ItemWrapperStyled key={item.uuid}>
              {item.journeyItemType === JourneyItemType.GROUP ? (
                <GroupGridContainer alignItems="center" container justifyContent="center" rowSpacing={2}>
                  {item.journeyGroup.journeyItems
                    .sort((a, b) => a.itemOrder - b.itemOrder)
                    .map((groupItem) => (
                      <Grid item key={groupItem.uuid}>
                        <JourneyItemBadge
                          label={groupItem.label}
                          locked={item.locked}
                          mainImage={getJourneyItemImage(groupItem.journeyItemType)}
                          progress={groupItem.progress}
                          progressStatus={mapProgressStatus(groupItem)}
                          selected={selectedItem?.uuid === groupItem.uuid}
                          type={groupItem.journeyItemType}
                          uuid={groupItem.uuid}
                          onClick={handleOnItemClick}
                        />
                      </Grid>
                    ))}
                </GroupGridContainer>
              ) : (
                <JourneyItemBadge
                  label={item.label}
                  locked={item.locked}
                  mainImage={getJourneyItemImage(item.journeyItemType)}
                  progress={item.progress}
                  progressStatus={mapProgressStatus(item)}
                  selected={selectedItem?.uuid === item.uuid}
                  type={item.journeyItemType}
                  uuid={item.uuid}
                  onClick={handleOnItemClick}
                />
              )}
            </ItemWrapperStyled>
          ))}
        </InnerBoxStyled>
      </Grid>
    </AnimatedGridStyled>
  );
};

export default JourneyItemList;
